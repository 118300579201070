import React from 'react'
import { Link } from 'react-router-dom'
import SpecialInvitees from '../../api/OurCreator'
import shape1 from '../../images/background/pattern-4.png'
import shape2 from '../../images/background/pattern-6.png'
// import shape3 from '../../images/background/pattern-5.png'

const Speakers = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="speakers-one speakers-onn" id="speakers">
            {/* <div className="speakers-one_pattern" style={{ backgroundImage: `url(${shape1})` }}></div> */}
            <div className="speakers-one_pattern-two" style={{ backgroundImage: `url(${shape2})` }}></div>
            <div className="auto-container" style={{marginTop: '120px'}}>
            <p className='color'>The Forttuna Global Excellence Awards as a global awards platform is an IP of The Forttuna Group, a collective entity driven by a team of dedicated and passionate individuals. As an integral part of The Forttuna Group, the award of excellence program embodies the group’s core values and mission, reflecting its unwavering commitment to recognizing and celebrating excellence on a global scale.

Within The Forttuna Group, there exists a diverse array of talents, expertise, and backgrounds. These individuals bring with them a wealth of experience, knowledge, and enthusiasm, contributing to the success and impact of the global awards- The Forttuna Global Excellence Awards.

The collective passion and dedication of our team drives the continuous evolution and growth of the excellence awards. Their collaborative efforts fuel the development of innovative strategies, meticulous planning, and seamless execution, ensuring that The Forttuna Global Excellence Awards remain at the forefront of recognizing global excellence and inspiring positive change.</p>
                <div className="sec-title title-anim centered">
                    {/* <div className="sec-title_title">Our Speakers</div> */}
                    <h2 className="sec-title_heading">Founders</h2>
                </div>
                <div className="row clearfix" style={{ justifyContent: 'center' }}>
                    {SpecialInvitees.slice(0, 10).map((team, aitem) => (
                        <div className="speaker-block_one col-lg-3 col-md-6 col-sm-12" key={aitem}>
                            <div className="speaker-block_one-inner">
                                <div className="speaker-block_one-image">
                                    <img src={team.tImg} alt="" />
                                    <div className="speaker-block_one-pattern"></div>
                                    <div className="speaker-block_one-social">
                                        <span className="speaker-block_one-share fa fa-share-alt"></span>
                                        <div className="speaker-block_one-social-list">
                                            <a href={team.link2} target='_blank' className="facebook fa fa-linkedin"></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="speaker-block_one-content">
                                    <h4 className="speaker-one_title"><Link onClick={ClickHandler} to={`/${team.slug}/`}>{team.name}</Link></h4>
                                    <div className="speaker-one_detail">{team.title}</div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>
    )
}

export default Speakers;

