import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/award/Forttuna-Global-Excellence-AwardsWomen-In-Business-Awards.webp'
import sSimg from '../../images/award/Business-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards: Hospitality Awards';
    }, []);
    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="A prestigious jury-led and merit-based hospitality awards program recognizing exceptional achievements across various industries." />
                <meta property="og:title" content="Forttuna Global Excellence Awards: Hospitality Awards" />
                <meta property="og:description" content="A prestigious jury-led and merit-based hospitality awards program recognizing exceptional achievements across various industries." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: Women In Business Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="" />
                        </div>
                        <div className="upper-box d-flex justify-content-between flex-wrap">
                            <h2 style={{color: '#fff'}}>The Forttuna Global Excellence Awards: Women In Business Awards</h2><br/>
                            {/* <div className="event-detail_author">
                                <div className="event-detail_author-image">
                                    <img src={eimg1} alt="" />
                                </div>
                                <div className="event-detail_author-name">Robert Gates, <span>Art Critic</span></div>
                                <div className="event-detail_author-location"><span>Location:</span> Hall 1, Building, <br /> Golden Street , Southafrica</div>
                                <div className="event-detail_author-timing">08.00 am  09.00 am</div>
                                <div className="event-detail_author-icon flaticon-voice"></div>
                            </div> */}
                        </div>
                        <div>
                            <p><strong>First-ever Jury Led Awards: Be Recognized on the World Stage</strong></p>
                            <p><span>The business world thrives on innovation, resilience, and leadership &ndash; qualities that women in business consistently demonstrate.</span></p>
                            <p>
                                <span>
                                    The Forttuna Global Excellence Awards - Women In Business Awards provide a platform to acknowledge their remarkable contributions and the impact they create. Recognition on a global stage will pave the way for future generations of
                                    female leaders and entrepreneurs.
                                </span>
                            </p>
                            <p><strong>What are the Forttuna Global Excellence Awards?</strong></p>
                            <ul>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>A prestigious jury-led and merit-based women in business awards program recognizing exceptional achievements across various industries.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Award of Excellence to honor individuals and companies through women in business awards making a significant impact on the global landscape.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>More than just global awards - a chance to connect and collaborate with like-minded individuals across diverse fields.</span></li>
                            </ul>
                            <p><strong>Why Participate</strong></p>
                            <ul>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Gain global recognition for your company's innovation and leadership.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Showcase individual talent and inspire others through achievement.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Network with industry leaders at our prestigious women in business awards ceremony.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Elevate your brand profile and attract new opportunities.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Gain significant media exposure, propelling your achievements into the spotlight.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Fair evaluation by a distinguished panel of international jury experts.</span></li>
                            </ul>
                            <p><strong>Women In Business Awards Categories :</strong></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Architecture, Construction &amp; Engineering</strong></li>
                            </ol>
                            <p><span>Business Woman of the Year</span></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Automobile</strong></li>
                            </ol>
                            <p><span>Business Woman of the Year</span></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Aviation</strong></li>
                            </ol>
                            <p><span>Business Woman of the Year</span></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Finance and Insurance</strong></li>
                            </ol>
                            <p><span>Business Woman of the Year</span></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Fashion and Beauty</strong></li>
                            </ol>
                            <p><span>Business Woman of the Year</span></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Entertainment and Media</strong></li>
                            </ol>
                            <p><span>Business Woman of the Year</span></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Education</strong></li>
                            </ol>
                            <p><span>Business Woman of the Year</span></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Hospitality</strong></li>
                            </ol>
                            <p><span>Business Woman of the Year</span></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Manufacturing</strong></li>
                            </ol>
                            <p><span>Business Woman of the Year</span></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Marketing</strong></li>
                            </ol>
                            <p><span>Business Woman of the Year</span></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Market Research</strong></li>
                            </ol>
                            <p><span>Business Woman of the Year</span></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Human Resources</strong></li>
                            </ol>
                            <p><span>Business Woman of the Year</span></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Real Estate</strong></li>
                            </ol>
                            <p><span>Business Woman of the Year</span></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> SME&rsquo;s</strong></li>
                            </ol>
                            <p><span>Business Woman of the Year</span></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Technology</strong></li>
                            </ol>
                            <p><span>Business Woman of the Year</span></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Textile</strong></li>
                            </ol>
                            <p><span>Business Woman of the Year</span></p>
                            <p><strong>Ready to shine a light? Participate yourself or someone you know for recognition.</strong></p>
                            <p><span>Let's celebrate your achievements together at this Women In Business Awards!</span></p>
                            <p><a href="https://calendly.com/d/ck9b-2wq-pjs/participation-interview-fga?month=2024-07"><span>Book An Appointment Now!</span></a></p>
                            <p><span><Link to={`/awards/hospitality-awards/`}>Learn More</Link> About the Excellence Award.</span></p>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
