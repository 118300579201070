import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import { Link } from 'react-router-dom';
import pIcon1 from '../../images/background/Payment-failure-page.webp';

    const TermPage = (props) => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Payment Failed';
    }, []);

    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="The Forttuna Global Excellence Awards is dedicated to recognizing and honoring outstanding achievements across various industries." />
                <meta property="og:title" content="Forttuna Global Excellence Awards | Payment Failed" />
                <meta property="og:description" content="The Forttuna Global Excellence Awards is dedicated to recognizing and honoring outstanding achievements across various industries."/>
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Payment Failed</div>
                            <h2 className="page-title_heading">Payment Failed</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Terms & Coditions</li>
                        </ul> */}
                    </div>
                </div>
            </section> 
            <section className="privacy-section">
                <div className="auto-container">
                    <div className="privacy-content">
                    <h2>We're Sorry, Your Payment Could Not Be Processed.</h2>
                        <p>
                        We regret to inform you that your recent payment attempt for the Nomination Administrative fee has failed. We understand the importance of this transaction and apologize for any inconvenience caused.
                        </p>
                        <br/>
                        <br/>
                        <h2>We're Here to Help:</h2>
                        <p>
                        At Forttuna Global Excellence Awards, we value your business and are committed to providing you with exceptional service. If you require any further assistance or have questions regarding your payment, please do not hesitate to reach out to us at <a href="mailto:nominations@forttuna.co">nominations@forttuna.co</a>
                        </p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    )
};
export default TermPage;
