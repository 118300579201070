


import React from "react";
import { Link } from 'react-router-dom'
import pimg1 from '../../images/clients/Forbes-India-Logo.png'
import pimg2 from '../../images/clients/khaleej-times.png'
import pimg3 from '../../images/clients/big-news-network.png'
import pimg4 from '../../images/clients/ABP-News.png'
import pimg5 from '../../images/clients/the-print.png'
import pimg6 from '../../images/clients/Hindustan-Times.png'
import pimg7 from '../../images/clients/ANI-News-Logo.png'
import pimg8 from '../../images/clients/Asian-News-Logo.png'
import pimg9 from '../../images/clients/British-Colombia-News-Logo.png'
import pimg10 from '../../images/clients/Business-Insider-Logo.png'
import pimg11 from '../../images/clients/Business-Standard.png'
import pimg12 from '../../images/clients/Daily-Hunt.png'
import pimg13 from '../../images/clients/DNA.png'
import pimg14 from '../../images/clients/East-Coast-American-News-Logo.png'
import pimg15 from '../../images/clients/England-News-Portal-News-Logo.png'
import pimg16 from '../../images/clients/France-Network-News-Logo.png'
import pimg17 from '../../images/clients/Gulf-News.png'
import pimg18 from '../../images/clients/Maa-Gulf-News-Logo.png'
import pimg19 from '../../images/clients/Midday-News-Logo.png'
import pimg20 from '../../images/clients/PTI-News-Logo.png'
import pimg21 from '../../images/clients/The-Week-News-Logo.png'
import pimg22 from '../../images/clients/Toronto-Times-News-Logo.png'
import pimg23 from '../../images/clients/US-World-Today-News-Logo.png'
import pimg24 from '../../images/clients/White-House-News-Logo.png'

const partners = [
    {
        pImg: pimg1,
    },
    {
        pImg: pimg2,
    },
    {
        pImg: pimg3,
    },
    {
        pImg: pimg4,
    },
    {
        pImg: pimg5,
    },
    {
        pImg: pimg6,
    },
    {
        pImg: pimg7,
    },
    {
        pImg: pimg8,
    },
    {
        pImg: pimg1,
    },
    {
        pImg: pimg2,
    },
    {
        pImg: pimg3,
    },
    {
        pImg: pimg4,
    },
    {
        pImg: pimg5,
    },
    {
        pImg: pimg6,
    },
    {
        pImg: pimg7,
    },
    {
        pImg: pimg8,
    },
    {
        pImg: pimg9,
    },
    {
        pImg: pimg10,
    },
    {
        pImg: pimg11,
    },
    {
        pImg: pimg12,
    },
    {
        pImg: pimg13,
    },
    {
        pImg: pimg14,
    },
    {
        pImg: pimg15,
    },
    {
        pImg: pimg16,
    },
    {
        pImg: pimg17,
    },
    {
        pImg: pimg18,
    },
    {
        pImg: pimg19,
    },
    {
        pImg: pimg20,
    },
    {
        pImg: pimg21,
    },
    {
        pImg: pimg22,
    },
    {
        pImg: pimg23,
    },
    {
        pImg: pimg24,
    },
]


const PartnerSection2 = (props) => {

    return (

        <section className="clients-one">
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <h2 className="sec-title_heading">Featured On</h2>
                    {/* <div className="sec-title_title mt-10">Appreciate Our Kind Partners</div> */}
                </div>
                <div className="clients-two_slide d-flex">
                    {partners.map((partner, pitem) => (
                        <div className="clients-two_column" key={pitem}>
                            <Link to="/"><img src={partner.pImg} alt="" /></Link>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PartnerSection2;