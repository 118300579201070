import React from 'react'
import CountUp from 'react-countup';
import bg from '../../images/background/1.jpg'

const FunFact = (props) => {

    const funFact = [
        {
            title: '6200',
            subTitle: 'Participants',
            Symbol: '+',
        },
        {
            title: '1000',
            subTitle: 'Delegates',
            Symbol: '+',
        },
        {
            title: '250',
            subTitle: 'Winners',
            Symbol: '+',
        },
        {
            title: '200',
            subTitle: 'Award Categories',
            Symbol: '+',
        },
        {
            title: '200',
            subTitle: 'Media Partners',
            Symbol: '+',
        },
        {
            title: '80',
            subTitle: 'Countries',
            Symbol: '+',
        },
        {
            title: '50',
            subTitle: 'Partners ',
            Symbol: '+',
        },
        {
            title: '16',
            subTitle: 'Speakers',
            Symbol: '+',
        },
        {
            title: '15',
            subTitle: 'Sponsors',
            Symbol: '+',
        },
        
        
        
    ]
    

    return (

        <section className="counter-one">
            <div className="auto-container max-width-important" >
                <div className="inner-container">
                    <div className="counter-one_image" style={{ backgroundImage: `url(${bg})` }}></div>
                    <div className="row clearfix text-center mb-50 vertically" style={{marginTop: '-47px', bsGutterY: '15px'}}>
                        {funFact.map((funfact, fitem) => (
                            <div className="counter-one_block col-lg-2 col-md-6 col-sm-12" key={fitem}>
                                <div className="counter-one_inner">
                                    <div className="counter-one_counter"><h4><CountUp end={funfact.title} enableScrollSpy />{funfact.Symbol}</h4></div>
                                    <div className="counter-one_text">{funfact.subTitle}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <span id="counter" className='d-none' />
        </section>

    )
}

export default FunFact;