import React from "react";
import { Link } from 'react-router-dom'
import TimeCountDown from "../countdown";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import hero1 from '../../images/main-slider/pattern-6.png'
import hero2 from '../../images/main-slider/image-4.jpg'
import hero3 from '../../images/main-slider/image-3.png'

const Hero3 = () => {

    var settings = {
        dots: false,
        arrows: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };


    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="slider-three" id="home">
            <div className="main-slider-carousel">
                <Slider {...settings}>
                    <div className="slider-three_slide">
                        <div className="slider-three_pattern-one" style={{ backgroundImage: `url(${hero1})` }}></div>
                        <div className="slider-three_image-layer" style={{ backgroundImage: `url(${hero2})` }}></div>
                        <div className="auto-container">
                            <div className="row clearfix">
                                <div className="slider-three_content col-lg-7 col-md-12 col-sm-12">
                                    <div className="slider-three_content-inner">
                                        <div className="slider-three_title">Worldwide conference on</div>
                                        <h2 className="slider-three_heading">Late Crazes in <br /> Teaching Learning</h2>
                                        <div className="slider-three_location">Hilton Nashville Airport Hotel, Nashville, TN</div>
                                        <div className="slider-three_timer">
                                            <div className="time-countdown clearfix">
                                                <TimeCountDown/>
                                            </div>
                                        </div>
                                        <div className="slider-three_button">
                                            <Link onClick={ClickHandler} to="/register" className="theme-btn btn-style-three">
                                                <span className="btn-wrap">
                                                    <span className="text-one">Book Ticket</span>
                                                    <span className="text-two">Book Ticket</span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="slider-three_image-column col-lg-5 col-md-12 col-sm-12">
                                    <div className="slider-three_image">
                                        <img src={hero3} alt="" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="slider-three_slide">
                        <div className="slider-three_pattern-one" style={{ backgroundImage: `url(${hero1})` }}></div>
                        <div className="slider-three_image-layer" style={{ backgroundImage: `url(${hero2})` }}></div>
                        <div className="auto-container">
                            <div className="row clearfix">
                                <div className="slider-three_content col-lg-7 col-md-12 col-sm-12">
                                    <div className="slider-three_content-inner">
                                        <div className="slider-three_title">Worldwide conference on</div>
                                        <h2 className="slider-three_heading">Late Crazes in <br /> Teaching Learning</h2>
                                        <div className="slider-three_location">Hilton Nashville Airport Hotel, Nashville, TN</div>
                                        <div className="slider-three_timer">
                                            <div className="time-countdown clearfix">
                                                <TimeCountDown/>
                                            </div>
                                        </div>
                                        <div className="slider-three_button">
                                            <Link onClick={ClickHandler} to="/register" className="theme-btn btn-style-three">
                                                <span className="btn-wrap">
                                                    <span className="text-one">Book Ticket</span>
                                                    <span className="text-two">Book Ticket</span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="slider-three_image-column col-lg-5 col-md-12 col-sm-12">
                                    <div className="slider-three_image">
                                        <img src={hero3} alt="" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="slider-three_slide">
                        <div className="slider-three_pattern-one" style={{ backgroundImage: `url(${hero1})` }}></div>
                        <div className="slider-three_image-layer" style={{ backgroundImage: `url(${hero2})` }}></div>
                        <div className="auto-container">
                            <div className="row clearfix">
                                <div className="slider-three_content col-lg-7 col-md-12 col-sm-12">
                                    <div className="slider-three_content-inner">
                                        <div className="slider-three_title">Worldwide conference on</div>
                                        <h2 className="slider-three_heading">Late Crazes in <br /> Teaching Learning</h2>
                                        <div className="slider-three_location">Hilton Nashville Airport Hotel, Nashville, TN</div>
                                        <div className="slider-three_timer">
                                            <div className="time-countdown clearfix">
                                                <TimeCountDown/>
                                            </div>
                                        </div>
                                        <div className="slider-three_button">
                                            <Link onClick={ClickHandler} to="/register" className="theme-btn btn-style-three">
                                                <span className="btn-wrap">
                                                    <span className="text-one">Book Ticket</span>
                                                    <span className="text-two">Book Ticket</span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="slider-three_image-column col-lg-5 col-md-12 col-sm-12">
                                    <div className="slider-three_image">
                                        <img src={hero3} alt="" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </section>
    )
}



export default Hero3;