import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Error from '../../components/404/404'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';

const ErrorPage =() => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Not Found';
    }, []);

    return(
        <Fragment>
            <Helmet>
                <meta name="description" content="Forttuna Global Excellence Awards | Not Found" />
                <meta property="og:title" content="Forttuna Global Excellence Awards | Not Found" />
                <meta property="og:description" content="Forttuna Global Excellence Awards | Not Found" />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar/>
            <PageTitle pageTitle={'Page Not Found'} pagesub={'404'} pageTitle2={'Error 404'}/> 
            <Error/>
            <Subscribe2 />
            <Footer2 />
            <Scrollbar/>
        </Fragment>
    )
};
export default ErrorPage;

