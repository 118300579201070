import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Health-Services-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards : Architecture Awards';
    }, []);
    return (
        <Fragment>
            <Helmet>
                <meta name="description" content="A prestigious jury-led and merit-based architecture awards program recognizing exceptional achievements across various industries." />
                <meta property="og:title" content="The Forttuna Global Excellence Awards : Architecture Awards" />
                <meta property="og:description" content="A prestigious jury-led and merit-based architecture awards program recognizing exceptional achievements across various industries." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: Health Services awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="" />
                        </div>
                        <div className="upper-box d-flex justify-content-between flex-wrap">
                            <h2 style={{color: '#fff'}}>The Forttuna Global Excellence Awards: Health Services awards</h2><br/>
                            {/* <div className="event-detail_author">
                                <div className="event-detail_author-image">
                                    <img src={eimg1} alt="" />
                                </div>
                                <div className="event-detail_author-name">Robert Gates, <span>Art Critic</span></div>
                                <div className="event-detail_author-location"><span>Location:</span> Hall 1, Building, <br /> Golden Street , Southafrica</div>
                                <div className="event-detail_author-timing">08.00 am  09.00 am</div>
                                <div className="event-detail_author-icon flaticon-voice"></div>
                            </div> */}
                        </div>
                        <div>
                            <p><strong>First-ever Jury Led Awards: Be Recognized on the World Stage</strong></p>
                            <p><span>Health professionals' unwavering dedication and exceptional skills are nothing short of heroic.</span></p>
                            <p>
                                <span>
                                    The Forttuna Global Excellence Awards take immense pride in celebrating these remarkable individuals through our prestigious Health Services Awards. We celebrate their dedication that goes beyond the call of duty, their advancements
                                    that revolutionize treatment, and the positive ripple effects their work creates throughout society.
                                </span>
                            </p>
                            <p><strong>What are the Forttuna Global Excellence Awards?</strong></p>
                            <ul>
                                <li><span>A prestigious jury-led and merit-based health services awards program recognizing exceptional achievements across various industries.</span></li>
                                <li><span>Award of Excellence to honor individuals and companies through health services awards making a significant impact on the global landscape.</span></li>
                                <li><span>More than just global awards - a chance to connect and collaborate with like-minded individuals across diverse fields.</span></li>
                            </ul>
                            <p><strong>Why Participate</strong></p>
                            <ul>
                                <li><span>Gain global recognition for your company's innovation and leadership.</span></li>
                                <li><span>Showcase individual talent and inspire others through achievement.</span></li>
                                <li><span>Network with industry leaders at our prestigious health services awards ceremony.</span></li>
                                <li><span>Elevate your brand profile and attract new opportunities.</span></li>
                                <li><span>Gain significant media exposure, propelling your achievements into the spotlight.</span></li>
                                <li><span>Fair evaluation by a distinguished panel of international jury experts.</span></li>
                            </ul>
                            <p>
                                <strong>Headline-3: Health Services Awards Categories :</strong>
                                <span> (All Categories for this award will contain subcategories too- like CMO of the year, CTO of the year as mentioned on the website. As shown in the first two categories )</span>
                            </p>
                            <ol>
                                <li><strong> Ayush, Spa, Wellness and Yoga Category</strong></li>
                            </ol>
                            <ol>
                                <li><span>Ayurveda Centre Of the Year</span></li>
                                <li><span>Medical Spa/Medical Resort Of the Year</span></li>
                                <li><span>Yoga Centre Of the Year</span></li>
                                <li><span>Wellness Centre/ Retreat Of the Year</span></li>
                            </ol>
                            <ol>
                                <li><strong> Company and Brands</strong></li>
                            </ol>
                            <ol>
                                <li><span>Health Insurance Company Of the Year</span></li>
                                <li><span>Long-Tem Care Provider Of the Year</span></li>
                                <li><span>Pharma Company Of the Year</span></li>
                                <li><span>Pharmacy Retail Chain Of the Year</span></li>
                                <li><span>Emerging Healthcare Company Of the Year</span></li>
                                <li><span>Medical Equipment Company Of the Year</span></li>
                                <li><span>Healthcare Start-up of the Year</span></li>
                                <li><span>Public Healthcare Initiative of the Year</span></li>
                                <li><span>Innovative Medical Product of the Year</span></li>
                                <li><span>Marketing Company Of the Year</span></li>
                                <li><span>Insurance and Finance Company Of the Year</span></li>
                                <li><span>Technology Company Of the Year</span></li>
                                <li><span>Education Company Of the Year</span></li>
                                <li><span>Veterinary Company Of the Year</span></li>
                            </ol>
                            <ol>
                                <li><strong> CXO&rsquo;s Health &amp; Wellness</strong></li>
                                <li><strong> Hospital &amp; Clinic Category</strong></li>
                                <li><strong> Individual Category</strong></li>
                                <li><strong> Medical College/Institute Category</strong></li>
                            </ol>
                            <p><strong>Ready to shine a light? Participate yourself or someone you know for recognition.</strong></p>
                            <p><span>Let's celebrate your achievements together at this Health Services Awards!</span></p>
                            <p><a href="https://calendly.com/d/ck9b-2wq-pjs/participation-interview-fga?month=2024-07"><span>Book An Appointment Now!</span></a></p>
                            <p><span><Link to={`/awards/architecture-awards/`}>Learn More</Link> About the Excellence Award.</span></p>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
