import timg from '../images/resource/riitesh-mehtaa.webp'
import timg0 from '../images/resource/amit-sheth.webp'
import timg1 from '../images/resource/jimmy-mistry.webp'
// import timg2 from '../images/resource/jay-manglani.webp'
import timg3 from '../images/resource/micheal-conner.webp'

import tsimg from '../images/resource/riitesh-mehtaa.webp'
import tsimg0 from '../images/resource/amit-sheth.webp'
import tsimg1 from '../images/resource/jimmy-mistry.webp'
// import tsimg2 from '../images/resource/jay-manglani.webp'
import tsimg3 from '../images/resource/micheal-conner.webp'
// import tsimg8 from '../images/resource/team-16.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Riitesh Mehtaa',
      slug: 'Riitesh Mehtaa',
      title: "Serial Entrepreneur & Business Advisor, The Forttuna Group, UAE",
   },
   {
      Id: '2',
      tImg: timg0,
      tsImg: tsimg0,
      name: 'Mr. Amit Sheth',
      slug: 'Mr. Amit Sheth',
      title: "Award-winning Serial Entrepreneur & Philanthropist, India",
   },
   {
      Id: '3',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Jimmy Mistry',
      slug: 'Jimmy Mistry',
      title: "CMD, Della Group, Singapore",
   },
   // {
   //    Id: '4',
   //    tImg: timg2,
   //    tsImg: tsimg2,
   //    name: 'Jay Magdani',
   //    slug: 'Jay Magdani',
   //    title: "Chief Product Officer - Collective Artists Network, India",
   // },
]

export default Teams;