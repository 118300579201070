import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Aviation-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards: Aviation Awards';
    }, []);
    return (
        <Fragment>
            <Helmet>
                <meta name="description" content="Celebrate exceptional achievements by businesses and individuals in the aviation awards category on the global stage." />
                <meta property="og:title" content="The Forttuna Global Excellence Awards: Aviation Awards" />
                <meta property="og:description" content="Celebrate exceptional achievements by businesses and individuals in the aviation awards category on the global stage." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: Aviation Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="" />
                        </div>
                        <div className="upper-box d-flex justify-content-between flex-wrap">
                            <h2 style={{color: '#fff'}}>The Forttuna Global Excellence Awards: Aviation Awards</h2><br/>
                            {/* <div className="event-detail_author">
                                <div className="event-detail_author-image">
                                    <img src={eimg1} alt="" />
                                </div>
                                <div className="event-detail_author-name">Robert Gates, <span>Art Critic</span></div>
                                <div className="event-detail_author-location"><span>Location:</span> Hall 1, Building, <br /> Golden Street , Southafrica</div>
                                <div className="event-detail_author-timing">08.00 am  09.00 am</div>
                                <div className="event-detail_author-icon flaticon-voice"></div>
                            </div> */}
                        </div>
                        <div>
                            <p><strong>First-ever Jury Led Awards: Be Recognized on the World Stage</strong></p>
                            <p><span>Soar to new heights of recognition!</span></p>
                            <p><span>The Forttuna Global Excellence Awards invites you to take flight in the prestigious Aviation Awards category. This program honors exceptional achievements across the aviation industry, recognizing</span></p>
                            <p><span>innovators who are revolutionizing air travel. From groundbreaking safety measures to exceptional customer service, showcase your commitment to keeping the skies friendly and reaching new heights of excellence.</span></p>
                            <p><strong>What are the Forttuna Global Excellence Awards?</strong><span>.</span></p>
                            <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Prestigious Recognition:</strong><span> Celebrate exceptional achievements by businesses and individuals in the aviation awards category.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Jury:</strong><span> Merit-based selection overseen by industry leaders.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Cross-Industry Impact:</strong><span> Awards across diverse fields, recognizing a broad spectrum of excellence.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Beyond Recognition:</strong><span> Fosters connections and collaboration for a global impact.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Global Reach:</strong><span> Gain recognition and showcase your achievements on the international stage.</span></li>
                            </ul>
                            <p><strong>Why Participate?&nbsp;</strong></p>
                            <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Gain international recognition for your company's innovative ideas and leadership in the aviation awards category.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Showcase your team's talent and inspire others through your achievements. Network with industry leaders at our prestigious ceremony, fostering valuable connections.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Increase your brand profile and attract new opportunities by being recognized on a global scale.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Secure significant media exposure that propels your achievements into the public eye and positions you as a leader in Aviation.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>A distinguished panel of international jury experts ensures a fair and merit-based evaluation, granting you the recognition you deserve.</span></li>
                            </ul>
                            <p><strong>Aviation Awards Categories :</strong></p>
                            <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Angel Investor of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Business Leader of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Business Woman of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>CEO of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>CFO of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>CHRO of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>CIO of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>CMO of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>COO of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>CTO of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Emerging Healthcare Leader of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Entrepreneur of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Influencer of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Lifetime Achievement Award</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Mentor of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Recruiter of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Start-up of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Venture Capitalist of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Young Business Leader of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Young Entrepreneur of the Year</span></li>
                            </ol>
                            <p><strong>Ready to shine a light? Participate yourself or someone you know for recognition.</strong></p>
                            <p><span>Join us in celebrating exceptional achievements in the aviation industry at the prestigious Forttuna Global Excellence Awards - Aviation Category.</span></p>
                            <div class="business-one_button">
                                <a href="https://india.forttuna.co/#form" class="theme-btn btn-style-one" contenteditable="false">
                                    <span class="btn-wrap">
                                        <span class="text-one">Participate Now!</span>
                                        <span class="text-two">Participate Now!</span>
                                    </span>
                                </a>
                            </div>
                            <p><span><Link to={`/fintech-award/`}>Learn More</Link> About the Excellence Award.</span></p>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
