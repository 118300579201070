import React from 'react'
import CountUp from 'react-countup';
import bg from '../../images/background/pattern-19.png'

const FunFact2 = (props) => {

    const funFact = [
        {
            title: '8',
            subTitle: 'Workshops',
            Symbol: 'hr',
            icon: 'flaticon-training'
        },
        {
            title: '124',
            subTitle: 'speakers',
            Symbol: '',
            icon: 'flaticon-mic'
        },
        {
            title: '70',
            subTitle: 'sponsors',
            Symbol: '+',
            icon: 'flaticon-handshake'
        },
        {
            title: '100',
            subTitle: 'countries',
            Symbol: '+',
            icon: 'flaticon-countries'
        },


    ]

    return (

        <section className="counter-two">
            <div className="counter-two_pattern" style={{ backgroundImage: `url(${bg})` }}></div>
            <div className="auto-container">
                <div className="inner-container">
                    <div className="row clearfix">
                        {funFact.map((funfact, fitem) => (
                            <div className="counter-two_block col-lg-3 col-md-6 col-sm-12" key={fitem}>
                                <div className="counter-two_inner">
                                    <span className={`counter-two_block-icon ${funfact.icon}`}></span>
                                    <div className="counter-two_counter"><span><CountUp end={funfact.title} enableScrollSpy /></span>{funfact.Symbol}</div>
                                    <div className="counter-two_text">{funfact.subTitle}</div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
            <span id="counter" className='d-none' />
        </section>

    )
}

export default FunFact2;