import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import { Link } from 'react-router-dom';
import pIcon1 from '../../images/background/Terms-&-Condition.webp';

const TermPage = (props) => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Terms of Use';
    }, []);
    return (
        <Fragment>
            <Helmet>
                <meta name="description" content="Welcome to the Forttuna Global Excellence Awards Terms of Use page, where we outline the terms and conditions governing the use of our awards program. Our platform celebrates exceptional achievements across various industries, and we take pride in recognizing outstanding individuals and organizations for their contributions. Join us in celebrating excellence and review our Terms of Use to learn more about the Forttuna Global Excellence Awards." />
                <meta property="og:title" content="Forttuna Global Excellence Awards | Terms of Use" />
                <meta property="og:description" content="Welcome to the Forttuna Global Excellence Awards Terms of Use page, where we outline the terms and conditions governing the use of our awards program. Our platform celebrates exceptional achievements across various industries, and we take pride in recognizing outstanding individuals and organizations for their contributions. Join us in celebrating excellence and review our Terms of Use to learn more about the Forttuna Global Excellence Awards." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Terms & Coditions</div>
                            <h2 className="page-title_heading">Terms & Coditions</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Terms & Coditions</li>
                        </ul> */}
                    </div>
                </div>
            </section> 
            <section className="privacy-section">
                <div className="auto-container">
                    <div className="privacy-content">
                    <h3>USER TERMS AND CONDITIONS &ndash; PART A</h3>
                        <h4>1. Definitions</h4>
                        <p>
                            "Company" refers to THE FORTTUNA EVENTS ORGANIZING &amp; MANAGING CO LLC, the entity curating the FORTTUNA GLOBAL EXCELLENCE AWARDS. "Event" refers to the FORTTUNA GLOBAL EXCELLENCE AWARDS. "Attendee" refers to any individual or entity
                            attending the Event.
                        </p>
                        <h4>2. Event Registration</h4>
                        <p>
                            Attendees must complete the official registration process provided by the Company to attend the Event. The Company reserves the right to accept or decline registrations at its discretion. Attendees agree to provide accurate and
                            up-to-date information during registration.
                        </p>
                        <h4>3. Participation</h4>
                        <p>Attendees shall conduct themselves in a respectful and professional manner throughout the Event. The Company reserves the right to remove any attendee engaging in disruptive behavior.</p>
                        <h4>4. Intellectual Property</h4>
                        <p>All materials presented at the Event, including presentations, speeches, and branding, are protected by intellectual property laws. Unauthorized use, reproduction, or distribution of these materials is strictly prohibited.</p>
                        <h4>5. Media Consent</h4>
                        <p>Attendees acknowledge that the Company may capture photographs, videos, or audio recordings during the Event. Attendees grant the Company the right to use such media for promotional purposes.</p>
                        <h4>6. Liability</h4>
                        <p>The Company shall not be liable for any loss, injury, or damage sustained by attendees during the Event. Attendees are responsible for their own safety and well-being.</p>
                        <h4>7. Cancellation, Rescheduling, Postponement, and Prepone by Forttuna Global Excellence Awards</h4>
                        <h5>Cancellation:</h5>
                        <p>
                            Forttuna Global Excellence Awards reserves the right to cancel an event at its sole discretion. This decision may be made due to reasons such as insufficient registrations, logistical challenges, force majeure events, or any other
                            unforeseen circumstances. In the event of a cancellation, Forttuna Global Excellence Awards will promptly inform all registered attendees via email or other means of communication provided during registration.
                        </p>
                        <h5>Rescheduling:</h5>
                        <p>
                            Forttuna Global Excellence Awards may decide to reschedule an event due to scheduling conflicts, venue availability, or other logistical concerns. In such cases, registered attendees will be notified of the new event dates, and their
                            registrations will be automatically transferred to the rescheduled event. Attendees unable to attend the rescheduled event will be provided with options for refunds or credits.
                        </p>
                        <h5>Postponement:</h5>
                        <p>
                            If an event is postponed, Forttuna Global Excellence Awards will communicate the new date to registered attendees. Registered attendees may choose to attend the postponed event on the new date or request a refund or credit, subject to
                            the terms and conditions outlined below.
                        </p>
                        <h5>Prepone:</h5>
                        <p>
                            In exceptional circumstances, Forttuna Global Excellence Awards may decide to prepone an event. Registered attendees will be notified of the change in event date and time. Attendees unable to accommodate the new schedule will be
                            provided with options for refunds or credits.
                        </p>
                        <h5>Refund Policies and Procedures:</h5>
                        <h6>Cancellation by Forttuna Global Excellence Awards:</h6>
                        <p>
                            In the event of cancellation by Forttuna Global Excellence Awards, registered attendees will be entitled to a full refund of the registration fee paid.<br />
                            Refunds will be processed using the same payment method used during registration.<br />
                            Forttuna Global Excellence Awards will make reasonable efforts to process refunds within 90 days from the date of cancellation notification.
                        </p>
                        <h6>Cancellation by Attendee:</h6>
                        <p>If an attendee wishes to cancel their registration for reasons unrelated to Forttuna Global Excellence Awards' actions, no refund will be provided.</p>
                        <h6>Limitation of Liability:</h6>
                        <p>
                            Forttuna Global Excellence Awards will not be liable for any losses incurred by attendees due to the cancellation, rescheduling, postponement, or prepone of the awards event. This includes but is not limited to expenses related to
                            travel, accommodation, visa costs, or any other consequential losses.
                        </p>
                        <p>We recommend that attendees consider purchasing travel insurance or taking necessary precautions to mitigate financial risks associated with attending the Forttuna Global Excellence Awards event.</p>
                        <h6>Contact Information:</h6>
                        <p>
                            If you have any questions or concerns regarding this Event Cancellation Policy, please Terms & Coditions at support@forttuna.co. This Event Cancellation Policy is subject to change without prior notice. Please refer to our official website or
                            Terms & Coditions for the most up-to-date version. Your continued registration and participation in Forttuna Global Excellence Awards events imply your acceptance of this policy.
                        </p>
                        <h4>8. Modifications</h4>
                        <p>The Company reserves the right to modify the Event program, content, schedule, and terms and conditions without prior notice.</p>
                        <div class="wpb_text_column wpb_content_element vc_custom_1706866326199">
                            <div class="wpb_wrapper">
                                <h3>NON-DISCLOSURE AGREEMENT (NDA)</h3>
                            </div>
                        </div>
                        <div class="wpb_text_column wpb_content_element">
                            <div class="wpb_wrapper">
                                <h4>1. Parties</h4>
                                <p>
                                    This Non-Disclosure Agreement (&ldquo;Agreement&rdquo;) is entered into by and between THE FORTTUNA EVENTS ORGANIZING &amp; MANAGING CO LLC (&ldquo;Company&rdquo;) and the registered attendee of the FORTTUNA GLOBAL EXCELLENCE
                                    AWARDS (&ldquo;Attendee&rdquo;).
                                </p>
                                <h4>2. Confidential Information</h4>
                                <p>Confidential Information includes, but is not limited to, business strategies, financial data, proprietary content, trade secrets, and any other information disclosed during the Event.</p>
                                <h4>3. Obligations</h4>
                                <p>
                                    The Attendee agrees to keep all Confidential Information strictly confidential. The Attendee shall not disclose, reproduce, or distribute any Confidential Information without prior written consent from the Company. The
                                    Confidential Information shall be used solely for the purpose of participating in the Event.
                                </p>
                                <h4>4. Duration</h4>
                                <p>The obligations set forth in this Agreement shall remain in effect indefinitely from the date of acceptance by the Attendee.</p>
                                <h4>5. Exceptions</h4>
                                <p>
                                    The obligations of confidentiality shall not apply to information that: Was known to the Attendee prior to the Event. Becomes publicly available through no fault of the Attendee. Is rightfully obtained by the Attendee from a
                                    third party without breach of confidentiality.
                                </p>
                                <h4>6. Remedies</h4>
                                <p>
                                    Both parties acknowledge that unauthorized disclosure of Confidential Information may result in irreparable harm. In the event of a breach, the Company is entitled to seek injunctive relief and other remedies available under
                                    law.
                                </p>
                                <h4>7. Agreement</h4>
                                <p>By attending the FORTTUNA GLOBAL EXCELLENCE AWARDS 2023, the Attendee acknowledges having read, understood, and agreed to the terms and conditions of this Non-Disclosure Agreement.</p>
                            </div>
                        </div>
                        <div class="wpb_text_column wpb_content_element vc_custom_1706866339055">
                            <div class="wpb_wrapper">
                                <h3>USER TERMS AND CONDITIONS &ndash; PART B</h3>
                            </div>
                        </div>
                        <div class="wpb_text_column wpb_content_element">
                            <div class="wpb_wrapper">
                                <p>
                                    Forttuna Global Excellence Awards provides a platform for networking and learning among a closed group of honor-winning peers from the industry. By submitting the application form for Forttuna Global Excellence Awards
                                    (&ldquo;FGEA&rdquo;) recognition program, the applicant acknowledges and agrees to the following clauses:
                                </p>
                                <ul>
                                    <li className='li'>The applicant understands and acknowledges that the information provided in the application form, including facts, professional achievements, and any accompanying documentation, may or may not be independently verified by the FGEA.</li>
                                    <li className='li'>The applicant agrees to indemnify, defend, and hold harmless the FGEA, its affiliates, directors, officers, employees, agents, and representatives from and against any and all claims, demands, actions, liabilities, costs, expenses, and damages (including reasonable attorneys&rsquo; fees) arising out of or relating to the information provided in the application form.</li>
                                    <li className='li'>The applicant acknowledges that the FGEA is relying solely on the information provided by the applicant for the purpose of assessing the application for the recognition program.</li>
                                    <li className='li'>The applicant further acknowledges that the FGEA does not make any warranties or representations, express or implied, regarding the accuracy, completeness, or authenticity of the information provided in the application form.</li>
                                    <li className='li'>The applicant agrees to assume all risks associated with the submission of the application form and the inclusion of the information therein in any materials, publications, or communications related to the recognition program.</li>
                                    <li className='li'>The applicant acknowledges that the FGEA reserves the right to reject or disqualify the application, in whole or in part, based on its sole discretion and without any liability or obligation to provide any explanation or reasoning.</li>
                                    <li className='li'>The applicant understands and agrees that the FGEA shall not be held liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with the inclusion, use, or reliance upon the information provided in the application form.</li>
                                    <li className='li'>The applicant represents and warrants that they have the legal right and authority to submit the application form and that the information provided therein is true, accurate, and not misleading.</li>
                                    <li className='li'>This is an application form and does not guarantee that you will be selected as a Winner.</li>
                                    <li className='li'>The Winners will be selected based on a list of parameters designed by the Jury Committee and the results will be intimated accordingly.</li>
                                    <li className='li'>The Winners are selected from amongst the applications received and are in no way reflective of the comparative value/merit of individuals/companies who did not apply.</li>
                                    <li className='li'>FGEA reserves the right to make any changes to the event as it deems necessary.</li>
                                    <li className='li'>All Winners are required to be physically present at the event to collect the recognition. In case they cannot attend, a representative can be present at the FGEA on their behalf.</li>
                                    <li className='li'>Nomination administration fee of $99.00 is non-refundable.</li>
                                    <li className='li'>The final assessment made by the Jury Committee is their independent judgment/opinion based on the information provided in the application form. The Jury Committee does not claim to be subject matter experts and their assessment is subjective in nature.</li>
                                    <li className='li'>If the jury questionnaire isn&rsquo;t submitted within 14 days of receipt, your nomination application will be canceled, and your data will be removed from our database.</li>
                                    <li className='li'>The decision of the Jury Committee will be final and binding.</li>
                                    </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    )
};
export default TermPage;
