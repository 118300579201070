import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom'
import pIcon1 from '../../images/background/Health-2024.jpg';
import pIcon2 from '../../images/resource/Awardee-Deliverables.webp';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Juryindia from '../../components/Juryindia/Team';
import Features from '../../components/Features';
import HealthIndia from '../../components/HealthIndia/HealthIndia';
import VideoArea from '../../components/VideoArea/VideoArea';
import PartnerSection from '../../components/PartnerSection';
import VenueSection from '../../components/VenueSection/VenueSection';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const AboutPage = (props) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | India Edition | Health & Wellness Leaders';
    }, []); // The empty array ensures this runs only once when the component moun
    return (
        <Fragment>
            <Helmet>
                <meta name="description" content="Forttuna Global Excellence Awards | India Edition | Health & Wellness Leaders" />
                <meta property="og:title" content="Forttuna Global Excellence Awards | India Edition | Health & Wellness Leaders" />
                <meta property="og:description" content="The Forttuna Global Excellence Awards understands that health encompasses the most crucial facet of our life, be it physical or mental." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Health & Wellness Leaders Edition</div>
                            <h2 className="page-title_heading">Health & Wellness Leaders Edition</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Health</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <HealthIndia />
            <Features />
            <section className="faq-two">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div class="sec-title centered title-anim">
                                <h2 class="sec-title_heading">Health & Wellness Leaders Categories</h2>
                            </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <ul className="accordion-box style-two">
                                <li className="accordion block active-block">
                                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="accordion block active-block">
                                        <AccordionSummary
                                            expandIcon={""}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            className='acc-btn'
                                        >
                                            <Typography>AYUSH / Spa / Wellness / Yoga Category <div className="icon fa fa-angle-down"></div></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <ol>
                                                <li>Emerging Wellness Brand of the Year</li>
                                                <li>Energy Medicine Practitioner of the Year</li>
                                                <li>Entrepreneur of the Year Health and Wellness</li>
                                                <li>Holistic Healer of the Year</li>
                                                <li>Holistic Nutrition and Lifestyle Program of the Year</li>
                                                <li>Most Innovative Product of the Year in AYUSH</li>
                                                <li>Wellness Centre/Retreat of the Year</li>
                                                <li>Wellness Coach of the Year</li>
                                                <li>Wellness Mentor of the Year</li>
                                                <li>Wellness Visionary of the Year</li>
                                                <li>Yoga Coach of the Year</li>
                                            </ol>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="accordion block active-block">
                                        <AccordionSummary
                                            expandIcon={""}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            className='acc-btn'
                                        >
                                            <Typography>Company and Brands <div className="icon fa fa-angle-down"></div></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <ol>
                                                <li>AI Dermatology Start-up of the Year</li>
                                                <li>Anti-Aging Product of the Year</li>
                                                <li>Care Home Pharmacy Services Provider of the Year</li>
                                                <li>Dental Clinic of the Year</li>
                                                <li>Emerging Healthcare Company of the Year</li>
                                                <li>Emerging Tech Company of the&nbsp;Year</li>
                                                <li>Femtech&nbsp;of&nbsp;the&nbsp;Year</li>
                                                <li>Healthcare Company of the Year</li>
                                                <li>Healthcare Consultancy of the Year</li>
                                                <li>Healthcare Start-up of the Year</li>
                                                <li>Innovative Idea of the Year</li>
                                                <li>Innovative Medical Product of the Year</li>
                                                <li>Long-Term Care Provider of the Year</li>
                                                <li>Marketing Company&nbsp;of&nbsp;the&nbsp;Year</li>
                                                <li>Medical Equipment Company of the Year</li>
                                                <li>Medical Tourism Company of the Year</li>
                                                <li>Mental Health Start-up of the Year</li>
                                                <li>Natural Ingredient Skincare Product of the Year</li>
                                                <li>Nursing Recruitment Centre of the Year</li>
                                                <li>Pharma Company of the Year</li>
                                                <li>Pharma Distributor of the Year</li>
                                                <li>Pharma Innovation Organisation of the Year</li>
                                                <li>Pharmacy Retail Chain of the Year</li>
                                                <li>Product of the Year</li>
                                                <li>Product of the Year - Contraceptive</li>
                                                <li>Protein Snack of the Year</li>
                                                <li>Public Eye Health Company of the Year</li>
                                                <li>Public Healthcare Initiative of the Year</li>
                                                <li>Recruiter of the Year</li>
                                                <li>Start-up of the Year</li>
                                                <li>Stress Management Organisation of the Year</li>
                                                <li>Technology Company of the Year</li>
                                                <li>Well-being Start-up of the Year</li>
                                                <li>Wellness Product of the Year</li>
                                            </ol>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="accordion block active-block">
                                        <AccordionSummary
                                            expandIcon={""}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            className='acc-btn'
                                        >
                                            <Typography>CXO's - Health & Wellness <div className="icon fa fa-angle-down"></div></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <ol>
                                                <li>CEO of the Year</li>
                                                <li>Chief Audit Executive (CAE) of the Year</li>
                                                <li>Chief Product Officer of the Year</li>
                                                <li>CIO of the Year</li>
                                                <li>CISO of the Year</li>
                                                <li>CMIO of the Year</li>
                                                <li>COO of the Year</li>
                                                <li>CTO of the Year</li>
                                                <li>Young CEO of the Year</li>
                                            </ol>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className="accordion block active-block">
                                        <AccordionSummary
                                            expandIcon={""}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            className='acc-btn'
                                        >
                                            <Typography>Hospital & Clinic Category <div className="icon fa fa-angle-down"></div></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <ol>
                                                <li>Aesthetic/Cosmetic Surgery Centre of the Year</li>
                                                <li>Clinic Chain of the Year</li>
                                                <li>Cosmetic Dermatology Clinic of the Year</li>
                                                <li>Digital Rehabilitation Centre of the Year</li>
                                                <li>Eye Hospital of the Year</li>
                                                <li>Fertility Centre of the Year</li>
                                                <li>Hair Clinic of the Year</li>
                                                <li>Hospital of the Year</li>
                                                <li>Innovative Pain Management Program of the Year</li>
                                                <li>Longevity Centre of the Year</li>
                                                <li>Medical Tech Organization of the Year</li>
                                                <li>Mental Health care Center of the Year</li>
                                                <li>Pain Management Program of the Year</li>
                                                <li>Quality and Patient Safety Leader of the Year</li>
                                                <li>Single Specialty Hospital of the Year</li>
                                                <li>Women Surgical Clinic of the Year</li>
                                            </ol>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className="accordion block active-block">
                                        <AccordionSummary
                                            expandIcon={""}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            className='acc-btn'
                                        >
                                            <Typography>Individual Category <div className="icon fa fa-angle-down"></div></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <ol>
                                                <li>Aesthetic Consultant of the Year</li>
                                                <li>Aesthetician of the Year</li>
                                                <li>Aesthetics Skincare Director of the Year</li>
                                                <li>AI Leader of the Year in Health and Social&nbsp;Care</li>
                                                <li>Animal Advocate of the Year</li>
                                                <li>Ayurvedic Physician of the Year</li>
                                                <li>Behaviour Analyst of the Year</li>
                                                <li>Bio-Technology Leader of the Year</li>
                                                <li>Cardiologist of the Year</li>
                                                <li>Cardiothoracic Surgeon of the Year</li>
                                                <li>Care Services Leader of the Year</li>
                                                <li>Child Advocacy &amp; Therapy Leader of the Year</li>
                                                <li>Chiropractor of the Year</li>
                                                <li>Chronic Condition and Wellbeing Leader of the Year</li>
                                                <li>Clinical Mentor of the Year</li>
                                                <li>Coach of the Year</li>
                                                <li>Community Health Impact Leader of the Year</li>
                                                <li>Constructive Healthcare Leader of the Year</li>
                                                <li>Consultant Psychiatrist and Senior Physician Executive of the Year</li>
                                                <li>Cosmetic Plastic Surgeon of the Year</li>
                                                <li>Cosmetologist of the Year</li>
                                                <li>Counsellor of the Year</li>
                                                <li>Data Scientist of the Year</li>
                                                <li>Dentist of the Year</li>
                                                <li>Dermatologist of the Year</li>
                                                <li>Diabetologist of the Year</li>
                                                <li>Dietician of the Year</li>
                                                <li>Digital Restorative Dentist of the Year</li>
                                                <li>Director of the Year</li>
                                                <li>Diversity Leader of the Year</li>
                                                <li>Educator of the Year</li>
                                                <li>Emergency Physician of the Year</li>
                                                <li>Emerging Healthcare Leader of the Year</li>
                                                <li>Emerging Leader of the Year in Home Care</li>
                                                <li>Emerging Medical Communications Leader of the Year</li>
                                                <li>Entrepreneur of the Year</li>
                                                <li>Executive Healthcare Leader of the&nbsp;Year</li>
                                                <li>Executive Women of the Year</li>
                                                <li>Eye Care Leader of the Year</li>
                                                <li>Facial Surgeon of the Year</li>
                                                <li>FemTech Platform of the&nbsp;Year</li>
                                                <li>Fitness Leader of the Year</li>
                                                <li>Fitness Mentor of the Year</li>
                                                <li>Fitness Solution Expert of the Year</li>
                                                <li>Founder of the Year</li>
                                                <li>Founder of the Year in Wellness</li>
                                                <li>Fundraising Leader of the Year</li>
                                                <li>General Surgeon of the Year</li>
                                                <li>Global Impact Leader of&nbsp;the&nbsp;Year</li>
                                                <li>Growth and Expansion Leader of the Year in Home Care</li>
                                                <li>Gynecologist of the Year</li>
                                                <li>Health &amp; Wellness Visionary of the Year</li>
                                                <li>Healthcare Leader of the Year in Chiropractic</li>
                                                <li>Health Coach of the Year</li>
                                                <li>Healthcare Compliance Leader of the Year</li>
                                                <li>Healthcare Education Leader of the Year</li>
                                                <li>Healthcare Entrepreneur of the Year</li>
                                                <li>Healthcare Finance Leader of the Year</li>
                                                <li>Healthcare Leader of the&nbsp;Year</li>
                                                <li>Healthcare Marketing Leader of the Year</li>
                                                <li>Healthcare Marketing Strategy Leader of the Year</li>
                                                <li>Healthcare Mentor Leader of the Year</li>
                                                <li>Healthcare Regulatory Affairs Leader of the Year</li>
                                                <li>Healthcare Researcher of the Year</li>
                                                <li>Healthcare Scientist of the Year</li>
                                                <li>Healthcare Staffing Leader of the Year</li>
                                                <li>Healthcare Technology Integration Leader of the Year</li>
                                                <li>Healthcare Technology Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                                                <li>Healthcare Visionary of the Year</li>
                                                <li>Healthcare Woman of the Year</li>
                                                <li>HR of the Year</li>
                                                <li>Infertility Specialist of the Year</li>
                                                <li>Innovative Health and Well-being Programme of the Year</li>
                                                <li>Innovative Leader of the Year in Gynecology and Oncology</li>
                                                <li>Innovative Mental Health Idea of the Year</li>
                                                <li>Innovative Product Distributor of the Year</li>
                                                <li>Innovative Solutions Leader of the Year</li>
                                                <li>Integrative Health Coach of the Year</li>
                                                <li>Lab Director of the Year</li>
                                                <li>Lifetime Achievement Award</li>
                                                <li>Lifetime Achievement Award in Natural Medicine</li>
                                                <li>Managing Director of the Year</li>
                                                <li>Maxillofacial Prosthodontist of the Year</li>
                                                <li>Medical Device Leader of the Year</li>
                                                <li>MedTech Leader of the Year</li>
                                                <li>Mental Health Leader of the Year</li>
                                                <li>Mental Health Professional of the Year</li>
                                                <li>Mental Health Promoter of the Year</li>
                                                <li>Mental Healthcare Leader of the Year</li>
                                                <li>Mentor of the Year</li>
                                                <li>Mentor of the Year in Quality</li>
                                                <li>Metaphysician of the Year</li>
                                                <li>Midwife of the Year (Wellness and Holistic Care)</li>
                                                <li>Minimally Invasive Surgeon of the Year</li>
                                                <li>Molecular Biologist of the Year</li>
                                                <li>Most Inspiring Leader of the Year</li>
                                                <li>Neurologist of the Year</li>
                                                <li>Neuroscience Leader of the Year</li>
                                                <li>Nursing Coach of the Year</li>
                                                <li>Nutritionist of the Year</li>
                                                <li>Oncologist of the Year</li>
                                                <li>Oncologist Surgeon of the Year</li>
                                                <li>Ophthalmologist of the Year</li>
                                                <li>Optometrist of the Year</li>
                                                <li>Oral Health Promoter of the Year</li>
                                                <li>Orthopaedic Sports Surgeon of the Year</li>
                                                <li>Orthopedic Surgeon of the Year</li>
                                                <li>Osteopath of the Year</li>
                                                <li>Pain Management Leader of the Year</li>
                                                <li>Pain Management Specialist of the Year</li>
                                                <li>Pathologist of the Year</li>
                                                <li>Patient-Centered Innovation Leader of the Year</li>
                                                <li>Physician of the Year</li>
                                                <li>Physiotherapist of&nbsp;the&nbsp;Year</li>
                                                <li>Plastic Surgeon of the Year</li>
                                                <li>Podiatrist of the Year</li>
                                                <li>Posthumous - Lifetime Achievement Award</li>
                                                <li>Postural Specialist of the Year</li>
                                                <li>President of the Year</li>
                                                <li>Psychologist of the Year</li>
                                                <li>Psychotherapist of the Year</li>
                                                <li>Public Health Specialist of the&nbsp;Year</li>
                                                <li>Restorative Dentist of the Year</li>
                                                <li>Scientist of the Year</li>
                                                <li>Skin Cancer Specialist of the Year</li>
                                                <li>Skincare Leader of the Year</li>
                                                <li>Skincare Training and Education Leader of the Year</li>
                                                <li>Social Change Leader of the Year</li>
                                                <li>Social Impact Leader of the Year</li>
                                                <li>Speech Language Pathologist of the Year</li>
                                                <li>Spiritual Education Leader of the Year</li>
                                                <li>Strategist of the Year</li>
                                                <li>Stress Management Leader of the Year</li>
                                                <li>Supply Chain Professional of the Year</li>
                                                <li>Surgeon of the Year</li>
                                                <li>Sustainability Leader of the Year</li>
                                                <li>Technology Leader of the Year in Healthcare and Wellness</li>
                                                <li>Urologist of the Year</li>
                                                <li>Visionary Leader of the Year</li>
                                                <li>Visionary of the Year</li>
                                                <li>Wellness Leader of the Year</li>
                                                <li>Wellness Mentor of the Year</li>
                                                <li>Wellness Visionary of the Year</li>
                                                <li>Young Healthcare Women of the Year</li>
                                                <li>Young Co-Founder of the Year</li>
                                                <li>Young Entrepreneur of the Year</li>
                                                <li>Young Healthcare Entrepreneur of the Year</li>
                                                <li>Young Healthcare Leader of the&nbsp;Year</li>
                                                <li>Young Healthcare Visionary of the Year</li>
                                                <li>Young Wellness Leader of the Year</li>
                                                <li>Young Wellness Visionary of the Year</li>
                                            </ol>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} className="accordion block active-block">
                                        <AccordionSummary
                                            expandIcon={""}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            className='acc-btn'
                                        >
                                            <Typography>Medical College / Institute Category <div className="icon fa fa-angle-down"></div></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <ol>
                                                <li>Clinical Research Innovation of the Year</li>
                                                <li>Education and Placement Leader of the Year</li>
                                            </ol>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panela2'} onChange={handleChange('panela2')} className="accordion block active-block">
                                        <AccordionSummary
                                            expandIcon={""}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            className='acc-btn'
                                        >
                                            <Typography>Wellness and Skincare <div className="icon fa fa-angle-down"></div></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <ol>
                                                <li>Emerging Skincare Product of the Year</li>
                                            </ol>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                {/* <div className="feature-one_pattern" style={{ backgroundImage: `url(${sIcon})` }}></div>
                <div className="feature-one_pattern-two" style={{ backgroundImage: `url(${sIcon2})` }}></div>
                <div className="feature-one_pattern-three" style={{ backgroundImage: `url(${sIcon3})` }}></div> */}
                <div>
                    <img src={pIcon2} alt="Description of the image" />
                </div>
            </section>
            <Juryindia />
            <VideoArea />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPage;
