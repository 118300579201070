import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import { Link } from 'react-router-dom';
import pIcon1 from '../../images/background/Cookies-Policy.webp';

const PrivacyPage = (props) => {

    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Cookies Policy';
    }, []);
    return (
        <Fragment>
            <Helmet>
                <meta name="description" content="Learn about our cookies policy on the Forttuna Global Excellence Awards website." />
                <meta property="og:title" content="Forttuna Global Excellence Awards | Cookies Policy" />
                <meta property="og:description" content="Learn about our cookies policy on the Forttuna Global Excellence Awards website." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Cookie Policy</div>
                            <h2 className="page-title_heading">Cookie Policy</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Cookie Policy</li>
                        </ul> */}
                    </div>
                </div>
            </section> 
            <section className="privacy-section">
                <div className="auto-container">
                    <div className="privacy-content">
                    <h5>Last Updated: [25th January 2024]</h5>
                    <h5>Introduction:</h5>
                    <p>Forttuna Global Excellence Awards ("we," "us," or "our '') utilises cookies and similar technologies on our website to enhance user experience, provide personalised content, and improve our services. This Cookie Policy outlines the types of cookies we use, their purposes, and your choices regarding their management.</p>
                    <h5>What Are Cookies?</h5>
                    <p>Cookies are small text files placed on your device when you visit a website. They serve various purposes, such as recognizing you as a visitor, remembering your preferences, and analysing website performance.</p>
                    <h5>Types of Cookies We Use:</h5>
                    <ol>
                    <li>Essential Cookies:<br />Purpose: Necessary for the proper functioning of our website.<br />Examples: User authentication, security, and session management.</li>
                    <li>Analytical/Performance Cookies:<br />Purpose: Help us understand how visitors interact with our website, allowing us to improve functionality.<br />Examples: Google Analytics, performance tracking tools.</li>
                    <li>Functionality Cookies:<br />Purpose: Enhance the user experience by remembering preferences.<br />Examples: Language selection, personalised settings.</li>
                    <li>Marketing/Targeting Cookies:<br />Purpose: Enable personalised advertising and content based on user interests.<br />Examples: Remarketing pixels, advertising partners.</li>
                    </ol>
                    <h5>Your Consent:</h5>
                    <p>By using our website, you consent to the use of cookies as described in this policy. You can manage your cookie preferences through your browser settings. Please note that blocking certain types of cookies may impact your experience on our site.</p>
                    <h5>How to Manage Cookies:</h5>
                    <p>Most browsers allow you to control cookies through settings. You can typically find these settings in the "Options" or "Preferences" menu of your browser. Different browsers may have varying procedures for managing cookies.</p>
                    <h5>Changes to This Policy:</h5>
                    <p>We may update our Cookie Policy to reflect changes in our practices or for legal reasons. Any updates will be posted on this page, and the date of the latest revision will be indicated.</p>
                    <h5>Contact Us:</h5>
                    <p>For inquiries about our Cookie Policy or any privacy-related concerns, please contact us at [contact@forttuna.co].</p>
                    <p>Thank you for choosing Forttuna Global Excellence Awards.</p>
                    <p>*This Cookie Policy was last updated on [25th January 2024].</p>
                    </div>

                </div>
            </section>
            <Subscribe2 />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    )
};
export default PrivacyPage;
