import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.png'
import bg1 from '../../images/background/10.jpg'
import bg2 from '../../images/background/pattern-25.png'
import bg3 from '../../images/background/pattern-26.png'

const Footer3 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    return (
        <footer className="footer-three" style={{ backgroundImage: `url(${bg1})` }}>
            <div className="footer-three_pattern" style={{ backgroundImage: `url(${bg2})` }}></div>
            <div className="footer-three_pattern-two" style={{ backgroundImage: `url(${bg3})` }}></div>
            <div className="auto-container">

                <div className="footer-three-widgets">
                    <div className="row clearfix">

                        <div className="footer-three_column col-lg-5 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                <div className="footer-three_logo"><Link onClick={ClickHandler} to="/"><img src={Logo} alt="" title="" /></Link></div>
                            </div>
                            <div className="footer-three_text">We have very good strength in innovative technology and tools with over 35 years of experience. We makes long-term investments goal in global companies in different sectors, mainly in Europe and other countries.</div>
                        </div>

                        <div className="footer-three_column col-lg-3 col-md-6 col-sm-12">
                            <ul className="footer-three_contact-list">
                                <li>
                                    <span className="icon fi flaticon-map"></span>
                                    <strong>ADDRESS</strong>
                                    27 Division St, NY, USA
                                </li>
                                <li>
                                    <span className="icon fi flaticon-email-1"></span>
                                    <strong>MAIL TO US</strong>
                                    envato@gmail.com
                                </li>
                                <li>
                                    <span className="icon fi flaticon-clock"></span>
                                    <strong>TIMEING</strong>
                                    Monday - Friday 9am - 6pm
                                </li>
                            </ul>
                            <div className="footer-social_list">
                                <Link onClick={ClickHandler} to="/" className="facebook fa fa-facebook"></Link>
                                <Link onClick={ClickHandler} to="/" className="youtube fi flaticon-youtube"></Link>
                                <Link onClick={ClickHandler} to="/" className="twitter fa fa-twitter"></Link>
                            </div>
                        </div>
                        <div className="footer-three_column col-lg-4 col-md-6 col-sm-12">
                            <div className="newsletter-widget">
                                <div className="footer-newsletter_title">Get The Latest Updates</div>
                                <h4 className="footer-newsletter_heading">Signup For Newsletter</h4>
                                <div className="subscribe-box style-three">
                                    <form method="post" onSubmit={SubmitHandler}>
                                        <div className="form-group">
                                            <input type="email" name="search-field" placeholder="Your Email Address ........" required />
                                            <button type="submit">Subscribe</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div className="footer-bottom">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="column col-lg-6 col-md-12 col-sm-12">
                            <div className="copyright">&copy;  Copyright ©2023 noor_tech. All Rights Reserved</div>
                        </div>
                        <div className="column col-lg-6 col-md-12 col-sm-12">
                            <ul className="footer-bottom-nav">
                                <li><Link onClick={ClickHandler} to="/terms">Terms and conditions</Link></li>
                                <li><Link onClick={ClickHandler} to="/privacy">Privacy policy</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    )
}

export default Footer3;