import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from '../MobileMenu/MobileMenu'
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";
import Logo from '../../images/forttuna-logo2.png'
import pr1 from '../../images/resource/post-thumb-1.jpg'
import pr2 from '../../images/resource/post-thumb-2.jpg'
import pr3 from '../../images/resource/post-thumb-3.jpg'


const Header = (props) => {
    const [searchActive, setSearchState] = useState(false);
    const [cartActive, setCartState] = useState(false);

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <header className="main-header">
            {/* Header Upper */}
            <div className="header-upper">
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">

                        <div className="logo-box">
                            <div className="logo"><Link onClick={ClickHandler} to="/"><img src={Logo} alt="" title="" /></Link></div>
                        </div>

                        <div className="nav-outer">
                            {/* Main Menu */}
                            <nav className="main-menu navbar-expand-md">
                                <div className="navbar-header">
                                    {/* Toggle Button */}
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>
                                </div>

                                <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                                    <ul className="navigation clearfix">
                                        <li><Link onClick={ClickHandler} to="/">Home</Link>
                                            {/* <ul>
                                                <li><Link onClick={ClickHandler} to="/home">Homepage 01</Link></li>
                                                <li><Link onClick={ClickHandler} to="/home-2">Homepage 02</Link></li>
                                                <li><Link onClick={ClickHandler} to="/home-3">Homepage 03</Link></li>
                                                <li className="dropdown"><Link onClick={ClickHandler} to="/">One Pager Style</Link>
                                                    <ul>
                                                        <li><Link onClick={ClickHandler} to="/home-onepage-1">HomePage 01</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/home-onepage-2">HomePage 02</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/home-onepage-3">HomePage 03</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown"><Link onClick={ClickHandler} to="/">Header Styles</Link>
                                                    <ul>
                                                        <li><Link onClick={ClickHandler} to="/home">Header 01</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/home-2">Header 02</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/home-3">Header 03</Link></li>
                                                    </ul>
                                                </li>
                                            </ul> */}
                                        </li>
                                        {/* <li className="dropdown"><Link onClick={ClickHandler} to="/">About</Link>
                                            <ul>
                                                <li><Link onClick={ClickHandler} to="/who-we-are/">Who We Are</Link></li>
                                                <li><Link onClick={ClickHandler} to="/the-team/">The Team</Link></li>
                                                <li><Link onClick={ClickHandler} to="/our-aim/">Our Aim</Link></li>
                                                <li><Link onClick={ClickHandler} to="/faq">Faq's</Link></li> */}
                                                {/* <li><Link onClick={ClickHandler} to="/price">Price</Link></li> */}
                                                {/* <li><Link onClick={ClickHandler} to="/schedule">Schedule</Link></li> */}
                                                {/* <li><Link onClick={ClickHandler} to="/gallery">Gallery</Link></li>
                                                <li><Link onClick={ClickHandler} to="/testimonial">Testimonial</Link></li>
                                                <li><Link onClick={ClickHandler} to="/coming-soon">Coming Soon</Link></li>
                                                <li><Link onClick={ClickHandler} to="/terms">Terms & Condition</Link></li>
                                                <li><Link onClick={ClickHandler} to="/privacy">Privacy & Policy</Link></li> */}
                                            {/* </ul> */}
                                        {/* </li> */}
                                        <li className="dropdown"><Link onClick={ClickHandler} to="/">Global Edition</Link>
                                            <ul>
                                                <li><Link onClick={ClickHandler} to="/health-wellness-edition/">Health & Wellness Leaders Edition</Link></li>
                                                <li><Link onClick={ClickHandler} to="/business-leaders-edition/">Business Leaders Edition</Link></li>
                                                <li><Link onClick={ClickHandler} to="/special-invitees/">Special Invitees</Link></li>
                                                <li><Link onClick={ClickHandler} to="/the-jury/">The Jury</Link></li>
                                                <li><Link onClick={ClickHandler} to="/participation-guidelines/">Participation Guidelines</Link></li>
                                                <li><Link onClick={ClickHandler} to="/award-categories/">Categories</Link></li>
                                                {/* <li><Link onClick={ClickHandler} to="/speaker">Speaker</Link></li>
                                                <li><Link onClick={ClickHandler} to="/speaker-details/Angelina-Holy">Speaker Detail</Link></li> */}
                                            </ul>
                                        </li>
                                        <li className="dropdown"><Link onClick={ClickHandler} to="/">India Edition</Link>
                                            <ul>
                                                <li><Link onClick={ClickHandler} to="/health-wellness-edition-india/">Health & Wellness Leaders Edition</Link></li>
                                                <li><Link onClick={ClickHandler} to="/business-leaders-edition-india/">Business Leaders Edition</Link></li>
                                                <li><Link onClick={ClickHandler} to="/special-invitees-india/">Special Invitees</Link></li>
                                                <li><Link onClick={ClickHandler} to="/the-jury-india/">The Jury</Link></li>
                                                <li><Link onClick={ClickHandler} to="/participation-guidelines-india/">Participation Guidelines</Link></li>
                                                <li><Link onClick={ClickHandler} to="/award-categories-india/">Categories</Link></li>
                                                {/* <li><Link onClick={ClickHandler} to="/event">Events</Link></li>
                                                <li><Link onClick={ClickHandler} to="/event-details/Modern-Marketing-Summit-Sydney">Events Detail</Link></li>
                                                <li><Link onClick={ClickHandler} to="/register">Account</Link></li> */}
                                            </ul>
                                        </li>
                                        <li className="dropdown"><Link onClick={ClickHandler} to="/">Past Editions</Link>
                                            <ul>
                                                <li className="dropdown"><Link onClick={ClickHandler} to="/health-wellness-edition-2023/">Health & Wellness Leaders Edition 2023</Link>
                                                    <ul>
                                                        <li><Link onClick={ClickHandler} to="/special-guests/">Special Guests</Link></li>
                                                        {/* <li><Link onClick={ClickHandler} to="/home-2">Our Partners</Link></li> */}
                                                        <li><Link onClick={ClickHandler} to="/our-partners/">Our Partners</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/past-winners/">Our Winners</Link></li>
                                                    </ul>
                                                </li>
                                                {/* <li><Link onClick={ClickHandler} to="/blog">Our Blog</Link></li>
                                                <li><Link onClick={ClickHandler} to="/blog-left-sidebar">Blog left Sidebar</Link></li>
                                                <li><Link onClick={ClickHandler} to="/blog-classNameic">Blog classNameic</Link></li>
                                                <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business">Blog Detail</Link></li>
                                                <li><Link onClick={ClickHandler} to="/not-found">Not Found</Link></li> */}
                                            </ul>
                                        </li>

                                        <li><Link onClick={ClickHandler} to="/gallery/">Gallery</Link></li>
                                        {/* <li className="dropdown"><Link onClick={ClickHandler} to="/">Gallery</Link>
                                            <ul>
                                                <li><Link onClick={ClickHandler} to="/contact">Contact 01</Link></li>
                                                <li><Link onClick={ClickHandler} to="/contacts/">Contact 02</Link></li>
                                                <li><Link onClick={ClickHandler} to="/contact-3">Contact 03</Link></li>
                                            </ul>
                                        </li> */}
                                        <li className="dropdown"><Link onClick={ClickHandler} to="/">Coverage</Link>
                                            <ul>
                                                <li><Link onClick={ClickHandler} to="/the-fftb-show/">The FFTB Show</Link></li>
                                                <li><Link onClick={ClickHandler} to="/newsroom/">News</Link></li>
                                                <li><Link onClick={ClickHandler} to="/blog/">Blogs</Link></li>
                                                <li><Link onClick={ClickHandler} to="/awards/">Awards</Link></li>
                                                {/* <li><Link onClick={ClickHandler} to="/contact">Contact 01</Link></li>
                                                <li><Link onClick={ClickHandler} to="/contact-2">Contact 02</Link></li>
                                                <li><Link onClick={ClickHandler} to="/contact-3">Contact 03</Link></li> */}
                                            </ul>
                                        </li>
                                        <li><Link onClick={ClickHandler} to="/faq">Faq's</Link></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>

                        {/* Main Menu End*/}
                        <div className="outer-box d-flex align-items-center flex-wrap">

                            

                            <div className="appointmeny_button-box">
                                <a href="https://india.forttuna.co/#form" className="theme-btn btn-style-one">
                                    <span className="btn-wrap">
                                        <span className="text-one">Participate</span>
                                        <span className="text-two">Participate</span>
                                    </span>
                                </a>
                            </div>

                            {/* Mobile Navigation Toggler */}
                            <MobileMenu/>
                        </div>

                    </div>
                </div>
            </div>
            {/*End Header Upper*/}
            {/* <div className={`${searchActive ? "search-active" : ""}`}>
                <div className="search-popup">
                    <div className="color-layer"></div>
                    <button className="close-search" onClick={() => setSearchState(!searchActive)}><span className="flaticon-cancel-1"></span></button>
                    <form method="post" onSubmit={SubmitHandler}>
                        <div className="form-group">
                            <input type="search" name="search-field" placeholder="Search Here" required="" />
                            <button className="fa fa-solid fa-magnifying-glass fa-fw" type="submit"></button>
                        </div>
                    </form>
                </div>
            </div> */}
            {/* <div className={`xs-sidebar-group info-group ${cartActive ? "isActive" : ""}`}>
                <div className="xs-overlay xs-bg-black" onClick={() => setCartState(!cartActive)}></div>
                <div className="xs-sidebar-widget">
                    <div className="sidebar-widget-container">
                        <div className="close-button" onClick={() => setCartState(!cartActive)}>
                            <span className="flaticon-cancel-1"></span>
                        </div>
                        <div className="sidebar-textwidget">
                            <div className="sidebar-info-contents">
                                <div className="content-inner">
                                    <div className="title-box">
                                        <h5>Shopping <span>Bag</span></h5>
                                        <div className="price">$15 from free economy shipping</div>
                                    </div>
                                    <div className="empty-cart-box">
                                        <div className="no-cart">
                                            <span className="icon"><i className='fi flaticon-shopping-cart'></i></span>
                                            No products in cart.
                                        </div>
                                    </div>

                                    <div className="lower-box">
                                        <h5>Popular <span>Suggestions</span></h5>
                                        <div className="post-block">
                                            <div className="inner-box">
                                                <div className="image">
                                                    <img src={pr1} alt="" />
                                                </div>
                                                <h6><Link to="/">Children Event</Link></h6>
                                                <div className="rating">
                                                    <span className="fa fa-star"></span>
                                                    <span className="fa fa-star"></span>
                                                    <span className="fa fa-star"></span>
                                                    <span className="fa fa-star"></span>
                                                    <span className="fa fa-star"></span>
                                                </div>
                                                <div className="price-box">$125</div>
                                                <Link className="theme-btn bag-btn" to="/">add to bag</Link>
                                            </div>
                                        </div>
                                        <div className="post-block">
                                            <div className="inner-box">
                                                <div className="image">
                                                    <img src={pr2} alt="" />
                                                </div>
                                                <h6><Link to="/">Marriage Caremoney</Link></h6>
                                                <div className="rating">
                                                    <span className="fa fa-star"></span>
                                                    <span className="fa fa-star"></span>
                                                    <span className="fa fa-star"></span>
                                                    <span className="fa fa-star"></span>
                                                    <span className="fa fa-star"></span>
                                                </div>
                                                <div className="price-box">$205</div>
                                                <Link className="theme-btn bag-btn" to="/">add to bag</Link>
                                            </div>
                                        </div>
                                        <div className="post-block">
                                            <div className="inner-box">
                                                <div className="image">
                                                    <img src={pr3} alt="" />
                                                </div>
                                                <h6><Link to="/">baby shower</Link></h6>
                                                <div className="rating">
                                                    <span className="fa fa-star"></span>
                                                    <span className="fa fa-star"></span>
                                                    <span className="fa fa-star"></span>
                                                    <span className="fa fa-star"></span>
                                                    <span className="fa fa-star"></span>
                                                </div>
                                                <div className="price-box">$125</div>
                                                <Link className="theme-btn bag-btn" to="/">add to bag</Link>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> */}

        </header>
    )
}

const mapStateToProps = (state) => {
    return {
        carts: state.cartList.cart,
    };
};


export default connect(mapStateToProps, { removeFromCart })(Header);