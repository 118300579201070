import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom';
import pIcon1 from '../../images/background/Awards.jpg';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Awards from '../../components/A1/A1';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';

const EventsPage = (props) => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Be Recognized';
    }, []);
    return (
        <Fragment>
            <Helmet>
                <meta name="description" content="A prestigious global jury-led and merit-based awards program recognizing exceptional achievements across various industries." />
                <meta property="og:title" content="Forttuna Global Excellence Awards | Be Recognized" />
                <meta property="og:description" content="A prestigious global jury-led and merit-based awards program recognizing exceptional achievements across various industries." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Awards</div>
                            <h2 className="page-title_heading">Awards</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Awards</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <Awards />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventsPage;
