import React from 'react';
import { Link } from 'react-router-dom'
import shape1 from '../../images/background/9.jpg'
import bg1 from '../../images/background/price-bg-1.png'
import bg2 from '../../images/background/price-bg-2.png'
import bg3 from '../../images/background/price-bg-3.png'


const pricing = [
    {
        li1: 'Full Conference',
        li2: '(Includes workshop, coffee etc)',
        li3: 'Live Videos',
        li4: '(Can access online free)',
        li5: 'Meet Speaker',
        li6: '(Ask questions privately)',
        title: 'Standard Pass',
        price: '120',
        day: '1 DAY',
        BG: bg1
    },
    {
        li1: 'Full Conference',
        li2: '(Includes workshop, coffee etc)',
        li3: 'Live Videos',
        li4: '(Can access online free)',
        li5: 'Meet Speaker',
        li6: '(Ask questions privately)',
        title: 'Flexible Pass',
        price: '220',
        active: 'active',
        day: '2 DAY',
        BG: bg2
    },
    {
        li1: 'Full Conference',
        li2: '(Includes workshop, coffee etc)',
        li3: 'Live Videos',
        li4: '(Can access online free)',
        li5: 'Meet Speaker',
        li6: '(Ask questions privately)',
        title: 'Business Pass',
        price: '320',
        day: '3 DAY',
        BG: bg3
    },


]


const Pricing2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="price-three" style={{ backgroundImage: `url(${shape1})` }}>
            <div className="auto-container">
                <div className="sec-title light centered">
                    <div className="sec-title_title">Conference Ticket Price</div>
                    <h2 className="sec-title_heading">Get You a Conference <br/>	Tickets Now </h2>
                </div>
                <div className="row clearfix">
                    {pricing.map((pricing, ptem) => (
                        <div className="price-block_three col-lg-4 col-md-6 col-sm-12" key={ptem}>
                            <div className="price-block_three-inner">
                                <div className="price-block_three-pattern" style={{ backgroundImage: `url(${pricing.BG})` }}></div>
                                <div className="price-block_three-title">{pricing.title}</div>
                                <div className="price-block_three-price">${pricing.price} <span>{pricing.day}</span></div>
                                <ul className="price-block_three-options">
                                    <li><strong>{pricing.li1}</strong>{pricing.li2}</li>
                                    <li className="light"><strong>{pricing.li3}</strong>{pricing.li4}</li>
                                    <li className="light"><strong>{pricing.li5}</strong>{pricing.li6}</li>
                                </ul>
                                <div className="price-block_three-button">
                                    <Link onClick={ClickHandler} to="/price" className="theme-btn btn-style-one">
                                        <span className="btn-wrap">
                                            <span className="text-one">Buy Ticket</span>
                                            <span className="text-two">Buy Ticket</span>
                                        </span>
                                    </Link>
                                </div>
                                <div className="price-block_three-charges">Our prices includes 10% charges</div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>
    );
}

export default Pricing2;