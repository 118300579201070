import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Entrepreneur-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards : Healthcare Awards';
    }, []);
    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="A prestigious jury-led and merit-based healthcare awards program recognizing exceptional achievements across various industries." />
                <meta property="og:title" content="The Forttuna Global Excellence Awards : Healthcare Awards" />
                <meta property="og:description" content="A prestigious jury-led and merit-based healthcare awards program recognizing exceptional achievements across various industries." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards : Entrepreneur Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="" />
                        </div>
                        <div className="upper-box d-flex justify-content-between flex-wrap">
                            <h2 style={{color: '#fff'}}>The Forttuna Global Excellence Awards : Entrepreneur Awards</h2><br/>
                            {/* <div className="event-detail_author">
                                <div className="event-detail_author-image">
                                    <img src={eimg1} alt="" />
                                </div>
                                <div className="event-detail_author-name">Robert Gates, <span>Art Critic</span></div>
                                <div className="event-detail_author-location"><span>Location:</span> Hall 1, Building, <br /> Golden Street , Southafrica</div>
                                <div className="event-detail_author-timing">08.00 am  09.00 am</div>
                                <div className="event-detail_author-icon flaticon-voice"></div>
                            </div> */}
                        </div>
                        <div>
                            <p><strong>First-ever Jury Led Awards: Be Recognized on the World Stage</strong></p>
                            <p><span>The spirit of innovation and the courage to chase a vision &ndash; these are the hallmarks of a true entrepreneur. Yet, their tireless efforts often go unrecognized.</span></p>
                            <p>
                                <span>
                                    The Forttuna Global Excellence Awards celebrate these remarkable individuals through our prestigious Entrepreneur Awards. As we honor these remarkable achievements, we collectively illuminate the path for future generations of
                                    entrepreneurs, building a brighter future fueled by innovation, determination, and impactful businesses.
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p><strong>What are the Forttuna Global Excellence Awards?</strong></p>
                            <ul>
                                <li><span>A prestigious jury-led and merit-based entrepreneur awards program recognizing exceptional achievements across various industries.</span></li>
                                <li><span>Award of Excellence to honor individuals and companies through entrepreneur awards making a significant impact on the global landscape.</span></li>
                                <li><span>More than just global awards - a chance to connect and collaborate with like-minded individuals across diverse fields.</span></li>
                            </ul>
                            <p><strong>Why Participate</strong></p>
                            <ul>
                                <li><span>Gain global recognition for your company's innovation and leadership.</span></li>
                                <li><span>Showcase individual talent and inspire others through achievement.</span></li>
                                <li><span>Network with industry leaders at our prestigious entrepreneur awards ceremony.</span></li>
                                <li><span>Elevate your brand profile and attract new opportunities.</span></li>
                                <li><span>Gain significant media exposure, propelling your achievements into the spotlight.</span></li>
                                <li><span>Fair evaluation by a distinguished panel of international jury experts.</span></li>
                            </ul>
                            <p><strong>Entrepreneur Awards Categories :</strong></p>
                            <ol>
                                <li><strong> Architecture, Construction &amp; Engineering</strong></li>
                            </ol>
                            <p><span>Young Entrepreneur of the Year</span></p>
                            <ol>
                                <li><strong> Automobile</strong></li>
                            </ol>
                            <p><span>Young Entrepreneur of the Year</span></p>
                            <ol>
                                <li><strong> Aviation</strong></li>
                            </ol>
                            <p><span>Young Entrepreneur of the Year</span></p>
                            <ol>
                                <li><strong> Finance and Insurance</strong></li>
                            </ol>
                            <p><span>Young Entrepreneur of the Year</span></p>
                            <ol>
                                <li><strong> Fashion and Beauty</strong></li>
                            </ol>
                            <p><span>Young Entrepreneur of the Year</span></p>
                            <ol>
                                <li><strong> Entertainment and Media</strong></li>
                            </ol>
                            <p><span>Young Entrepreneur of the Year</span></p>
                            <ol>
                                <li><strong> Education</strong></li>
                            </ol>
                            <p><span>Young Entrepreneur of the Year</span></p>
                            <ol>
                                <li><strong> Hospitality</strong></li>
                            </ol>
                            <p><span>Young Entrepreneur of the Year</span></p>
                            <ol>
                                <li><strong> Manufacturing</strong></li>
                            </ol>
                            <p><span>Young Entrepreneur of the Year</span></p>
                            <ol>
                                <li><strong> Marketing</strong></li>
                            </ol>
                            <p><span>Young Entrepreneur of the Year</span></p>
                            <ol>
                                <li><strong> Market Research</strong></li>
                            </ol>
                            <p><span>Young Entrepreneur of the Year</span></p>
                            <ol>
                                <li><strong> Human Resources</strong></li>
                            </ol>
                            <p><span>Young Entrepreneur of the Year</span></p>
                            <ol>
                                <li><strong> Real Estate</strong></li>
                            </ol>
                            <p><span>Young Entrepreneur of the Year</span></p>
                            <ol>
                                <li><strong> SME&rsquo;s</strong></li>
                            </ol>
                            <p><span>Young Entrepreneur of the Year</span></p>
                            <ol>
                                <li><strong> Technology</strong></li>
                            </ol>
                            <p><span>Young Entrepreneur of the Year</span></p>
                            <ol>
                                <li><strong> Textile</strong></li>
                            </ol>
                            <p><span>Young Entrepreneur of the Year</span></p>
                            <p><strong>Ready to shine a light? Participate yourself or someone you know for recognition.</strong></p>
                            <p><span>Let's celebrate your achievements together at this Entrepreneur Awards!</span></p>
                            <p><a href="https://calendly.com/d/ck9b-2wq-pjs/participation-interview-fga?month=2024-07"><span>Book An Appointment Now!</span></a></p>
                            <p><span>Learn More About the Excellence Award.</span></p>
                            <p><span>++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++</span></p>
                            <p><strong>Title : The Forttuna Global Excellence Awards: Advertising Awards</strong></p>
                            <p><strong>First-ever Jury Led Awards: Be Recognized on the World Stage</strong></p>
                            <p><span>Advertisers are the storytellers who weave captivating narratives that resonate with audiences.</span></p>
                            <p>
                                <span>
                                    The Forttuna Global Excellence Awards take immense pride in celebrating these very individuals through our prestigious Advertising Awards. It is a tribute to their groundbreaking campaigns and a wellspring of inspiration for
                                    aspiring advertisers.
                                </span>
                            </p>
                            <p><strong>What are the Forttuna Global Excellence Awards?</strong></p>
                            <ul>
                                <li><span>A prestigious jury-led and merit-based advertising awards program recognizing exceptional achievements across various industries.</span></li>
                                <li><span>Award of Excellence to honor individuals and companies through advertising awards making a significant impact on the global landscape.</span></li>
                                <li><span>More than just global awards - a chance to connect and collaborate with like-minded individuals across diverse fields.</span></li>
                            </ul>
                            <p><strong>Why Participate&nbsp;</strong></p>
                            <ul>
                                <li><span>Gain global recognition for your company's innovation and leadership.</span></li>
                                <li><span>Showcase individual talent and inspire others through achievement.</span></li>
                                <li><span>Network with industry leaders at our prestigious advertising awards ceremony.</span></li>
                                <li><span>Elevate your brand profile and attract new opportunities.</span></li>
                                <li><span>Gain significant media exposure, propelling your achievements into the spotlight.</span></li>
                                <li><span>Fair evaluation by a distinguished panel of international jury experts.</span></li>
                            </ul>
                            <p><strong>Advertising Awards Categories :</strong></p>
                            <ol>
                                <li><strong> Marketing</strong></li>
                            </ol>
                            <ol>
                                <li><span>Angel Investor of the Year</span></li>
                                <li><span>Business Leader of the Year</span></li>
                                <li><span>Business Woman of the Year</span></li>
                                <li><span>CEO of the Year</span></li>
                                <li><span>CFO of the Year</span></li>
                                <li><span>CHRO of the Year</span></li>
                                <li><span>CIO of the Year</span></li>
                                <li><span>CMO of the Year</span></li>
                                <li><span>COO of the Year</span></li>
                                <li><span>CTO of the Year</span></li>
                                <li><span>Emerging Healthcare Leader of the Year</span></li>
                                <li><span>Entrepreneur of the Year</span></li>
                                <li><span>Influencer of the Year</span></li>
                                <li><span>Lifetime Achievement Award</span></li>
                                <li><span>Mentor of the Year</span></li>
                                <li><span>Recruiter of the Year</span></li>
                                <li><span>Start-up of the Year</span></li>
                                <li><span>Venture Capitalist of the Year</span></li>
                                <li><span>Young Business Leader of the Year</span></li>
                                <li><span>Young Entrepreneur of the Year</span></li>
                            </ol>
                            <ol>
                                <li><strong> Market Research</strong></li>
                            </ol>
                            <ol>
                                <li><span>Angel Investor of the Year</span></li>
                                <li><span>Business Leader of the Year</span></li>
                                <li><span>Business Woman of the Year</span></li>
                                <li><span>CEO of the Year</span></li>
                                <li><span>CFO of the Year</span></li>
                                <li><span>CHRO of the Year</span></li>
                                <li><span>CIO of the Year</span></li>
                                <li><span>CMO of the Year</span></li>
                                <li><span>COO of the Year</span></li>
                                <li><span>CTO of the Year</span></li>
                                <li><span>Emerging Healthcare Leader of the Year</span></li>
                                <li><span>Entrepreneur of the Year</span></li>
                                <li><span>Influencer of the Year</span></li>
                                <li><span>Lifetime Achievement Award</span></li>
                                <li><span>Mentor of the Year</span></li>
                                <li><span>Recruiter of the Year</span></li>
                                <li><span>Start-up of the Year</span></li>
                                <li><span>Venture Capitalist of the Year</span></li>
                                <li><span>Young Business Leader of the Year</span></li>
                                <li><span>Young Entrepreneur of the Year</span></li>
                            </ol>
                            <p><strong>Ready to shine a light? Participate yourself or someone you know for recognition.</strong></p>
                            <p><span>Let's celebrate your achievements together at this Advertising Awards!</span></p>
                            <p><a href="https://calendly.com/d/ck9b-2wq-pjs/participation-interview-fga?month=2024-07"><span>Book An Appointment Now!</span></a></p>
                            <p><span><Link to={`/awards/entrepreneur-awards/`}>Learn More</Link> About the Excellence Award.</span></p>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
