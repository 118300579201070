import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Bg from '../../images/background/faq.jpg'

const FaqSection = (props) => {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <section className="faq-two">
            <div className="auto-container">
                <div className="row clearfix">
                        <div class="sec-title centered title-anim">
                            <h2 class="sec-title_heading">Health & Wellness Leaders Categories</h2>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <ul className="accordion-box style-two">
                                <li className="accordion block active-block">
                                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="accordion block active-block">
                                        <AccordionSummary
                                            expandIcon={""}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            className='acc-btn'
                                        >
                                            <Typography>AYUSH / Spa / Wellness / Yoga Category <div className="icon fa fa-angle-down"></div></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <ol>
                                                <li>Emerging Wellness Brand of the Year</li>
                                                <li>Energy Medicine Practitioner of the Year</li>
                                                <li>Entrepreneur of the Year Health and Wellness</li>
                                                <li>Holistic Healer of the Year</li>
                                                <li>Holistic Nutrition and Lifestyle Program of the Year</li>
                                                <li>Most Innovative Product of the Year in AYUSH</li>
                                                <li>Wellness Centre/Retreat of the Year</li>
                                                <li>Wellness Coach of the Year</li>
                                                <li>Wellness Mentor of the Year</li>
                                                <li>Wellness Visionary of the Year</li>
                                                <li>Yoga Coach of the Year</li>
                                            </ol>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="accordion block active-block">
                                        <AccordionSummary
                                            expandIcon={""}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            className='acc-btn'
                                        >
                                            <Typography>Company and Brands <div className="icon fa fa-angle-down"></div></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <ol>
                                                <li>AI Dermatology Start-up of the Year</li>
                                                <li>Anti-Aging Product of the Year</li>
                                                <li>Care Home Pharmacy Services Provider of the Year</li>
                                                <li>Dental Clinic of the Year</li>
                                                <li>Emerging Healthcare Company of the Year</li>
                                                <li>Emerging Tech Company of the&nbsp;Year</li>
                                                <li>Femtech&nbsp;of&nbsp;the&nbsp;Year</li>
                                                <li>Healthcare Company of the Year</li>
                                                <li>Healthcare Consultancy of the Year</li>
                                                <li>Healthcare Start-up of the Year</li>
                                                <li>Innovative Idea of the Year</li>
                                                <li>Innovative Medical Product of the Year</li>
                                                <li>Long-Term Care Provider of the Year</li>
                                                <li>Marketing Company&nbsp;of&nbsp;the&nbsp;Year</li>
                                                <li>Medical Equipment Company of the Year</li>
                                                <li>Medical Tourism Company of the Year</li>
                                                <li>Mental Health Start-up of the Year</li>
                                                <li>Natural Ingredient Skincare Product of the Year</li>
                                                <li>Nursing Recruitment Centre of the Year</li>
                                                <li>Pharma Company of the Year</li>
                                                <li>Pharma Distributor of the Year</li>
                                                <li>Pharma Innovation Organisation of the Year</li>
                                                <li>Pharmacy Retail Chain of the Year</li>
                                                <li>Product of the Year</li>
                                                <li>Product of the Year - Contraceptive</li>
                                                <li>Protein Snack of the Year</li>
                                                <li>Public Eye Health Company of the Year</li>
                                                <li>Public Healthcare Initiative of the Year</li>
                                                <li>Recruiter of the Year</li>
                                                <li>Start-up of the Year</li>
                                                <li>Stress Management Organisation of the Year</li>
                                                <li>Technology Company of the Year</li>
                                                <li>Well-being Start-up of the Year</li>
                                                <li>Wellness Product of the Year</li>
                                            </ol>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="accordion block active-block">
                                        <AccordionSummary
                                            expandIcon={""}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            className='acc-btn'
                                        >
                                            <Typography>CXO's - Health & Wellness <div className="icon fa fa-angle-down"></div></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <ol>
                                                <li>CEO of the Year</li>
                                                <li>Chief Audit Executive (CAE) of the Year</li>
                                                <li>Chief Product Officer of the Year</li>
                                                <li>CIO of the Year</li>
                                                <li>CISO of the Year</li>
                                                <li>CMIO of the Year</li>
                                                <li>COO of the Year</li>
                                                <li>CTO of the Year</li>
                                                <li>Young CEO of the Year</li>
                                            </ol>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className="accordion block active-block">
                                        <AccordionSummary
                                            expandIcon={""}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            className='acc-btn'
                                        >
                                            <Typography>Hospital & Clinic Category <div className="icon fa fa-angle-down"></div></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <ol>
                                                <li>Aesthetic/Cosmetic Surgery Centre of the Year</li>
                                                <li>Clinic Chain of the Year</li>
                                                <li>Cosmetic Dermatology Clinic of the Year</li>
                                                <li>Digital Rehabilitation Centre of the Year</li>
                                                <li>Eye Hospital of the Year</li>
                                                <li>Fertility Centre of the Year</li>
                                                <li>Hair Clinic of the Year</li>
                                                <li>Hospital of the Year</li>
                                                <li>Innovative Pain Management Program of the Year</li>
                                                <li>Longevity Centre of the Year</li>
                                                <li>Medical Tech Organization of the Year</li>
                                                <li>Mental Health care Center of the Year</li>
                                                <li>Pain Management Program of the Year</li>
                                                <li>Quality and Patient Safety Leader of the Year</li>
                                                <li>Single Specialty Hospital of the Year</li>
                                                <li>Women Surgical Clinic of the Year</li>
                                            </ol>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className="accordion block active-block">
                                        <AccordionSummary
                                            expandIcon={""}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            className='acc-btn'
                                        >
                                            <Typography>Individual Category <div className="icon fa fa-angle-down"></div></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <ol>
                                                <li>Aesthetic Consultant of the Year</li>
                                                <li>Aesthetician of the Year</li>
                                                <li>Aesthetics Skincare Director of the Year</li>
                                                <li>AI Leader of the Year in Health and Social&nbsp;Care</li>
                                                <li>Animal Advocate of the Year</li>
                                                <li>Ayurvedic Physician of the Year</li>
                                                <li>Behaviour Analyst of the Year</li>
                                                <li>Bio-Technology Leader of the Year</li>
                                                <li>Cardiologist of the Year</li>
                                                <li>Cardiothoracic Surgeon of the Year</li>
                                                <li>Care Services Leader of the Year</li>
                                                <li>Child Advocacy &amp; Therapy Leader of the Year</li>
                                                <li>Chiropractor of the Year</li>
                                                <li>Chronic Condition and Wellbeing Leader of the Year</li>
                                                <li>Clinical Mentor of the Year</li>
                                                <li>Coach of the Year</li>
                                                <li>Community Health Impact Leader of the Year</li>
                                                <li>Constructive Healthcare Leader of the Year</li>
                                                <li>Consultant Psychiatrist and Senior Physician Executive of the Year</li>
                                                <li>Cosmetic Plastic Surgeon of the Year</li>
                                                <li>Cosmetologist of the Year</li>
                                                <li>Counsellor of the Year</li>
                                                <li>Data Scientist of the Year</li>
                                                <li>Dentist of the Year</li>
                                                <li>Dermatologist of the Year</li>
                                                <li>Diabetologist of the Year</li>
                                                <li>Dietician of the Year</li>
                                                <li>Digital Restorative Dentist of the Year</li>
                                                <li>Director of the Year</li>
                                                <li>Diversity Leader of the Year</li>
                                                <li>Educator of the Year</li>
                                                <li>Emergency Physician of the Year</li>
                                                <li>Emerging Healthcare Leader of the Year</li>
                                                <li>Emerging Leader of the Year in Home Care</li>
                                                <li>Emerging Medical Communications Leader of the Year</li>
                                                <li>Entrepreneur of the Year</li>
                                                <li>Executive Healthcare Leader of the&nbsp;Year</li>
                                                <li>Executive Women of the Year</li>
                                                <li>Eye Care Leader of the Year</li>
                                                <li>Facial Surgeon of the Year</li>
                                                <li>FemTech Platform of the&nbsp;Year</li>
                                                <li>Fitness Leader of the Year</li>
                                                <li>Fitness Mentor of the Year</li>
                                                <li>Fitness Solution Expert of the Year</li>
                                                <li>Founder of the Year</li>
                                                <li>Founder of the Year in Wellness</li>
                                                <li>Fundraising Leader of the Year</li>
                                                <li>General Surgeon of the Year</li>
                                                <li>Global Impact Leader of&nbsp;the&nbsp;Year</li>
                                                <li>Growth and Expansion Leader of the Year in Home Care</li>
                                                <li>Gynecologist of the Year</li>
                                                <li>Health &amp; Wellness Visionary of the Year</li>
                                                <li>Healthcare Leader of the Year in Chiropractic</li>
                                                <li>Health Coach of the Year</li>
                                                <li>Healthcare Compliance Leader of the Year</li>
                                                <li>Healthcare Education Leader of the Year</li>
                                                <li>Healthcare Entrepreneur of the Year</li>
                                                <li>Healthcare Finance Leader of the Year</li>
                                                <li>Healthcare Leader of the&nbsp;Year</li>
                                                <li>Healthcare Marketing Leader of the Year</li>
                                                <li>Healthcare Marketing Strategy Leader of the Year</li>
                                                <li>Healthcare Mentor Leader of the Year</li>
                                                <li>Healthcare Regulatory Affairs Leader of the Year</li>
                                                <li>Healthcare Researcher of the Year</li>
                                                <li>Healthcare Scientist of the Year</li>
                                                <li>Healthcare Staffing Leader of the Year</li>
                                                <li>Healthcare Technology Integration Leader of the Year</li>
                                                <li>Healthcare Technology Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                                                <li>Healthcare Visionary of the Year</li>
                                                <li>Healthcare Woman of the Year</li>
                                                <li>HR of the Year</li>
                                                <li>Infertility Specialist of the Year</li>
                                                <li>Innovative Health and Well-being Programme of the Year</li>
                                                <li>Innovative Leader of the Year in Gynecology and Oncology</li>
                                                <li>Innovative Mental Health Idea of the Year</li>
                                                <li>Innovative Product Distributor of the Year</li>
                                                <li>Innovative Solutions Leader of the Year</li>
                                                <li>Integrative Health Coach of the Year</li>
                                                <li>Lab Director of the Year</li>
                                                <li>Lifetime Achievement Award</li>
                                                <li>Lifetime Achievement Award in Natural Medicine</li>
                                                <li>Managing Director of the Year</li>
                                                <li>Maxillofacial Prosthodontist of the Year</li>
                                                <li>Medical Device Leader of the Year</li>
                                                <li>MedTech Leader of the Year</li>
                                                <li>Mental Health Leader of the Year</li>
                                                <li>Mental Health Professional of the Year</li>
                                                <li>Mental Health Promoter of the Year</li>
                                                <li>Mental Healthcare Leader of the Year</li>
                                                <li>Mentor of the Year</li>
                                                <li>Mentor of the Year in Quality</li>
                                                <li>Metaphysician of the Year</li>
                                                <li>Midwife of the Year (Wellness and Holistic Care)</li>
                                                <li>Minimally Invasive Surgeon of the Year</li>
                                                <li>Molecular Biologist of the Year</li>
                                                <li>Most Inspiring Leader of the Year</li>
                                                <li>Neurologist of the Year</li>
                                                <li>Neuroscience Leader of the Year</li>
                                                <li>Nursing Coach of the Year</li>
                                                <li>Nutritionist of the Year</li>
                                                <li>Oncologist of the Year</li>
                                                <li>Oncologist Surgeon of the Year</li>
                                                <li>Ophthalmologist of the Year</li>
                                                <li>Optometrist of the Year</li>
                                                <li>Oral Health Promoter of the Year</li>
                                                <li>Orthopaedic Sports Surgeon of the Year</li>
                                                <li>Orthopedic Surgeon of the Year</li>
                                                <li>Osteopath of the Year</li>
                                                <li>Pain Management Leader of the Year</li>
                                                <li>Pain Management Specialist of the Year</li>
                                                <li>Pathologist of the Year</li>
                                                <li>Patient-Centered Innovation Leader of the Year</li>
                                                <li>Physician of the Year</li>
                                                <li>Physiotherapist of&nbsp;the&nbsp;Year</li>
                                                <li>Plastic Surgeon of the Year</li>
                                                <li>Podiatrist of the Year</li>
                                                <li>Posthumous - Lifetime Achievement Award</li>
                                                <li>Postural Specialist of the Year</li>
                                                <li>President of the Year</li>
                                                <li>Psychologist of the Year</li>
                                                <li>Psychotherapist of the Year</li>
                                                <li>Public Health Specialist of the&nbsp;Year</li>
                                                <li>Restorative Dentist of the Year</li>
                                                <li>Scientist of the Year</li>
                                                <li>Skin Cancer Specialist of the Year</li>
                                                <li>Skincare Leader of the Year</li>
                                                <li>Skincare Training and Education Leader of the Year</li>
                                                <li>Social Change Leader of the Year</li>
                                                <li>Social Impact Leader of the Year</li>
                                                <li>Speech Language Pathologist of the Year</li>
                                                <li>Spiritual Education Leader of the Year</li>
                                                <li>Strategist of the Year</li>
                                                <li>Stress Management Leader of the Year</li>
                                                <li>Supply Chain Professional of the Year</li>
                                                <li>Surgeon of the Year</li>
                                                <li>Sustainability Leader of the Year</li>
                                                <li>Technology Leader of the Year in Healthcare and Wellness</li>
                                                <li>Urologist of the Year</li>
                                                <li>Visionary Leader of the Year</li>
                                                <li>Visionary of the Year</li>
                                                <li>Wellness Leader of the Year</li>
                                                <li>Wellness Mentor of the Year</li>
                                                <li>Wellness Visionary of the Year</li>
                                                <li>Young Healthcare Women of the Year</li>
                                                <li>Young Co-Founder of the Year</li>
                                                <li>Young Entrepreneur of the Year</li>
                                                <li>Young Healthcare Entrepreneur of the Year</li>
                                                <li>Young Healthcare Leader of the&nbsp;Year</li>
                                                <li>Young Healthcare Visionary of the Year</li>
                                                <li>Young Wellness Leader of the Year</li>
                                                <li>Young Wellness Visionary of the Year</li>
                                            </ol>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} className="accordion block active-block">
                                        <AccordionSummary
                                            expandIcon={""}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            className='acc-btn'
                                        >
                                            <Typography>Medical College / Institute Category <div className="icon fa fa-angle-down"></div></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <ol>
                                                <li>Clinical Research Innovation of the Year</li>
                                                <li>Education and Placement Leader of the Year</li>
                                            </ol>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panela2'} onChange={handleChange('panela2')} className="accordion block active-block">
                                        <AccordionSummary
                                            expandIcon={""}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            className='acc-btn'
                                        >
                                            <Typography>Wellness and Skincare <div className="icon fa fa-angle-down"></div></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <ol>
                                                <li>Emerging Skincare Product of the Year</li>
                                            </ol>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </li>
                            </ul>
                        </div>

                    <section  style={{marginTop: '30px'}}>
                        <div className="auto-container">
                            <div className="row clearfix">
                                <div class="sec-title centered title-anim">
                                        <h2 class="sec-title_heading">Business Leaders Categories</h2>
                                    </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <ul className="accordion-box style-two">
                                    <li>
                                        <Accordion expanded={expanded === 'panelb24'} onChange={handleChange('panelb24')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>Architecture/Construction/Engineering <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Business Leader of the Year</li>
                                                    <li>Business Woman of the Year</li>
                                                    <li>Chief Eco-System Officer of the Year</li>
                                                    <li>Construction Technology Leader of the Year</li>
                                                    <li>Emerging Healthcare Leader of the Year</li>
                                                    <li>Entrepreneur of the Year</li>
                                                    <li>Influencer of the Year</li>
                                                    <li>Interior Designer of the Year</li>
                                                    <li>Legal Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                                                    <li>Mentor of the Year</li>
                                                    <li>Most Innovative Company of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion expanded={expanded === 'panelb25'} onChange={handleChange('panelb25')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>Automobile <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Business Leader of the Year</li>
                                                    <li>Entrepreneur of the Year</li>
                                                    <li>Influencer of the Year</li>
                                                    <li>Lifetime Achievement Award</li>
                                                    <li>Sales Leader of the Year</li>
                                                    <li>Strategist of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion expanded={expanded === 'panelb26'} onChange={handleChange('panelb26')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>Aviation <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Aviation Leader of the Year</li>
                                                    <li>Business Leader of the Year</li>
                                                    <li>Procurement Leader of the Year</li>
                                                    <li>Young Business Leader of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion expanded={expanded === 'panelb27'} onChange={handleChange('panelb27')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>Education <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Business Leader of the Year</li>
                                                    <li>Business Woman of the Year</li>
                                                    <li>CEO of the Year</li>
                                                    <li>CFO of the Year</li>
                                                    <li>Chief Learning Officer of the Year</li>
                                                    <li>CIO of the Year</li>
                                                    <li>Co-Founder of the Year</li>
                                                    <li>Compliance Training Company of the Year (edtech)</li>
                                                    <li>COO of the Year</li>
                                                    <li>CTO of the Year</li>
                                                    <li>Data Privacy Leader of the Year</li>
                                                    <li>Deputy Vice-Chancellor of the Year</li>
                                                    <li>Director of Music of the Year</li>
                                                    <li>Director of the Year</li>
                                                    <li>Education Company of the Year</li>
                                                    <li>Education Leader of the Year</li>
                                                    <li>Educator of the Year</li>
                                                    <li>Emerging Author of the Year</li>
                                                    <li>Emerging Entrepreneur in Higher Education of the Year</li>
                                                    <li>Enrollment Leader of the Year</li>
                                                    <li>Entrepreneur of the Year</li>
                                                    <li>Female Publisher of the Year</li>
                                                    <li>Human-Centered Design Leader of the Year</li>
                                                    <li>Influencer of the Year</li>
                                                    <li>Innovative Leader of the Year</li>
                                                    <li>Innovative Leadership Development Program of the Year</li>
                                                    <li>Innovator Facilitator of the Year</li>
                                                    <li>Learning &amp; Development Leader of the Year</li>
                                                    <li>Legal Advocacy Leader of the Year</li>
                                                    <li>Lifetime Achievement Award</li>
                                                    <li>Lifetime Achievement Award In Leadership Development</li>
                                                    <li>Lifetime Achievement in Higher Education Management</li>
                                                    <li>Mentor of the Year</li>
                                                    <li>Most Influential Young Educator of the Year</li>
                                                    <li>Most Innovative Business Leader of the Year</li>
                                                    <li>Most Innovative Leader of the&nbsp;Year</li>
                                                    <li>Most Inspiring Business Leader of the Year</li>
                                                    <li>Most Inspiring Leader of the&nbsp;Year</li>
                                                    <li>Non-Profit of the Year</li>
                                                    <li>Personal Leadership Executive Coach of the Year</li>
                                                    <li>Quality Leader of the Year</li>
                                                    <li>Social Innovation Leader of the Year</li>
                                                    <li>Start-up of the Year</li>
                                                    <li>Strategic &amp; Visionary Leader of the Year in Higher Education</li>
                                                    <li>Strategic Leader of the Year</li>
                                                    <li>Strategist of the Year</li>
                                                    <li>Visionary Leader of the Year</li>
                                                    <li>Visionary of the Year</li>
                                                    <li>Well-being &amp; Human Flourishing Leader of the Year</li>
                                                    <li>Women Leader of the Year in Social Impact</li>
                                                    <li>Young Business Leader of the Year</li>
                                                    <li>Young Entrepreneur of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion expanded={expanded === 'panelb28'} onChange={handleChange('panelb28')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>Entertainment and Media <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Animation and Visual Effects Community Leader of the Year</li>
                                                    <li>Business Journalist of the Year</li>
                                                    <li>Business Leader of the Year</li>
                                                    <li>Business Woman of the Year</li>
                                                    <li>Content Leader of the Year</li>
                                                    <li>Creative Director of the Year</li>
                                                    <li>CTO of the Year</li>
                                                    <li>Emerging Healthcare Leader of the Year</li>
                                                    <li>Entrepreneur of the Year</li>
                                                    <li>Film and Television Production Leader of the Year</li>
                                                    <li>Mentor of the Year</li>
                                                    <li>Presenter of the Year</li>
                                                    <li>Start-up of the Year</li>
                                                    <li>Travel Influencer of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion expanded={expanded === 'panelb29'} onChange={handleChange('panelb29')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>Fashion and Beauty <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Board Member of the Year</li>
                                                    <li>Brand Leader of the Year</li>
                                                    <li>Business Leader of the Year</li>
                                                    <li>Business Woman of the Year</li>
                                                    <li>CEO of the Year</li>
                                                    <li>CMO of the Year</li>
                                                    <li>COO of the Year</li>
                                                    <li>Cultural Heritage Fashion Leader of the Year</li>
                                                    <li>CX Leader of the Year</li>
                                                    <li>Digital Innovation Leader of the Year</li>
                                                    <li>Director of the Year</li>
                                                    <li>Emerging Healthcare Leader of the Year</li>
                                                    <li>Entrepreneur of the Year</li>
                                                    <li>Fashion Brand of the Year</li>
                                                    <li>Founder of the Year</li>
                                                    <li>Fragrance Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                                                    <li>Haircare Company of the Year</li>
                                                    <li>Social Impact Leader of the Year</li>
                                                    <li>Start-up of the Year</li>
                                                    <li>Strategic Business Development Leader of the Year</li>
                                                    <li>Visionary Leader of the Year</li>
                                                    <li>Young Business Leader of the Year</li>
                                                    <li>Young Entrepreneur of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion expanded={expanded === 'panelb30'} onChange={handleChange('panelb30')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>Finance and Insurance <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Banking Pofessional of the Year</li>
                                                    <li>Business Leader of the Year</li>
                                                    <li>Business Woman of the Year</li>
                                                    <li>Cash Management Leader of the Year</li>
                                                    <li>CEO of the Year</li>
                                                    <li>CFO of the Year</li>
                                                    <li>Compliance Officer of the Year</li>
                                                    <li>CTO of the Year</li>
                                                    <li>Entrepreneur of the Year</li>
                                                    <li>Finance Company of the Year</li>
                                                    <li>Finance Leader of the Year</li>
                                                    <li>FinTech Leader of the Year</li>
                                                    <li>Impact Investor of the Year</li>
                                                    <li>Investment Leader of the Year</li>
                                                    <li>Regional Director of the Year</li>
                                                    <li>Venture Capitalist of the Year</li>
                                                    <li>Visionary of the Year</li>
                                                    <li>Wealth Management Leader of the Year</li>
                                                    <li>Young Business Leader of the Year</li>
                                                    <li>Young Entrepreneur of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion expanded={expanded === 'panelb31a'} onChange={handleChange('panelb31a')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>Governance <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Influencer of the Year</li>
                                                    <li>Leader of the Year - Government Services</li>
                                                    <li>Mentor of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion expanded={expanded === 'panelb31'} onChange={handleChange('panelb31')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>Hospitality <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Business Leader of the Year</li>
                                                    <li>Business Woman of the Year</li>
                                                    <li>CEO of the Year</li>
                                                    <li>Entrepreneur of the Year</li>
                                                    <li>Facility Management Leader of the Year</li>
                                                    <li>Influencer of the Year</li>
                                                    <li>Start-up of the Year</li>
                                                    <li>Travel Influencer of the Year</li>
                                                    <li>Travel Professional of the Year</li>
                                                    <li>Young Business Leader of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion expanded={expanded === 'panelb32'} onChange={handleChange('panelb32')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>Human Resources <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Business Leader of the Year</li>
                                                    <li>Business Leadership Educator of the Year</li>
                                                    <li>Business Woman of the Year</li>
                                                    <li>CEO of the Year</li>
                                                    <li>CHRO of the Year</li>
                                                    <li>Entrepreneur of the Year</li>
                                                    <li>HR Leader &amp; Woman Entreprenuer of the Year</li>
                                                    <li>HR Leader of the Year</li>
                                                    <li>HR Outsourcing Leader of the Year</li>
                                                    <li>HR Outsourcing Woman Leader of the Year</li>
                                                    <li>Infleuncial Talent Leader of the Year</li>
                                                    <li>Influencer of the Year</li>
                                                    <li>Mentor of the Year</li>
                                                    <li>Recruiter of the Year</li>
                                                    <li>Start-up of the Year</li>
                                                    <li>Talent Acquisition Leader of the Year</li>
                                                    <li>Young Entrepreneur of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion expanded={expanded === 'panelb34'} onChange={handleChange('panelb34')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>Law <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Business Leader of the Year</li>
                                                    <li>Corporate Lawyer of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion expanded={expanded === 'panelb33'} onChange={handleChange('panelb33')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>Manufacturing <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Business Leader of the Year</li>
                                                    <li>Business Woman of the Year</li>
                                                    <li>CEO of the Year</li>
                                                    <li>Entrepreneur of the Year</li>
                                                    <li>Lifetime Achievement Award</li>
                                                    <li>Young Business Leader of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion expanded={expanded === 'panelb36'} onChange={handleChange('panelb36')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>Market Research <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Business Woman of the Year</li>
                                                    <li>CEO of the Year</li>
                                                    <li>Director of the Year</li>
                                                    <li>Emerging Healthcare Leader of the Year</li>
                                                    <li>Innovation Leader of the Year</li>
                                                    <li>Market Research Company of the Year</li>
                                                    <li>Market Research Expert of the Year</li>
                                                    <li>Mentor of the Year</li>
                                                    <li>Research Services Organisation of the Year</li>
                                                    <li>Researcher of the Year</li>
                                                    <li>Supply Chain Professional of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion expanded={expanded === 'panelb37'} onChange={handleChange('panelb37')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>Marketing <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Advertising Leader of the Year</li>
                                                    <li>Brand Strategist of the Year</li>
                                                    <li>Brand Strategy Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                                                    <li>Business Coach of the Year</li>
                                                    <li>Business Leader of the Year</li>
                                                    <li>Business Woman of the Year</li>
                                                    <li>CEO of the Year</li>
                                                    <li>Chief Commercial Officer of the Year</li>
                                                    <li>CMO of the Year</li>
                                                    <li>Content Marketer of the Year</li>
                                                    <li>COO of the Year</li>
                                                    <li>Creative Director of the Year</li>
                                                    <li>Data Analytics Leader of the Year</li>
                                                    <li>Digital Marketing Leader of the Year</li>
                                                    <li>Director of the Year</li>
                                                    <li>Emerging Healthcare Leader of the Year</li>
                                                    <li>Emerging Business Woman of the Year</li>
                                                    <li>Entrepreneur of the Year</li>
                                                    <li>Female Entrepreneur of the Year</li>
                                                    <li>Healthcare Marketing Leader of the Year</li>
                                                    <li>Humanitarian Philanthropy Leader of the Year</li>
                                                    <li>Influencer of the Year</li>
                                                    <li>Innovative Business Idea of the Year</li>
                                                    <li>Integrated Marketing Communications Leader of the Year</li>
                                                    <li>Marketer of the Year</li>
                                                    <li>Marketing Company of the Year</li>
                                                    <li>Marketing Leader of the Year</li>
                                                    <li>Marketing Strategist of the Year</li>
                                                    <li>Media &amp; Advertising Leader of the Year</li>
                                                    <li>Mentor of the Year</li>
                                                    <li>Sales Director of the Year</li>
                                                    <li>Social Media Innovator of the Year</li>
                                                    <li>Start-up of the Year</li>
                                                    <li>Sustainability Leader of the Year</li>
                                                    <li>VP of the Year</li>
                                                    <li>Woman Healthcare Leader of the Year</li>
                                                    <li>Young Business Leader of the Year</li>
                                                    <li>Young Entrepreneur of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion expanded={expanded === 'panelb38'} onChange={handleChange('panelb38')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>Real Estate <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Business Leader of the Year</li>
                                                    <li>Business Strategist of&nbsp;the&nbsp;Year</li>
                                                    <li>Business Woman of the Year</li>
                                                    <li>CEO of the Year</li>
                                                    <li>CTO of the Year</li>
                                                    <li>Entrepreneur of the Year</li>
                                                    <li>Most Innovative Marketer of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion expanded={expanded === 'panelb39'} onChange={handleChange('panelb39')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>SME's <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Business Competency Leader of the Year</li>
                                                    <li>Business Leader of the Year</li>
                                                    <li>Business Woman of the Year</li>
                                                    <li>CEO of the Year</li>
                                                    <li>CMO of the Year</li>
                                                    <li>Consultancy of the Year (Outsourcing Services)</li>
                                                    <li>Cybersecurity Evangelist of the Year</li>
                                                    <li>Entrepreneur of the Year</li>
                                                    <li>Healthcare Entrepreneur of the Year</li>
                                                    <li>Innovator of the Year</li>
                                                    <li>Lifetime Achievement Award in Operations</li>
                                                    <li>Managing Director of the Year</li>
                                                    <li>Mentor of the Year</li>
                                                    <li>Pastry Chef of the Year</li>
                                                    <li>Patisserie Company of the Year</li>
                                                    <li>SME of the Year</li>
                                                    <li>Start-up of the Year</li>
                                                    <li>Women Entrepreneur of the Year</li>
                                                    <li>Young Entrepreneur of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion expanded={expanded === 'panelb40'} onChange={handleChange('panelb40')} className="accordion block active-block">
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className='acc-btn'
                                            >
                                                <Typography>Technology <div className="icon fa fa-angle-down"></div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                <ol>
                                                    <li>Ad-Tech Leader of the Year</li>
                                                    <li>AVP of the Year</li>
                                                    <li>Blockchain Consultant of the Year</li>
                                                    <li>Business Leader of the Year</li>
                                                    <li>Business Transformation Leader of the Year</li>
                                                    <li>Business Woman of the Year</li>
                                                    <li>CEO of the Year</li>
                                                    <li>CFO of the Year</li>
                                                    <li>Chief Interoperability Officer of the Year</li>
                                                    <li>Chief Revenue Officer of the Year</li>
                                                    <li>CIO of the Year</li>
                                                    <li>Cloud Computing Innovator of the Year</li>
                                                    <li>CMO of the Year</li>
                                                    <li>Creative Developer of the Year</li>
                                                    <li>CTO of the Year</li>
                                                    <li>Cybersecurity Business of the Year</li>
                                                    <li>Cybersecurity Leader of the Year</li>
                                                    <li>Data Center Management Leader of the Year</li>
                                                    <li>Digital Disruptor of the Year</li>
                                                    <li>Digital Technology Company of the Year</li>
                                                    <li>Digital Transformation Leader of the Year</li>
                                                    <li>Emerging Healthcare Leader of the Year</li>
                                                    <li>Emerging Entrepreneur of the Year</li>
                                                    <li>Emerging Technology Advocate of the Year</li>
                                                    <li>Entrepreneur of the Year</li>
                                                    <li>Founder of the Year</li>
                                                    <li>Influencer of the Year</li>
                                                    <li>Innovative Icon of the Year</li>
                                                    <li>Innovative Leader of the Year</li>
                                                    <li>Innovator of the Year</li>
                                                    <li>IT Leader of the Year</li>
                                                    <li>Lifetime Achievement Award</li>
                                                    <li>Lifetime Achievement Award in Agriculture and Development</li>
                                                    <li>Achievement Award in Engineering and Manufacturing</li>
                                                    <li>Mental Health Tech Innovator Leader of the Year</li>
                                                    <li>Mentor of the Year</li>
                                                    <li>Most Innovative Company of the Year</li>
                                                    <li>Most Innovative Technology Leader of the Year</li>
                                                    <li>Power and Water Utilities Leader of the Year</li>
                                                    <li>Sales and Marketing Leader of the&nbsp;Year</li>
                                                    <li>Scientist of the Year</li>
                                                    <li>Social Impact Leader of the Year</li>
                                                    <li>Start-up of the Year</li>
                                                    <li>Strategic Cloud Technology Leader of the Year</li>
                                                    <li>Strategic Leader of the Year</li>
                                                    <li>Technology Company of the Year</li>
                                                    <li>Technology Leader of the Year</li>
                                                    <li>Utility Industry Leader of the Year</li>
                                                    <li>Visionary of the Year</li>
                                                    <li>Website Design &amp; Development Company of the Year</li>
                                                    <li>Young Business Leader of the Year</li>
                                                    <li>Young Business Woman of the Year</li>
                                                    <li>Young CEO of the Year</li>
                                                    <li>Young Entrepreneur of the Year</li>
                                                    <li>Young Technology Leader of the Year</li>
                                                </ol>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>

    )
}

export default FaqSection;