import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Bg from '../../images/background/faq.jpg'

const FaqSection = (props) => {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <section className="faq-two">
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <ul className="accordion-box style-two">
                            <li className="accordion block active-block">
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Does participating in the Forttuna Global Excellence Awards guarantee that I will win?<div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Participation in the Fortuna Global Excellence Awards does not guarantee a win. Our program is jury-led and merit-based. Our winners are decided by the Jury.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Is this a paid award? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        No, the Forttuna Global Excellence Awards is not a paid award. We celebrate and honor outstanding individuals and organizations in their respective industry based on their merits, contributions, and achievements rather than financial considerations. It is our commitment to recognizing excellence.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>When & Where is the FORTTUNA Global Excellence Awards taking place? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <p>The Forttuna Global Excellence Awards will be held at two distinct times and locations:</p>
                                        <p><b>Global Edition:</b> This event is scheduled for December 2024 and will take place in the Dubai, United Arab Emirates (UAE).</p>
                                        <p><b>India Edition:</b> The event will be held in June 2025, with the event set to take place in Mumbai, India.</p>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>How did I get invited for an interview? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        The invitation process is a result of meticulous research conducted by our dedicated team in collaboration with a research unit. We employ comprehensive criteria, including recommendations, educational qualifications, professional experience, outstanding accomplishments, and recognition in your respective sector. This thorough evaluation identifies individuals who stand out within the industry.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>What are the steps to participation? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Certainly, we've outlined the participation process for your convenience:
                                            Begin by visiting our page at <a href='https://india.forttuna.co/'>https://india.forttuna.co/</a>, where you can fill out our Application Form. As part of this process, a $99 + 5% VAT per category, non-refundable Participation Fee is required.
                                            Upon submitting your application and completing the payment for Participation Fee, our team will send you a specialized questionnaire. This questionnaire is thoughtfully designed by our esteemed Jury to gain a deeper understanding of your contributions and achievements.
                                            After we receive your completed questionnaire, our distinguished Jury, known for its fair and meticulous evaluations, will review your application. If your application align with the established criteria, you will receive an exclusive invitation to attend the prestigious "FORTTUNA GLOBAL EXCELLENCE AWARDS".

                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panelb16'} onChange={handleChange('panelb16')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Is there a limit to the number of categories one can apply for? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Yes, an individual may apply for a minimum of one category and a maximum of six categories. This limit applies regardless of whether the application is made by an individual or a company.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel5v'} onChange={handleChange('panel5v')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>If a participant applies for a custom category that no one else has applied for, will they automatically win?<div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        No, submitting an application in a custom category does not guarantee an award. Even if no other applicants are in that category, the jury reserves the right to reject an application if it does not meet the established criteria or standards. The absence of other candidates does not automatically result in a win for the sole applicant. The decision to award is based solely on the merit of the application according to the category’s specific requirements.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>I have paid the participation fee. What is the next step? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        The next step is that our nominations team will contact you within 24 to 48 hours with the Jury-based questionnaire.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>How can I confirm that my participation fee has been received? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        You will receive a confirmation email once your participation fee has been processed. If you do not receive this email, please contact us at nominations@forttuna.co.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Can I get a refund if I cancel my participation? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Unfortunately, all fees associated with the participation process are non-refundable. We recommend that you carefully consider your participation before proceeding.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>It's been more than 48 hours since I submitted my application and I am still waiting to receive the questionnaire. <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Our team usually sends the questionnaire email within 24 to 48 hours. Please check your spam/junk folder, as our emails may inadvertently land there.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>I have logged into the portal but cannot access the jury questionnaire. <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        After logging into the portal, you will see a tab named "Jury Questionnaire," where you will find the assigned questions.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>I have submitted the questionnaire but want to edit my responses. What can I do? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Once you submit the questionnaire, it is sent to the Jury for evaluation and cannot be edited.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Can I make changes to my application after submission? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Changes to the application can only be made before the final submission. Once submitted, the details are locked for jury review.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>I have submitted my questionnaire responses. What is the next step, and when will I know the results? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        It takes 30 to 45 working days from the day you submit your jury questionnaire for the jury to deliberate on all applications in your category. We will reach out to you once the jury has reviewed your submission.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li className="accordion block active-block">
                                <Accordion expanded={expanded === 'panelb1'} onChange={handleChange('panelb1')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>I cannot submit the responses by the mentioned deadline. Can I get an extension? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        We can provide only one extension, and the deadline for that extension will be firm. No further extensions will be granted. If you would like to apply for an extension, please contact us at nominations@forttuna.co.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panelb2'} onChange={handleChange('panelb2')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>I received an email to craft a video byte. Is it mandatory to submit it, and will it affect my application if I don't? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Submitting a video byte is optional and will not impact your application. It is a way to promote your participation.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panelb3'} onChange={handleChange('panelb3')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>I created the video byte but cannot send it via email due to size restrictions. How can I send it to you? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        We suggest using a cloud storage solution. You can create a dedicated folder on Google Drive and share the link to that folder with us for a seamless and secure transfer.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panelb4'} onChange={handleChange('panelb4')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>I have submitted my video byte. When will it be posted on your social media? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Your video byte will be posted in due course once it goes through our quality checks and editing process. All video bytes are posted in the order in which they are received.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panelb7'} onChange={handleChange('panelb7')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Will I be notified when my video byte is posted on your social media channels? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Yes, when your video byte is posted, our Social Media Team will ensure that you are tagged in the post across all our social media channels.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panelb5'} onChange={handleChange('panelb5')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>How does the Jury evaluate applications? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <p>The Jury evaluates applications through a comprehensive three-level internal screening process. This method ensures a thorough and unbiased assessment of each candidate's qualifications. Here&rsquo;s how the evaluation is structured:</p>
                                        <p><strong>Initial Review by the Preliminary Jury:</strong> This first level involves a preliminary assessment of applications. The jury conducts an initial review to determine adherence to the application guidelines.</p>
                                        <p><strong>Detailed Review by a Jury Lead:</strong> Applications that pass the initial review proceed to this stage, where a Jury Lead conducts an in-depth evaluation of the candidate's application, submission, and supporting documents along with the candidate&rsquo;s credentials, achievements, and overall suitability.</p>
                                        <p><strong>Final Review by the Grand Jury:</strong> The final level of scrutiny is conducted by our Grand Jury. Only the applications that excel through the previous stages make it to this round, ensuring that only the most outstanding candidates are considered.</p>
                                        <p><strong>PLEASE NOTE:</strong><strong> Scores are assigned objectively based on the evidence and information provided by each candidate in their questionnaire responses. APPLICATIONS THAT DO NOT MEET THE REQUIRED STANDARDS AT ANY LEVEL ARE ELIMINATED FROM THE PROCESS.</strong></p>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panelb6'} onChange={handleChange('panelb6')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Will I receive feedback from the jury on my application? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Detailed feedback from the jury is not provided; however, you will be notified of the outcome once the deliberation is complete.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panelb8'} onChange={handleChange('panelb8')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Can I attend the awards night even if I am not selected by the Jury? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Yes, you can attend the awards night even if you are not selected by the Jury. We encourage all participants and supporters to join us in celebrating the achievements and excellence of all our winners. You will need to purchase a ticket to attend the awards night. Please reach out to nominations@forttuna.co to secure your spot.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panelb9'} onChange={handleChange('panelb9')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Can I recommend someone else to participate? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Yes, you can indeed recommend deserving individuals for this prestigious recognition. To recommend someone to participate, please email us at support@forttuna.co
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panelb10'} onChange={handleChange('panelb10')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Are there any speaking opportunities at your event? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Yes, we do offer speaking opportunities at our event. Should you be interested, please do not hesitate to get in touch with us for further details on how to become a speaker and share your insights and expertise with our audience.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panelb11'} onChange={handleChange('panelb11')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Do attendees also have to pay to attend the event? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        This is a ticketed event, which means that attendees are required to obtain tickets for access. However, award winners will receive a complimentary invitation to attend the awards night as a recognition of their achievements.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panelb12'} onChange={handleChange('panelb12')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Do you provide translators at your event? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        We regret to inform you that we do not offer translation services during the event.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panelb13'} onChange={handleChange('panelb13')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Do you help in applying for a visa? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Yes, we do provide assistance with visa applications. We understand the importance of a seamless entry process and aim to support our international attendees in obtaining the necessary visas to participate in our event.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panelb14'} onChange={handleChange('panelb14')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Will I be featured in the media partners' magazines? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Yes, our awardees will have the opportunity to be featured in the magazines of our esteemed media partners. This provides a platform to showcase your achievements and contributions to a wider audience.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panelb15'} onChange={handleChange('panelb15')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Do you provide flight and accommodation for participants? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        No, we do not provide flight and accommodation for participants. It is the responsibility of each participant to arrange their own travel and accommodation arrangements for our events.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panelb17'} onChange={handleChange('panelb17')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Can Indians residing outside of India apply for India Edition 2025? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Yes, Indians living outside of India are eligible to apply.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default FaqSection;