import React, { useEffect, useState } from 'react';
import axios from 'axios';

const InstagramFeed = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchInstagramPosts = async () => {
      try {
        const accessToken = 'IGQWRNRnBZAdnh5N05BeDNiNVp5TjFkSkFpYllFamFIejJTVl9YWWxhTEVDWnNlY1lZAakNmX2FfOHVBLUVvTzBqRDEwVzJyWktsRVVsMnYydldlQXBhMUZATUVN2VkN0eUY5R3htMXhXaDJuZAllOQXVhX25zQzVDNVkZD';
        const response = await axios.get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,thumbnail_url,permalink&access_token=${accessToken}`);
        const videos = response.data.data.filter(post => post.media_type === 'VIDEO').slice(0, 3); // Get the latest 6 video posts
        setPosts(videos);
      } catch (error) {
        console.error('Error fetching Instagram posts:', error);
      }
    };

    fetchInstagramPosts();
  }, []);

  return (
    <div className="instagram-feed">
      {posts.map((post) => (
        <div key={post.id} className="instagram-post">
          <video controls src={post.media_url} />
        </div>
      ))}
    </div>
  );
};

export default InstagramFeed;
