
import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import BlogSingle from '../../components/BlogD36/BlogD36';
import blogs from '../../api/blogs'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';


const BlogDetails = () => {

    const { slug } = useParams()

    const BlogDetails = blogs.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'Leverage B2B Marketing Awards: Recognition and Growth';
    }, []);
    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="Winning B2B Marketing Awards is a recognition of your team & hard work, which can boost morale and encourage continued excellence." />
                <meta property="og:title" content="Leverage B2B Marketing Awards: Recognition and Growth" />
                <meta property="og:description" content="Winning B2B Marketing Awards is a recognition of your team & hard work, which can boost morale and encourage continued excellence." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
            <PageTitle pageTitle='Leverage B2B Marketing Awards: Recognition and Growth' pagesub={''} pageTitle2={'BLOG'}/>
            <BlogSingle />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default BlogDetails;
