import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import abimg from '../../images/background/pattern-3.png'
import abimg2 from '../../images/background/pattern-2.png'
import abimg3 from '../../images/background/pattern-1.png'
import abimg4 from '../../images/background/about-pattern.png'
import abimg5 from '../..//images/resource/raul-handa-video.png'
import { Roll } from "react-awesome-reveal";

const About = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [isOpen, setOpen] = useState(false)

    return (
        <section className="about-one" id="about">
            <div className="about-one_pattern-one" style={{ backgroundImage: `url(${abimg})` }}></div>
            <div className="about-one_pattern-two" style={{ backgroundImage: `url(${abimg3})` }}></div>
            <div className="about-one_pattern-three" style={{ backgroundImage: `url(${abimg2})` }}></div>
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="about-one_image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="about-one_image-inner">
                            {/* <div className="about-one_pattern-four" style={{ backgroundImage: `url(${abimg4})` }}></div> */}
                            <Roll left triggerOnce={'false'}>
                                <div className="about-one_image">
                                    <img src={abimg5} alt="" />
                                </div>
                            </Roll>
                            <button onClick={() => setOpen(true)} className="lightbox-video slider-one_play"><span className="fa fa-play"><i className="ripple"></i></span></button>
                        </div>
                    </div>
                    <div className="about-one_content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="about-one_content-inner">
                            <div className="sec-title title-anim">
                                {/* <div className="sec-title_title">About Event</div> */}
                                <h2 className="sec-title_heading">Welcome to Forttuna Awards</h2>
                            </div>
                            {/* <div className="about-one_bold-text">Grow, scrape their knees, try new things, to be vulnerable, and to have epic adventures together</div> */}
                            <div className="about-one_text">
                                <span style={{ color: '#dadada' }}>
                                    The Forttuna Global Excellence Awards is dedicated to recognizing and honoring outstanding achievements across 
                                    <Link onClick={ClickHandler} to="/business-excellence-awards"> various industries</Link>. It is an Award of Excellence. Established with a vision to award for excellence, innovation, and leadership, we take pride in acknowledging the contributions of individuals and companies that have made a significant impact in their respective fields through global recognition. <br/><br/>
                                    The Excellence Awards offer a prestigious platform for global recognition and industry validation. The Forttuna Global Excellence Awards signifies industry leaders’ contribution in health, wellness and business excellence. The <Link to='/global-awards/'>Global Awards</Link> builds new exciting partnerships, investments, and advancements.
                                </span>
                                </div>

                            <div className="row clearfix">
                                <div className="about-one_block col-lg-6 col-md-6 col-sm-12">
                                    <div className="about-one_block-inner">
                                        <span className="about-one_block-icon flaticon-map"></span>
                                        <strong>Where</strong>
                                        Dubai, UAE <br/> Mumbai, India
                                    </div>
                                </div>
                                <div className="about-one_block col-lg-6 col-md-6 col-sm-12">
                                    <div className="about-one_block-inner">
                                        <span className="about-one_block-icon flaticon-calendar-2"></span>
                                        <strong>When</strong>
                                        Dec 06 & 07, 2024 <br/>
                                        June, 2025
                                    </div>
                                </div>
                            </div>
                            <div className="about-one_button">
                                <Link to="/who-we-are/" onClick={ClickHandler} className="theme-btn btn-style-one">
                                    <span className="btn-wrap">
                                        <span className="text-one">Know More</span>
                                        <span className="text-two">Know More</span>
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="rAwho0WFUho" onClose={() => setOpen(false)} />
        </section>
    )
}

export default About;