import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Featuresbook from '../../components/Featuresbook';
import Featuresbook2 from '../../components/Featuresbook2';
import Featuresbook3 from '../../components/Featuresbook3';
import Footer2 from '../../components/footer2/Footer2';
import { Link } from 'react-router-dom';
import pIcon1 from '../../images/background/Special-Guest.jpg';

const TermPage = (props) => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Terms of Use';
    }, []);
    return (
        <Fragment>
            <Helmet>
                <meta name="description" content="Welcome to the Forttuna Global Excellence Awards Terms of Use page, where we outline the terms and conditions governing the use of our awards program. Our platform celebrates exceptional achievements across various industries, and we take pride in recognizing outstanding individuals and organizations for their contributions. Join us in celebrating excellence and review our Terms of Use to learn more about the Forttuna Global Excellence Awards." />
                <meta property="og:title" content="Forttuna Global Excellence Awards | Terms of Use" />
                <meta property="og:description" content="Welcome to the Forttuna Global Excellence Awards Terms of Use page, where we outline the terms and conditions governing the use of our awards program. Our platform celebrates exceptional achievements across various industries, and we take pride in recognizing outstanding individuals and organizations for their contributions. Join us in celebrating excellence and review our Terms of Use to learn more about the Forttuna Global Excellence Awards." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Book Ticket</div>
                            <h2 className="page-title_heading">Book Ticket</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Terms & Coditions</li>
                        </ul> */}
                    </div>
                </div>
            </section> 
            <section className="privacy-section">
                <div className="auto-container">
                    <div className="privacy-content">
                        <h3 style={{fontSize: '40px',textAlign: 'center'}}>Experience the Summit of Excellence at The <br/> Forttuna Global Excellence Awards 2024</h3>
                        <p style={{textAlign: 'center'}}>Join us for an extraordinary evening<br/> celebrating achievements, innovations, and<br/> leadership across various industries.</p>
                    </div>
                </div>
            </section>
            <Featuresbook />
            <Featuresbook2 />
            <section className="privacy-section">
                <div className="auto-container">
                    <div className="privacy-content">
                        <h3 style={{fontSize: '40px',textAlign: 'center'}}>Secure Your Ticket Now and Unlock<br/> Exclusive Benefits!</h3>
                        <p style={{textAlign: 'center'}}>Ticket Price: $399<br/>
                            – Access to the Prestigious Awards Ceremony<br/>
                            – Networking Opportunities with Industry Leaders<br/>
                            – <span>Lavish 5-star buffet complemented by an array of Exotic Cocktails</span><br/>
                            – Enjoy captivating performances by International Artists</p>
                    </div>
                </div>
            </section>
            <Featuresbook3 />
            <section className="privacy-section">
                <div className="auto-container">
                    <div className="privacy-content">
                        <h3 style={{fontSize: '40px',textAlign: 'center'}}>Limited Tickets Available - Don't Miss Out!</h3>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div class="business-one_button">
                                    <a class="theme-btn btn-style-one" href="https://buy.stripe.com/cN27tYc8P6A8anm3ci">
                                        <span class="btn-wrap"><span class="text-one">Purchase Your Tickets For Business Leaders Edition</span><span class="text-two">Purchase Your Tickets For Business Leaders Edition</span></span>
                                    </a>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div class="business-one_button">
                                    <a class="theme-btn btn-style-one" href="https://buy.stripe.com/4gwcOi3Cj6A8brq9AH">
                                        <span class="btn-wrap"><span class="text-one">Purchase Your Tickets For Health & Wellness Edition</span><span class="text-two">Purchase Your Tickets For Health & Wellness Edition</span></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    )
};
export default TermPage;
