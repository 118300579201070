
import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import BlogSingle from '../../components/BlogD37/BlogD37';
import blogs from '../../api/blogs'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';


const BlogDetails = () => {

    const { slug } = useParams()

    const BlogDetails = blogs.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'Upcoming Award Shows 2024 Where Merit Wins: No Pay-to-Play';
    }, []);
    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="Upcoming award shows 2024 where merit trumps all else are not just celebrations of talent; they are pivotal milestones." />
                <meta property="og:title" content="Upcoming Award Shows 2024 Where Merit Wins: No Pay-to-Play" />
                <meta property="og:description" content="Upcoming award shows 2024 where merit trumps all else are not just celebrations of talent; they are pivotal milestones." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="keywords" content="Upcoming award shows 2024" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet> 
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
            <PageTitle pageTitle='Upcoming Award Shows 2024 Where Merit Wins: No Pay-to-Play' pagesub={''} pageTitle2={'  '}/>
            <BlogSingle />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default BlogDetails;
