import React, { Fragment, useState , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom';
import pIcon1 from '../../images/background/The-FFTB-show.jpg';
import Navbar from '../../components/Navbar/Navbar';
import VideoArea from '../../components/VideoArea/VideoArea';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ModalVideo from 'react-modal-video';
import mImg1 from '../../images/gallery/Icons-of-Impact-Ft-Raul-handa-X-Jimmy-Mistry.webp';
import mImg2 from '../../images/gallery/Icons-of-Impact-Ft-Raul-handa-X-Kaiser-raja.webp';
import mImg3 from '../../images/gallery/Icons-of-Impact-Ft-Raul-handa-X-Shallen-Verma-F.webp';
import mImg4 from '../../images/gallery/The-Bravery-Blueprint-III-2.webp';
import mImg5 from '../../images/gallery/Icons-of-Impact-II-Ft-Raul-handa-X-Varinder-Verma.webp';
import mImg6 from '../../images/gallery/Love.-Laugh.-Live.-Ft-Raul-handa-X-Aditii-Handa.jpg';
import mImg7 from '../../images/gallery/Icons-of-Impact-IV-Ft-Raul-handa-X-shailza-Taneja.jpg';
import mImg8 from '../../images/gallery/Love.-Laugh.-Live.-2-Ft-Raul-handa-X-Aditii-Handa.jpg';
// import pimg7 from '../../images/clients/7.png'
// import pimg8 from '../../images/clients/8.png'


const HomePage = () => {
    const [isOpen, setOpen] = useState(false);
    const [isOpen2, setOpen2] = useState(false);
    const [isOpen3, setOpen3] = useState(false);
    const [isOpen4, setOpen4] = useState(false);
    const [isOpen5, setOpen5] = useState(false);
    const [isOpen6, setOpen6] = useState(false);
    const [isOpen7, setOpen7] = useState(false);
    const [isOpen8, setOpen8] = useState(false);
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | The FFTB Show';
    }, []);
    return (
        <Fragment>
            <Helmet>
                <meta name="description" content="Join us at the Forttuna Global Excellence Awards and The FFTB Show to celebrate outstanding achievements and excellence across various industries. Discover inspiring stories and recognize top performers." />
                <meta property="og:title" content="Forttuna Global Excellence Awards | The FFTB Show" />
                <meta property="og:description" content="Join us at the Forttuna Global Excellence Awards and The FFTB Show to celebrate outstanding achievements and excellence across various industries. Discover inspiring stories and recognize top performers." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">The FFTB Show</div>
                            <h2 className="page-title_heading">The FFTB Show</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>The FFTB Show</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <section>
                <div className='row' style={{margin: '80px 60px'}}>
                    <div className='col-lg-6 col-md-6 col-sm-12' >
                        <div className="auto-container video" style={{ backgroundImage: `url(${mImg1})`}}>
                            <div className="sec-title title-anim light centered" style={{marginTop: '97px'}}>
                                <button onClick={() => setOpen(true)} className="lightbox-video video-one_play">
                                    <span className="fa fa-play"><i className="ripple"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className="auto-container video" style={{ backgroundImage: `url(${mImg2})`}}>
                            <div className="sec-title title-anim light centered" style={{marginTop: '97px'}}>
                                <button onClick={() => setOpen2(true)} className="lightbox-video video-one_play">
                                    <span className="fa fa-play"><i className="ripple"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row' style={{margin: '80px 60px'}}>
                    <div className='col-lg-6 col-md-6 col-sm-12' >
                        <div className="auto-container video" style={{ backgroundImage: `url(${mImg3})`}}>
                            <div className="sec-title title-anim light centered" style={{marginTop: '97px'}}>
                                <button onClick={() => setOpen3(true)} className="lightbox-video video-one_play">
                                    <span className="fa fa-play"><i className="ripple"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className="auto-container video" style={{ backgroundImage: `url(${mImg4})`}}>
                            <div className="sec-title title-anim light centered" style={{marginTop: '97px'}}>
                                <button onClick={() => setOpen4(true)} className="lightbox-video video-one_play">
                                    <span className="fa fa-play"><i className="ripple"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row' style={{margin: '80px 60px'}}>
                    <div className='col-lg-6 col-md-6 col-sm-12' >
                        <div className="auto-container video" style={{ backgroundImage: `url(${mImg5})`}}>
                            <div className="sec-title title-anim light centered" style={{marginTop: '97px'}}>
                                <button onClick={() => setOpen5(true)} className="lightbox-video video-one_play">
                                    <span className="fa fa-play"><i className="ripple"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12' >
                        <div className="auto-container video" style={{ backgroundImage: `url(${mImg6})`}}>
                            <div className="sec-title title-anim light centered" style={{marginTop: '97px'}}>
                                <button onClick={() => setOpen6(true)} className="lightbox-video video-one_play">
                                    <span className="fa fa-play"><i className="ripple"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row' style={{margin: '80px 60px'}}>
                    <div className='col-lg-6 col-md-6 col-sm-12' >
                        <div className="auto-container video" style={{ backgroundImage: `url(${mImg7})`}}>
                            <div className="sec-title title-anim light centered" style={{marginTop: '97px'}}>
                                <button onClick={() => setOpen7(true)} className="lightbox-video video-one_play">
                                    <span className="fa fa-play"><i className="ripple"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12' >
                        <div className="auto-container video" style={{ backgroundImage: `url(${mImg8})`}}>
                            <div className="sec-title title-anim light centered" style={{marginTop: '97px'}}>
                                <button onClick={() => setOpen8(true)} className="lightbox-video video-one_play">
                                    <span className="fa fa-play"><i className="ripple"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="-SSzV0Aeb3o" onClose={() => setOpen(false)} />
                <ModalVideo channel='youtube' autoplay isOpen={isOpen2} videoId="Irh4cvGw6Zo" onClose={() => setOpen2(false)} />
                <ModalVideo channel='youtube' autoplay isOpen={isOpen3} videoId="8VTNNiHThUY" onClose={() => setOpen3(false)} />
                <ModalVideo channel='youtube' autoplay isOpen={isOpen4} videoId="Ni97Ux06XmU" onClose={() => setOpen4(false)} />
                <ModalVideo channel='youtube' autoplay isOpen={isOpen5} videoId="277U_xT5ePI" onClose={() => setOpen5(false)} />
                <ModalVideo channel='youtube' autoplay isOpen={isOpen6} videoId="KqKe0sSr2hQ" onClose={() => setOpen6(false)} />
                <ModalVideo channel='youtube' autoplay isOpen={isOpen7} videoId="xbdUAjYuOcQ" onClose={() => setOpen7(false)} />
                <ModalVideo channel='youtube' autoplay isOpen={isOpen8} videoId="xxudLcwWsVQ" onClose={() => setOpen8(false)} />
            </section>
            <VideoArea />
            <Subscribe2 />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    );
};

export default HomePage;
