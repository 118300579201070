import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Banking-Tech-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)

    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards: Banking Tech Awards';
    }, []);
    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="Be Recognized on the World Stage The Forttuna Global Excellence Banking Tech Awards" />
                <meta property="og:title" content="The Forttuna Global Excellence Awards: Banking Tech Awards" />
                <meta property="og:description" content="Be Recognized on the World Stage The Forttuna Global Excellence Banking Tech Awards" />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: Banking Tech Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="" />
                        </div>
                        <div className="upper-box d-flex justify-content-between flex-wrap">
                            <h2 style={{color: '#fff'}}>The Forttuna Global Excellence Awards: Banking Tech Awards</h2><br/>
                            {/* <div className="event-detail_author">
                                <div className="event-detail_author-image">
                                    <img src={eimg1} alt="" />
                                </div>
                                <div className="event-detail_author-name">Robert Gates, <span>Art Critic</span></div>
                                <div className="event-detail_author-location"><span>Location:</span> Hall 1, Building, <br /> Golden Street , Southafrica</div>
                                <div className="event-detail_author-timing">08.00 am  09.00 am</div>
                                <div className="event-detail_author-icon flaticon-voice"></div>
                            </div> */}
                        </div>
                        <div>
                            <p><strong>First-ever Jury Led Awards: Be Recognized on the World Stage</strong></p>
                            <p>
                                <span>
                                    The Forttuna Global Excellence Banking Tech Awards recognize and honor the groundbreaking innovations transforming the banking industry. By celebrating the fintech pioneers driving digital transformation, efficiency, and customer
                                    experience, these awards highlight the technology-driven solutions shaping the future of finance.
                                </span>
                            </p>
                            <p><strong>What are the Forttuna Global Excellence Awards?</strong></p>
                            <ul>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Recognize Banking Innovation:</strong><span> Celebrate groundbreaking achievements in banking technology.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Validation:</strong><span> Benefit from a rigorous evaluation by industry experts.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Drive Industry Advancement:</strong><span> Showcase your solution's impact on the banking sector.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Connect with Leaders:</strong><span> Network with fellow banking tech innovators and collaborate.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Gain Global Recognition:</strong><span> Position your bank as a global leader in technology.</span></li>
                            </ul>
                            <p><strong>Why Participate?</strong></p>
                            <ul>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Showcase Innovation:</strong><span> Be recognized as a leader in banking technology innovation.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Inspire and Connect:</strong><span> Network with industry peers and foster collaboration.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Elevate Your Brand:</strong><span> Increase brand visibility and attract new business opportunities.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Gain Media Attention:</strong><span> Secure significant media coverage for your achievements.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Endorsement:</strong><span> Receive validation from a panel of industry experts.</span></li>
                            </ul>
                            <p><strong>Banking Tech Awards Categories :</strong></p>
                            <p><strong>Finance and Insurance-</strong></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Angel Investor of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Business Leader of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Business Woman of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>CEO of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>CFO of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>CHRO of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>CIO of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>CMO of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>COO of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>CTO of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Emerging Healthcare Leader of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Entrepreneur of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Influencer of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Lifetime Achievement Award</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Mentor of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Recruiter of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Start-up of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Venture Capitalist of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Young Business Leader of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Young Entrepreneur of the Year</span></li>
                            </ol>
                            <p><strong>Technology-</strong></p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Angel Investor of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Business Leader of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Business Woman of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>CEO of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>CFO of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>CHRO of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>CIO of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>CMO of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>COO of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>CTO of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Emerging Healthcare Leader of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Entrepreneur of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Influencer of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Lifetime Achievement Award</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Mentor of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Recruiter of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Start-up of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Venture Capitalist of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Young Business Leader of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Young Entrepreneur of the Year</span></li>
                            </ol>
                            <p><strong>Ready to shine a light? Participate yourself or someone you know for recognition.</strong></p>
                            <p><span>Shape the future of banking technology. Be recognized as a global industry leader.</span></p>
                            <p><Link  to={`https://calendly.com/d/ck9b-2wq-pjs/participation-interview-fga?month=2024-07`}><span>Book An Appointment Now!</span></Link></p>
                            <p><span><Link to={`/corporate-and-financial-award/`}>Learn More</Link> About the Excellence Award.</span></p>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
