import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Fintech-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards: Fintech Award';
    }, []);
    return (
        <Fragment>
            <Helmet>
                <meta name="description" content="Gain global and corporate recognition for your company's cutting-edge ideas and leadership in the FinTech Award space." />
                <meta property="og:title" content="The Forttuna Global Excellence Awards: Fintech Award" />
                <meta property="og:description" content="Gain global and corporate recognition for your company's cutting-edge ideas and leadership in the FinTech Award space." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: Fintech Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="" />
                        </div>
                        <div className="upper-box d-flex justify-content-between flex-wrap">
                            <h2 style={{color: '#fff'}}>The Forttuna Global Excellence Awards: Fintech Awards</h2><br/>
                            {/* <div className="event-detail_author">
                                <div className="event-detail_author-image">
                                    <img src={eimg1} alt="" />
                                </div>
                                <div className="event-detail_author-name">Robert Gates, <span>Art Critic</span></div>
                                <div className="event-detail_author-location"><span>Location:</span> Hall 1, Building, <br /> Golden Street , Southafrica</div>
                                <div className="event-detail_author-timing">08.00 am  09.00 am</div>
                                <div className="event-detail_author-icon flaticon-voice"></div>
                            </div> */}
                        </div>
                        <div>
                            <p><strong>First-ever Jury Led Awards: Be Recognized on the World Stage</strong></p>
                            <p>Do you have a revolutionary fintech product or service that's transforming the financial landscape?</p>
                            <p>The Forttuna Global Excellence Awards could be your chance to shine! The prestigious fintech awards category recognizes outstanding achievements in financial technology, honoring innovation, design, and impactful contributions that are shaping the future of finance.</p>
                            <p><strong>What are the Forttuna Global Excellence Awards?</strong></p>
                            <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Prestigious Recognition:</strong> Forttuna Global Excellence Awards honor exceptional achievements by Fintech leaders.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Jury:</strong> Industry leaders evaluate entries to ensure merit-based selection.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Cross-Industry Impact:</strong> The program celebrates outstanding contributions across diverse fields.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Beyond Awards:</strong> Forttuna fosters connections and collaboration among like-minded businesses.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Global Reach:</strong> Gain recognition for your impact on the international stage.</li>
                            </ul>
                            <p><strong>Why Participate?</strong><strong><br /></strong><strong><br /></strong><strong>Shine a Light on Innovation:</strong> Gain global recognition for your company's cutting-edge ideas and leadership in the fintech space.</p>
                            <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Inspire and Be Inspired:</strong> Showcase the talent within your team and motivate others through your achievements&mdash;network with industry leaders at our prestigious awards ceremony, fostering valuable connections.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Elevate Your Brand:</strong> Increase your brand profile and attract new opportunities by being recognized on the global stage.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Media Spotlight:</strong> Gain significant media exposure, propelling your accomplishments into the public eye.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Recognition:</strong> A distinguished panel of international jury experts will ensure a fair and merit-based evaluation of your entry.</li>
                            </ul>
                            <p><strong>Fintech Awards Categories :</strong></p>
                            <p><strong>Finance and Insurance-</strong></p>
                            <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Healthcare Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                            </ol>
                            <p><strong>Technology-</strong></p>
                            <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Healthcare Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                            </ol>
                            <p><strong>Ready to shine a light? Participate yourself or someone you know for recognition.</strong></p>
                            <p>Join us in recognizing your accomplishments at the Forttuna Fintech Awards.</p>
                            {/* <p>Buttons- Participate Now!</p> */}
                            <div class="business-one_button">
                                <a href="https://india.forttuna.co/#form" class="theme-btn btn-style-one" contenteditable="false">
                                    <span class="btn-wrap">
                                        <span class="text-one">Participate Now!</span>
                                        <span class="text-two">Participate Now!</span>
                                    </span>
                                </a>
                            </div>
                            <p><Link to={`/awards/banking-tech-awards/`}>Learn More</Link> About the Excellence Award.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>  
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
