import React from 'react'
import bg from '../../images/background/pattern-6.png'
import bg2 from '../../images/background/pattern-10.png'
import { Link } from 'react-router-dom'

const WhyAttendS2 = (props) => {

    const Service = [
        {
            title: 'Network',
            des: 'Connect with industry leaders, professionals, and potential collaborators.',
            icon: 'flaticon-connection'
        },
        {
            title: 'Get Inspired',
            des: 'Gain insights from the success stories of global achievers.',
            icon: 'flaticon-book'
        },
        {
            title: 'Meet Innovators',
            des: 'Meet Disruptors who have launched successful ventures.',
            icon: 'flaticon-idea'
        },
    ]

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="" >
            {/* <div className="services-one_pattern-one" style={{ backgroundImage: `url(${bg})` }}></div>
            <div className="services-one_pattern-two" style={{ backgroundImage: `url(${bg2})` }}></div> */}
            <div className="auto-container">
                <div className="sec-title centered title-anim" style={{textAlign: 'center'}}>
                    <h2 className="sec-title_heading">Why Attend?</h2>
                </div>
                <div className="row clearfix">
                    {Service.map((service, sitem) => (
                        <div className="service-block_one col-lg-4 col-md-6 col-sm-12" key={sitem}>
                            <div className="service-block_one-inner">
                                <div className={`service-block_one-icon fi ${service.icon}`}></div>
                                <h4 className="service-block_one-heading"><Link onClick={ClickHandler} to="/event-details/Registration-For-Opening-Workshop">{service.title}</Link></h4>
                                <div className="service-block_one-text">{service.des}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>

    )
}

export default WhyAttendS2;