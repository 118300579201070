import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import EventsSection3 from '../../components/EventsSection3/EventsSection3';
import VideoArea from '../../components/VideoArea/VideoArea';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';


const SchedulePage = (props) => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards - Event Schedule';
    }, []);
    return (
        <Fragment>
            <Navbar />
            <Helmet>
                <meta name="description" content="The Modern Healthcare Awards stand out as an acclaimed recognition for those at the forefront of this dynamic industry." />
                <meta property="og:title" content="Forttuna Global Excellence Awards - Event Schedule" />
                <meta property="og:description" content="The Modern Healthcare Awards stand out as an acclaimed recognition for those at the forefront of this dynamic industry." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <PageTitle pageTitle={'schedule'} pagesub={'schedule'} pageTitle2={'schedule'} />
            <EventsSection3 />
            <VideoArea />
            <Subscribe2 />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    )
};
export default SchedulePage;
