import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Corporate-and-Financial-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards: Corporate and Financial Award';
    }, []);

    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="The Forttuna Global Excellence Awards are proud to celebrate your achievements in the prestigious Corporate and Financial Awards category." />
                <meta property="og:title" content="Forttuna Global Excellence Awards: Corporate and Financial Award" />
                <meta property="og:description" content="The Forttuna Global Excellence Awards are proud to celebrate your achievements in the prestigious Corporate and Financial Awards category." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>   
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: Corporate and Financial Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="" />
                        </div>
                        <div className="upper-box d-flex justify-content-between flex-wrap">
                            <h2 style={{color: '#fff'}}>The Forttuna Global Excellence Awards: Corporate and Financial Awards</h2><br/>
                            {/* <div className="event-detail_author">
                                <div className="event-detail_author-image">
                                    <img src={eimg1} alt="" />
                                </div>
                                <div className="event-detail_author-name">Robert Gates, <span>Art Critic</span></div>
                                <div className="event-detail_author-location"><span>Location:</span> Hall 1, Building, <br /> Golden Street , Southafrica</div>
                                <div className="event-detail_author-timing">08.00 am  09.00 am</div>
                                <div className="event-detail_author-icon flaticon-voice"></div>
                            </div> */}
                        </div>
                        <div>
                            <p><strong>First-ever Jury Led Awards: Be Recognized on the World Stage</strong></p>
                            <p><strong>Introductory Paragraph-</strong><span> The Forttuna Global Excellence Awards are proud to celebrate your achievements in the prestigious Corporate and Financial Awards category. This distinguished program recognizes exceptional companies and individuals who are shaping the future of business and finance. From visionary leadership to cutting-edge financial solutions, showcase your commitment to excellence and ethical practices. Be recognized for your impact on the global landscape and join a community of industry leaders driving positive change.</span></p>
                            <p><strong>What are the Forttuna Global Excellence Awards?</strong></p>
                            <p><strong>Expert Recognition Awaits:</strong></p>
                            <p><span>A distinguished panel of industry leaders oversees a rigorous merit-based selection process, ensuring only the most deserving are recognized.</span></p>
                            <p><strong>Shape the Financial Landscape:</strong></p>
                            <p><span>Showcase your commitment to excellence and ethical practices. Whether through visionary leadership, groundbreaking financial solutions, or dedication to positive social impact, demonstrate your contribution to the global financial landscape.</span></p>
                            <p><strong>Join a Community of Leaders:</strong></p>
                            <p><span>Become part of a network of like-minded individuals driving positive change in the corporate and financial world. Connect, collaborate, and inspire alongside industry champions.</span></p>
                            <p><strong>Elevate Your Brand on the Global Stage:</strong></p>
                            <p><span>Gain the recognition you deserve. Forttuna will elevate your brand and achievements, propelling you forward in the international arena.</span></p>
                            <p><strong>Why Participate&nbsp;</strong></p>
                            <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>International Acclaim:</strong><span> Gain prestigious recognition for your company's innovative ideas and leadership in the international business arena.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Inspire the Global Marketplace:</strong><span> Showcase your team's talent and inspire future generations of business leaders through your achievements. Network with industry titans at our renowned awards ceremony, forging valuable connections that can propel your business forward.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Elevate Your Brand to New Heights:</strong><span> Increase your brand profile and attract new global opportunities by being recognized on the international stage.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Become a Global Powerhouse:</strong><span> Secure significant media exposure that propels your achievements into the public eye and positions you as a leading force in international commerce.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Merit-Based Recognition:</strong><span> A distinguished panel of international business experts ensures a fair and transparent evaluation, granting you the recognition you deserve for your contributions to the global economy.</span></li>
                            </ul>
                            <p><strong>International Business Categories :</strong></p>
                            <ol style={{listStyleTtype: 'upper-alpha'}}>
                            <li><strong> Architecture, Construction &amp; Engineerin</strong>
                            <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Healthcare Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                            </ol>
                            </li>
                            <li><strong> Automobile</strong>
                            <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Healthcare Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                            </ol>
                            </li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Aviation</strong></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Finance and Insurance</strong></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Fashion and Beauty</strong></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Entertainment and Media</strong></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Education</strong></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Hospitality</strong></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Manufacturing</strong></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Marketing</strong></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Market Research</strong></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Human Resources</strong></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Real Estate</strong></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> SME&rsquo;s</strong></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Technology</strong></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Textile</strong></li>
                            </ol>
                            <p><strong>Ready to shine a light? Participate yourself or someone you know for recognition.</strong></p>
                            <p><span>Gain recognition for your contribution to international trade and connect with a network of industry leaders shaping the future of global business at the International Business Awards.</span></p>
                            <div class="business-one_button">
                                <a href="https://india.forttuna.co/#form" class="theme-btn btn-style-one" contenteditable="false">
                                    <span class="btn-wrap">
                                        <span class="text-one">Participate Now!</span>
                                        <span class="text-two">Participate Now!</span>
                                    </span>
                                </a>
                            </div>
                            <p><span><Link to={`/international-business-awards/`}>Learn More</Link> About the Excellence Award.</span></p>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
