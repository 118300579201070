import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom'
import pIcon1 from '../../images/background/Gallery.jpg';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import GallerySection3 from '../../components/GallerySection3/GallerySection3';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';


const GalleryPage = (props) => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Our Gallery';
    }, []);
    return (
        <Fragment>
            <Helmet>
                <meta name="description" content="Join us in celebrating exceptional healthcare professionals at the 2023 Forttuna Global Excellence Awards. Participate someone today for a Forttuna Global Excellence Award!" />
                <meta property="og:title" content="Forttuna Global Excellence Awards | Our Gallery" />
                <meta property="og:description" content="Join us in celebrating exceptional healthcare professionals at the 2023 Forttuna Global Excellence Awards. Participate someone today for a Forttuna Global Excellence Award!" />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Gallery</div>
                            <h2 className="page-title_heading">Gallery</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Gallery</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <GallerySection3 />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default GalleryPage;
