import timg1 from '../images/resource/Dr.-Puneet-Wadhwa.webp'
import timg2 from '../images/resource/Ms.-Celestia-Faith-Chong.webp'
import timg3 from '../images/resource/Dr.-Neeta-Nagra.webp'
import timg4 from '../images/resource/Dr.-Sujit-Kumar-Bhattacharya.webp'
import timg5 from '../images/resource/Dr.-Michael-Conner.webp'
import timg6 from '../images/resource/Dr.-Basma-Ghandourah.jpg'
import timg7 from '../images/resource/Ms.-Minu-Basi.webp'
import timg8 from '../images/resource/Dr.-Fahad-Attar.webp'
import timg9 from '../images/resource/Mr.-Adhip-Iyer.webp'
import timg10 from '../images/resource/Dr.-Rickson-Pereira.webp'
import timg11 from '../images/resource/Dr.-Krishnan-Nair.webp'
import timg12 from '../images/resource/Dr.-Mayank-Vats.webp'
import timg13 from '../images/resource/Mr.-Hamdan-Almurshidi.webp'
import timg14 from '../images/resource/Dr.-RK-Marya.webp'
import timg15 from '../images/resource/Dr.-Kirti-Mohan-Marya.webp'
import timg16 from '../images/resource/Mr.-Jinal-Jain-(2).webp'
import timg17 from '../images/resource/Dr.-Shwetha-Kakkerla.webp'
import timg18 from '../images/resource/Dr.-Shallen-Verma.webp'
import timg19 from '../images/resource/Dr.-Monika-Kaushal.webp'
import timg20 from '../images/resource/Dr.-Mohammed-Hashmat-Faheem.webp'
import timg21 from '../images/resource/Mrs.-Nada-Munir-Al-Balushi.webp'
import timg22 from '../images/resource/Dr.-Ryan-Harvey.webp'
import timg23 from '../images/resource/Dr.-Humeira-Badsha.webp'
import timg24 from '../images/resource/Prof.-Dr.-Pratit-Samdani.webp'
import timg25 from '../images/resource/Mr.-Alec-Junaid-Ali.webp'
import timg26 from '../images/resource/Dr.-Jenny-Bouraima.webp'
import timg27 from '../images/resource/Mrs.-Rohini-Rajeev-(2).webp'
import timg28 from '../images/resource/Dr.-Sylvia-Paulig.webp'
import timg29 from '../images/resource/Mr.-Badal-Shah.webp'
import timg30 from '../images/resource/Solfrid-Sagstad-&-Stian-Lavik.webp'
import timg31 from '../images/resource/Mrs.-Geraldine-Lo-Monaco.webp'
// import timg32 from '../images/resource/speaker-1.jpg'
// import timg33 from '../images/resource/speaker-1.jpg'
// import timg34 from '../images/resource/speaker-1.jpg'
// import timg35 from '../images/resource/speaker-1.jpg'
// import timg36 from '../images/resource/speaker-1.jpg'
// import timg37 from '../images/resource/speaker-1.jpg'
// import timg38 from '../images/resource/speaker-1.jpg'
// import timg8 from '../images/resource/speaker-8.jpg'

import tsimg1 from '../images/resource/Dr.-Puneet-Wadhwa.webp'
import tsimg2 from '../images/resource/Ms.-Celestia-Faith-Chong.webp'
import tsimg3 from '../images/resource/Dr.-Neeta-Nagra.webp'
import tsimg4 from '../images/resource/Dr.-Sujit-Kumar-Bhattacharya.webp'
import tsimg5 from '../images/resource/Dr.-Michael-Conner.webp'
import tsimg6 from '../images/resource/Dr.-Basma-Ghandourah.jpg'
import tsimg7 from '../images/resource/Ms.-Minu-Basi.webp'
import tsimg8 from '../images/resource/Dr.-Fahad-Attar.webp'
import tsimg9 from '../images/resource/Mr.-Adhip-Iyer.webp'
import tsimg10 from '../images/resource/Dr.-Rickson-Pereira.webp'
import tsimg11 from '../images/resource/Dr.-Krishnan-Nair.webp'
import tsimg12 from '../images/resource/Dr.-Mayank-Vats.webp'
import tsimg13 from '../images/resource/Mr.-Hamdan-Almurshidi.webp'
import tsimg14 from '../images/resource/Dr.-RK-Marya.webp'
import tsimg15 from '../images/resource/Dr.-Kirti-Mohan-Marya.webp'
import tsimg16 from '../images/resource/Mr.-Jinal-Jain-(2).webp'
import tsimg17 from '../images/resource/Dr.-Shwetha-Kakkerla.webp'
import tsimg18 from '../images/resource/Dr.-Shallen-Verma.webp'
import tsimg19 from '../images/resource/Dr.-Monika-Kaushal.webp'
import tsimg20 from '../images/resource/Dr.-Mohammed-Hashmat-Faheem.webp'
import tsimg21 from '../images/resource/Mrs.-Nada-Munir-Al-Balushi.webp'
import tsimg22 from '../images/resource/Dr.-Ryan-Harvey.webp'
import tsimg23 from '../images/resource/Dr.-Humeira-Badsha.webp'
import tsimg24 from '../images/resource/Prof.-Dr.-Pratit-Samdani.webp'
import tsimg25 from '../images/resource/Mr.-Alec-Junaid-Ali.webp'
import tsimg26 from '../images/resource/Dr.-Jenny-Bouraima.webp'
import tsimg27 from '../images/resource/Mrs.-Rohini-Rajeev-(2).webp'
import tsimg28 from '../images/resource/Dr.-Sylvia-Paulig.webp'
import tsimg29 from '../images/resource/Mr.-Badal-Shah.webp'
import tsimg30 from '../images/resource/Solfrid-Sagstad-&-Stian-Lavik.webp'
import tsimg31 from '../images/resource/Mrs.-Geraldine-Lo-Monaco.webp'
// import tsimg32 from '../images/resource/speaker-1.jpg'
// import tsimg33 from '../images/resource/speaker-1.jpg'
// import tsimg34 from '../images/resource/speaker-1.jpg'
// import tsimg35 from '../images/resource/speaker-1.jpg'
// import tsimg36 from '../images/resource/speaker-1.jpg'
// import tsimg37 from '../images/resource/speaker-1.jpg'
// import tsimg38 from '../images/resource/speaker-1.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Dr. Puneet Wadhwa',
      slug: 'Dr. Puneet Wadhwa',
      title: "Pediatrician Of The Year UAE",
   },
   {
      Id: '2',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Celestia Faith Chong',
      slug: 'Celestia Faith Chong',
      title: 'Healthcare Marketer Of The Year Asia',
   },
   {
      Id: '3',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Dr. Neeta Nagra',
      slug: 'Dr. Neeta Nagra',
      title: 'Young Healthcare Entrepreneur Of The Year Global',
   },
   {
      Id: '4',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr. Sujit Kumar Bhattacharya',
      slug: 'Dr. Sujit Kumar Bhattacharya',
      title: 'Rheumatologist Of The Year India',
   },
   {
      Id: '5',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Dr. Michael Conner',
      slug: 'Dr. Michael Conner',
      title: 'Healthcare And Wellness Educator Of The Year Global',
   },
   {
      Id: '6',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Dr. Basma Ghandourah',
      slug: 'Dr. Basma Ghandourah',
      title: 'Restorative Dentist of the Year Middle East',
   },
   {
      Id: '7',
      tImg: timg7,
      tsImg: tsimg7,
      name: 'Minu Basi',
      slug: 'Minu Basi',
      title: 'Wellness Visionary of the Year Global',
   },
   {
      Id: '8',
      tImg: timg8,
      tsImg: tsimg8,
      name: 'Dr. Fahad Attar',
      slug: 'Dr. Fahad Attar',
      title: 'Orthopedic Surgeon of the Year Global',
   },
   {
      Id: '9',
      tImg: timg9,
      tsImg: tsimg9,
      name: 'Adhip Iyer (Zennara)',
      slug: 'Adhip Iyer (Zennara)',
      title: 'Aesthetic & Wellness Center Of The Year India',
   },
   {
      Id: '10',
      tImg: timg10,
      tsImg: tsimg10,
      name: 'Dr. Rickson Pereira',
      slug: 'Dr. Rickson Pereira',
      title: 'Dermatologist of the Year India',
   },
   {
      Id: '11',
      tImg: timg11,
      tsImg: tsimg11,
      name: 'Dr. Krishnan Nair',
      slug: 'Dr. Krishnan Nair',
      title: 'Healthcare Marketer Of The Year UAE',
   },
   {
      Id: '12',
      tImg: timg12,
      tsImg: tsimg12,
      name: 'Dr. Mayank Vats',
      slug: 'Dr. Mayank Vats',
      title: 'Pulmonologist And Sleep Physician Of The Year UAE',
   },
   {
      Id: '13',
      tImg: timg13,
      tsImg: tsimg13,
      name: 'Hamdan Al Murshidi (Al Murshidi Medical Tourism)',
      slug: 'Hamdan Al Murshidi (Al Murshidi Medical Tourism)',
      title: 'Medical Travel Agency Of The Year UAE',
   },
   {
      Id: '14',
      tImg: timg14,
      tsImg: tsimg14,
      name: 'Dr. R K Marya',
      slug: 'Dr. R K Marya',
      title: 'Lifetime Achievement Award India',
   },
   {
      Id: '15',
      tImg: timg15,
      tsImg: tsimg15,
      name: 'Dr. Kirti Mohan Marya',
      slug: 'Dr. Kirti Mohan Marya',
      title: 'CMO Of The Year UAE',
   },
   {
      Id: '16',
      tImg: timg16,
      tsImg: tsimg16,
      name: 'Jinal Jain (Kunal Pharma)',
      slug: 'Jinal Jain (Kunal Pharma)',
      title: 'Pharma Distributor Of The Year India',
   },
   {
      Id: '17',
      tImg: timg17,
      tsImg: tsimg17,
      name: 'Dr. Shwetha Kakkerla',
      slug: 'Dr. Shwetha Kakkerla',
      title: 'Oral Implantologist & Cosmetologist Of The Year India',
   },
   {
      Id: '18',
      tImg: timg18,
      tsImg: tsimg18,
      name: 'Dr. Shallen Verma',
      slug: 'Dr. Shallen Verma',
      title: 'Dentist of the Year Middle East',
   },
   {
      Id: '19',
      tImg: timg19,
      tsImg: tsimg19,
      name: 'Dr. Monika Kaushal',
      slug: 'Dr. Monika Kaushal',
      title: 'Neonatologist Of The Year Asia',
   },
   {
      Id: '20',
      tImg: timg20,
      tsImg: tsimg20,
      name: 'Dr. Mohammed Hashmat Faheem',
      slug: 'Dr. Mohammed Hashmat Faheem',
      title: 'Emergency Medicine Specialist Of The Year UAE',
   },
   {
      Id: '21',
      tImg: timg21,
      tsImg: tsimg21,
      name: 'Nada Munir Al Balushi',
      slug: 'Nada Munir Al Balushi',
      title: 'CEO Of The Year Middle East',
   },
   {
      Id: '22',
      tImg: timg22,
      tsImg: tsimg22,
      name: 'Dr. Ryan Harvey',
      slug: 'Dr. Ryan Harvey',
      title: 'Skin Cancer Specialist Of The Year Global',
   },
   {
      Id: '23',
      tImg: timg23,
      tsImg: tsimg23,
      name: 'Dr. Humeira Badsha',
      slug: 'Dr. Humeira Badsha',
      title: 'Physician Of The Year Middle East',
   },
   {
      Id: '24',
      tImg: timg24,
      tsImg: tsimg24,
      name: 'Dr. Pratit Samdani',
      slug: 'Dr. Pratit Samdani',
      title: 'Physician Of The Year India',
   },
   {
      Id: '25',
      tImg: timg25,
      tsImg: tsimg25,
      name: 'Alec Junaid Ali',
      slug: 'Alec Junaid Ali',
      title: 'Healthcare Marketer Of The Year Global',
   },
   {
      Id: '26',
      tImg: timg26,
      tsImg: tsimg26,
      name: 'Dr. Jenny Bouraima (Urban Care)',
      slug: 'Dr. Jenny Bouraima (Urban Care)',
      title: 'Healthcare Start-up of the Year Africa',
   },
   {
      Id: '27',
      tImg: timg27,
      tsImg: tsimg27,
      name: 'Rohini Rajeev (The Able Mind)',
      slug: 'Rohini Rajeev (The Able Mind)',
      title: 'Healthcare Start-up of the Year India',
   },
   {
      Id: '28',
      tImg: timg28,
      tsImg: tsimg28,
      name: 'Dr. Sylvia Paulig',
      slug: 'Dr. Sylvia Paulig',
      title: 'Ophthalmic Surgeon Of The Year Global',
   },
   {
      Id: '29',
      tImg: timg29,
      tsImg: tsimg29,
      name: 'Badal Shah',
      slug: 'Badal Shah',
      title: 'Healthcare Entrepreneur Of The Year Global',
   },
   {
      Id: '30',
      tImg: timg30,
      tsImg: tsimg30,
      name: 'Solfrid Sagtstad and Stian Lavik',
      slug: 'Solfrid Sagtstad and Stian Lavik',
      title: 'Entrepreneurial Team Of The Year Europe',
   },
   {
      Id: '31',
      tImg: timg31,
      tsImg: tsimg31,
      name: 'Geraldine Lo Monaco',
      slug: 'Geraldine Lo Monaco',
      title: 'Energy Medicine Practitioner Of The Year Global',
   },
   // {
   //    Id: '8',
   //    tImg: timg8,
   //    tsImg: tsimg8,
   //    name: 'Harnold Min',
   //    slug: 'Harnold-Min',
   //    title: 'Starbucks CEO',
   // },

]

export default Teams;