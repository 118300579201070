import React from 'react'
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import pImg1 from '../../images/gallery/1.webp'
import pImg2 from '../../images/gallery/2.webp'
import pImg3 from '../../images/gallery/3.webp'
import pImg4 from '../../images/gallery/4.webp'
import pImg5 from '../../images/gallery/5.webp'
import pImg6 from '../../images/gallery/6.webp'
import pImg7 from '../../images/gallery/7.webp'
import pImg8 from '../../images/gallery/8.webp'

const images = [
    pImg1,
    pImg2,
    pImg3,
    pImg4,
    pImg5,
    pImg6,
    pImg7,
    pImg8,
]

var settings = {
    dots: false,
    arrows: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const GallerySectionS2 = (props) => {

    const [open, setOpen] = React.useState(false);


    return (
        <section className="gallery-two">
            <div className="outer-container">
                <div className="gallery-carousel">
                    <Slider {...settings}>
                        {images.map((item, pitem) => (
                            <div className="gallery-one_block" key={pitem}>
                                <div className="gallery-one_block-inner">
                                    <div className="gallery-one_block-image" onClick={() => setOpen(true)}>
                                        <img src={item} alt="" />
                                        <div className="gallery-one_block-overlay">
                                            <span className="gallery-one_block-icon flaticon-plus-symbol"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>

                </div>
            </div>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={[
                    { src: pImg1 },
                    { src: pImg2 },
                    { src: pImg3 },
                    { src: pImg4 },
                    { src: pImg5 },
                    { src: pImg6 },
                ]}
                plugins={[Zoom]}
            />
        </section>

    )
}

export default GallerySectionS2;