import React from 'react'
import blogs from '../../api/blogs'
import { Link } from 'react-router-dom'

const BlogSection3 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="news-three" id='blog'>
            <div className="auto-container">
                <div className="sec-title">
                    <div className="d-flex justify-content-between align-items-end flex-wrap">
                        <div className="title-box title-anim">
                            <div className="sec-title_title">Blog % Articles</div>
                            <h2 className="sec-title_heading">News & Articles</h2>
                        </div>
                        <div className="button-box title-anim">
                            <Link onClick={ClickHandler} to="/blog" className="theme-btn btn-style-one">
                                <span className="btn-wrap">
                                    <span className="text-one">View More News</span>
                                    <span className="text-two">View More News</span>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    {blogs.slice(3, 6).map((blog, Bitem) => (
                        <div className="news-block_three col-lg-4 col-md-6 col-sm-12" key={Bitem}>
                            <div className="news-block_three-inner">
                                <div className="news-block_three-post">
                                    <div className="news-block_three-post-inner">
                                        <div className="news-block_three-post-image">
                                            <img src={blog.AuthorImg} alt="" />
                                        </div>
                                        By: <span>{blog.author}</span>
                                    </div>
                                </div>
                                <div className="news-block_three-image">
                                    <Link onClick={ClickHandler} to={`/blog-single/${blog.slug}`}><img src={blog.screens} alt="" /></Link>
                                    <img src={blog.screens} alt="" />
                                </div>
                                <div className="news-block_three-content">
                                    <ul className="news-block_three-meta">
                                        <li><span className="icon fa fa-calendar"></span>by {blog.author}</li>
                                        <li><span className="icon fa fa-comments"></span>({blog.comment}) Comments</li>
                                    </ul>
                                    <h5 className="news-block_three-heading"><Link onClick={ClickHandler} to={`/blog-single/${blog.slug}`}>{blog.title}</Link></h5>
                                    <Link onClick={ClickHandler} to={`/blog-single/${blog.slug}`} className="news-block_three-more" >Read More</Link>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>
    )
}

export default BlogSection3;