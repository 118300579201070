import React from "react";
import { Link } from 'react-router-dom'
import pimg1 from '../../images/clients/Forbes-India-Logo.png'
import pimg2 from '../../images/clients/khaleej-times.png'
import pimg3 from '../../images/clients/big-news-network.png'
import pimg4 from '../../images/clients/ABP-News.png'
import pimg5 from '../../images/clients/the-print.png'
import pimg6 from '../../images/clients/Hindustan-Times.png'
// import pimg7 from '../../images/clients/7.png'
// import pimg8 from '../../images/clients/8.png'

const partners = [
    {
        pImg: pimg1,
    },
    {
        pImg: pimg2,
    },
    {
        pImg: pimg3,
    },
    {
        pImg: pimg4,
    },
    {
        pImg: pimg5,
    },
    {
        pImg: pimg6,
    },
]


const PartnerSection = (props) => {

    return (

        <section className="clients-one">
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <h2 className="sec-title_heading">Media Partners</h2>
                    {/* <div className="sec-title_title mt-10">Appreciate Our Kind Partners</div> */}
                </div>
                <div className="row clearfix">
                    {partners.map((partner, pitem) => (
                        <div className="clients-one_column col-lg-2 col-md-3 col-sm-4" key={pitem}>
                            <Link to="/"><img src={partner.pImg} alt="" /></Link>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PartnerSection;