import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Nurse-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards: Banking Tech Awards';
    }, []);
    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="Be Recognized on the World Stage The Forttuna Global Excellence Banking Tech Awards" />
                <meta property="og:title" content="The Forttuna Global Excellence Awards: Banking Tech Awards" />
                <meta property="og:description" content="Be Recognized on the World Stage The Forttuna Global Excellence Banking Tech Awards" />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: Nurse Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="" />
                        </div>
                        <div className="upper-box d-flex justify-content-between flex-wrap">
                            <h2 style={{color: '#fff'}}>The Forttuna Global Excellence Awards: Nurse Awards</h2><br/>
                            {/* <div className="event-detail_author">
                                <div className="event-detail_author-image">
                                    <img src={eimg1} alt="" />
                                </div>
                                <div className="event-detail_author-name">Robert Gates, <span>Art Critic</span></div>
                                <div className="event-detail_author-location"><span>Location:</span> Hall 1, Building, <br /> Golden Street , Southafrica</div>
                                <div className="event-detail_author-timing">08.00 am  09.00 am</div>
                                <div className="event-detail_author-icon flaticon-voice"></div>
                            </div> */}
                        </div>
                        <div>
                            <p><strong>First-ever Jury Led Awards: Be Recognized on the World Stage</strong></p>
                            <p>
                                <span>
                                    The Forttuna Global Excellence Nurse Awards honor the unsung heroes who dedicate their lives to caring for others. By recognizing exceptional nurses who demonstrate compassion, expertise, and innovation, these awards celebrate the
                                    invaluable contributions of the nursing profession to global healthcare.
                                </span>
                            </p>
                            <p><strong>What are the Forttuna Global Excellence Awards?</strong><span>.</span></p>
                            <ul>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Celebrate Nursing Excellence:</strong><span> Honor exceptional nurses who exemplify compassion and expertise.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Recognition:</strong><span> Receive prestigious recognition from leading healthcare professionals.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Impactful Care:</strong><span> Acknowledge nurses making a significant difference in patient lives.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Inspire and Connect:</strong><span> Network with fellow nursing professionals and share best practices.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Global Leadership:</strong><span> Showcase your contributions to the global nursing community.</span></li>
                            </ul>
                            <p><strong>Why Participate?</strong></p>
                            <ul>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Celebrate Nursing Excellence:</strong><span> Honor the dedication and compassion of exceptional nurses.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Recognition:</strong><span> Receive prestigious recognition from healthcare leaders.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Inspire Future Nurses:</strong><span> Be a role model for the next generation of healthcare professionals.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Connect with Peers:</strong><span> Network with fellow nurses and build lasting relationships.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Gain Global Acclaim:</strong><span> Showcase your nursing expertise on a global stage.</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Validation:</strong><span> Receive expert recognition for your outstanding contributions.</span></li>
                            </ul>
                            <p>
                                <strong>Nurse Award Categories :</strong>
                                <span> (All Categories for this award will contain subcategories too- like CMO of the year, CTO of the year as mentioned on the website. As shown in the first two categories )</span>
                            </p>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Ayush, Spa, Wellness and Yoga Category</strong></li>
                            </ol>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Ayurveda Centre Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Medical Spa/Medical Resort Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Yoga Centre Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Wellness Centre/ Retreat Of the Year</span></li>
                            </ol>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Company and Brands</strong></li>
                            </ol>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Health Insurance Company Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Long-Tem Care Provider Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Pharma Company Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Pharmacy Retail Chain Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Emerging Healthcare Company Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Medical Equipment Company Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Healthcare Start-up of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Public Healthcare Initiative of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Innovative Medical Product of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Marketing Company Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Insurance and Finance Company Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Technology Company Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Education Company Of the Year</span></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><span>Veterinary Company Of the Year</span></li>
                            </ol>
                            <ol>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> CXO&rsquo;s Health &amp; Wellness</strong></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Hospital &amp; Clinic Category</strong></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Individual Category</strong></li>
                                <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Medical College/Institute Category</strong></li>
                            </ol>
                            <p><strong>Headline-4: Ready to shine a light? Participate yourself or someone you know for recognition.</strong></p>
                            <p>
                                <span>
                                    Let's celebrate the extraordinary contributions of nurses worldwide at the Forttuna Global Excellence Nurse Awards. Join us in honoring the compassionate, skilled, and innovative professionals who dedicate their lives to patient
                                    care.
                                </span>
                            </p>
                            <p><a href="https://calendly.com/d/ck9b-2wq-pjs/participation-interview-fga?month=2024-07"><span>Book An Appointment Now!</span></a></p>
                            <p><span><Link to={`/fintech-award/`}>Learn More</Link> About the Excellence Award.</span></p>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
