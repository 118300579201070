import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom'
import pIcon1 from '../../images/background/Business-2024.jpg';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Jury2 from '../../components/Jury2/Team';
import Features2 from '../../components/FeaturesB';
import FeaturesH from '../../components/FeaturesH';
import Business from '../../components/Business/Business';
import VideoArea from '../../components/VideoArea/VideoArea';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const AboutPage = (props) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Global Edition | Business Leaders Edition';
    }, []); // The empty array ensures this runs only once when the component moun
    return (
        <Fragment>
            <Helmet>
                <meta name="description" content="Forttuna Global Excellence Awards | Global Edition | Business Leaders Edition" />
                <meta property="og:title" content="Forttuna Global Excellence Awards | Global Edition | Business Leaders Edition" />
                <meta property="og:description" content="In summary, the Forttuna Global Excellence Awards for Business Leaders stands as a distinguished award platform." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Business Leaders Edition</div>
                            <h2 className="page-title_heading">Business Leaders Edition</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Business</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <Business />
            <Features2 />
            <FeaturesH />
            <section  style={{marginTop: '120px'}}>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div class="sec-title centered title-anim">
                            <h2 class="sec-title_heading">Business Leaders Categories</h2>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <ul className="accordion-box style-two">
                                <li>
                                    <Accordion expanded={expanded === 'panelb24'} onChange={handleChange('panelb24')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        Architecture/Construction/Engineering 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Business Leader of the Year</li>
                                            <li>Business Woman of the Year</li>
                                            <li>Chief Eco-System Officer of the Year</li>
                                            <li>Construction Technology Leader of the Year</li>
                                            <li>Emerging Healthcare Leader of the Year</li>
                                            <li>Entrepreneur of the Year</li>
                                            <li>Influencer of the Year</li>
                                            <li>Interior Designer of the Year</li>
                                            <li>Legal Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                                            <li>Mentor of the Year</li>
                                            <li>Most Innovative Company of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panelb25'} onChange={handleChange('panelb25')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        Automobile 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Business Leader of the Year</li>
                                            <li>Entrepreneur of the Year</li>
                                            <li>Influencer of the Year</li>
                                            <li>Lifetime Achievement Award</li>
                                            <li>Sales Leader of the Year</li>
                                            <li>Strategist of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panelb26'} onChange={handleChange('panelb26')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        Aviation 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Aviation Leader of the Year</li>
                                            <li>Business Leader of the Year</li>
                                            <li>Procurement Leader of the Year</li>
                                            <li>Young Business Leader of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panelb27'} onChange={handleChange('panelb27')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        Education 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Business Leader of the Year</li>
                                            <li>Business Woman of the Year</li>
                                            <li>CEO of the Year</li>
                                            <li>CFO of the Year</li>
                                            <li>Chief Learning Officer of the Year</li>
                                            <li>CIO of the Year</li>
                                            <li>Co-Founder of the Year</li>
                                            <li>Compliance Training Company of the Year (edtech)</li>
                                            <li>COO of the Year</li>
                                            <li>CTO of the Year</li>
                                            <li>Data Privacy Leader of the Year</li>
                                            <li>Deputy Vice-Chancellor of the Year</li>
                                            <li>Director of Music of the Year</li>
                                            <li>Director of the Year</li>
                                            <li>Education Company of the Year</li>
                                            <li>Education Leader of the Year</li>
                                            <li>Educator of the Year</li>
                                            <li>Emerging Author of the Year</li>
                                            <li>Emerging Entrepreneur in Higher Education of the Year</li>
                                            <li>Enrollment Leader of the Year</li>
                                            <li>Entrepreneur of the Year</li>
                                            <li>Female Publisher of the Year</li>
                                            <li>Human-Centered Design Leader of the Year</li>
                                            <li>Influencer of the Year</li>
                                            <li>Innovative Leader of the Year</li>
                                            <li>Innovative Leadership Development Program of the Year</li>
                                            <li>Innovator Facilitator of the Year</li>
                                            <li>Learning &amp; Development Leader of the Year</li>
                                            <li>Legal Advocacy Leader of the Year</li>
                                            <li>Lifetime Achievement Award</li>
                                            <li>Lifetime Achievement Award In Leadership Development</li>
                                            <li>Lifetime Achievement in Higher Education Management</li>
                                            <li>Mentor of the Year</li>
                                            <li>Most Influential Young Educator of the Year</li>
                                            <li>Most Innovative Business Leader of the Year</li>
                                            <li>Most Innovative Leader of the&nbsp;Year</li>
                                            <li>Most Inspiring Business Leader of the Year</li>
                                            <li>Most Inspiring Leader of the&nbsp;Year</li>
                                            <li>Non-Profit of the Year</li>
                                            <li>Personal Leadership Executive Coach of the Year</li>
                                            <li>Quality Leader of the Year</li>
                                            <li>Social Innovation Leader of the Year</li>
                                            <li>Start-up of the Year</li>
                                            <li>Strategic &amp; Visionary Leader of the Year in Higher Education</li>
                                            <li>Strategic Leader of the Year</li>
                                            <li>Strategist of the Year</li>
                                            <li>Visionary Leader of the Year</li>
                                            <li>Visionary of the Year</li>
                                            <li>Well-being &amp; Human Flourishing Leader of the Year</li>
                                            <li>Women Leader of the Year in Social Impact</li>
                                            <li>Young Business Leader of the Year</li>
                                            <li>Young Entrepreneur of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panelb28'} onChange={handleChange('panelb28')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        Entertainment and Media 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Animation and Visual Effects Community Leader of the Year</li>
                                            <li>Business Journalist of the Year</li>
                                            <li>Business Leader of the Year</li>
                                            <li>Business Woman of the Year</li>
                                            <li>Content Leader of the Year</li>
                                            <li>Creative Director of the Year</li>
                                            <li>CTO of the Year</li>
                                            <li>Emerging Healthcare Leader of the Year</li>
                                            <li>Entrepreneur of the Year</li>
                                            <li>Film and Television Production Leader of the Year</li>
                                            <li>Mentor of the Year</li>
                                            <li>Presenter of the Year</li>
                                            <li>Start-up of the Year</li>
                                            <li>Travel Influencer of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panelb29'} onChange={handleChange('panelb29')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        Fashion and Beauty 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Board Member of the Year</li>
                                            <li>Brand Leader of the Year</li>
                                            <li>Business Leader of the Year</li>
                                            <li>Business Woman of the Year</li>
                                            <li>CEO of the Year</li>
                                            <li>CMO of the Year</li>
                                            <li>COO of the Year</li>
                                            <li>Cultural Heritage Fashion Leader of the Year</li>
                                            <li>CX Leader of the Year</li>
                                            <li>Digital Innovation Leader of the Year</li>
                                            <li>Director of the Year</li>
                                            <li>Emerging Healthcare Leader of the Year</li>
                                            <li>Entrepreneur of the Year</li>
                                            <li>Fashion Brand of the Year</li>
                                            <li>Founder of the Year</li>
                                            <li>Fragrance Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                                            <li>Haircare Company of the Year</li>
                                            <li>Social Impact Leader of the Year</li>
                                            <li>Start-up of the Year</li>
                                            <li>Strategic Business Development Leader of the Year</li>
                                            <li>Visionary Leader of the Year</li>
                                            <li>Young Business Leader of the Year</li>
                                            <li>Young Entrepreneur of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panelb30'} onChange={handleChange('panelb30')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        Finance and Insurance 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Banking Pofessional of the Year</li>
                                            <li>Business Leader of the Year</li>
                                            <li>Business Woman of the Year</li>
                                            <li>Cash Management Leader of the Year</li>
                                            <li>CEO of the Year</li>
                                            <li>CFO of the Year</li>
                                            <li>Compliance Officer of the Year</li>
                                            <li>CTO of the Year</li>
                                            <li>Entrepreneur of the Year</li>
                                            <li>Finance Company of the Year</li>
                                            <li>Finance Leader of the Year</li>
                                            <li>FinTech Leader of the Year</li>
                                            <li>Impact Investor of the Year</li>
                                            <li>Investment Leader of the Year</li>
                                            <li>Regional Director of the Year</li>
                                            <li>Venture Capitalist of the Year</li>
                                            <li>Visionary of the Year</li>
                                            <li>Wealth Management Leader of the Year</li>
                                            <li>Young Business Leader of the Year</li>
                                            <li>Young Entrepreneur of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panelb31a'} onChange={handleChange('panelb31a')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        Governance 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Influencer of the Year</li>
                                            <li>Leader of the Year - Government Services</li>
                                            <li>Mentor of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panelb31'} onChange={handleChange('panelb31')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        Hospitality 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Business Leader of the Year</li>
                                            <li>Business Woman of the Year</li>
                                            <li>CEO of the Year</li>
                                            <li>Entrepreneur of the Year</li>
                                            <li>Facility Management Leader of the Year</li>
                                            <li>Influencer of the Year</li>
                                            <li>Start-up of the Year</li>
                                            <li>Travel Influencer of the Year</li>
                                            <li>Travel Professional of the Year</li>
                                            <li>Young Business Leader of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panelb32'} onChange={handleChange('panelb32')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        Human Resources 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Business Leader of the Year</li>
                                            <li>Business Leadership Educator of the Year</li>
                                            <li>Business Woman of the Year</li>
                                            <li>CEO of the Year</li>
                                            <li>CHRO of the Year</li>
                                            <li>Emerging Healthcare Leader of the Year</li>
                                            <li>Entrepreneur of the Year</li>
                                            <li>HR Leader &amp; Woman Entreprenuer of the Year</li>
                                            <li>HR Leader of the Year</li>
                                            <li>HR Outsourcing Leader of the Year</li>
                                            <li>HR Outsourcing Woman Leader of the Year</li>
                                            <li>Infleuncial Talent Leader of the Year</li>
                                            <li>Influencer of the Year</li>
                                            <li>Mentor of the Year</li>
                                            <li>Recruiter of the Year</li>
                                            <li>Start-up of the Year</li>
                                            <li>Talent Acquisition Leader of the Year</li>
                                            <li>Young Entrepreneur of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panelb34'} onChange={handleChange('panelb34')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        Law 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Business Leader of the Year</li>
                                            <li>Corporate Lawyer of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panelb33'} onChange={handleChange('panelb33')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        Manufacturing 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Business Leader of the Year</li>
                                            <li>Business Woman of the Year</li>
                                            <li>CEO of the Year</li>
                                            <li>Entrepreneur of the Year</li>
                                            <li>Lifetime Achievement Award</li>
                                            <li>Young Business Leader of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panelb36'} onChange={handleChange('panelb36')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        Market Research 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Business Woman of the Year</li>
                                            <li>CEO of the Year</li>
                                            <li>Director of the Year</li>
                                            <li>Emerging Healthcare Leader of the Year</li>
                                            <li>Innovation Leader of the Year</li>
                                            <li>Market Research Company of the Year</li>
                                            <li>Market Research Expert of the Year</li>
                                            <li>Mentor of the Year</li>
                                            <li>Research Services Organisation of the Year</li>
                                            <li>Researcher of the Year</li>
                                            <li>Supply Chain Professional of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panelb37'} onChange={handleChange('panelb37')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        Marketing 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Advertising Leader of the Year</li>
                                            <li>Brand Strategist of the Year</li>
                                            <li>Brand Strategy Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                                            <li>Business Coach of the Year</li>
                                            <li>Business Leader of the Year</li>
                                            <li>Business Woman of the Year</li>
                                            <li>CEO of the Year</li>
                                            <li>Chief Commercial Officer of the Year</li>
                                            <li>CMO of the Year</li>
                                            <li>Content Marketer of the Year</li>
                                            <li>COO of the Year</li>
                                            <li>Creative Director of the Year</li>
                                            <li>Data Analytics Leader of the Year</li>
                                            <li>Digital Marketing Leader of the Year</li>
                                            <li>Director of the Year</li>
                                            <li>Emerging Healthcare Leader of the Year</li>
                                            <li>Emerging Business Woman of the Year</li>
                                            <li>Entrepreneur of the Year</li>
                                            <li>Female Entrepreneur of the Year</li>
                                            <li>Healthcare Marketing Leader of the Year</li>
                                            <li>Humanitarian Philanthropy Leader of the Year</li>
                                            <li>Influencer of the Year</li>
                                            <li>Innovative Business Idea of the Year</li>
                                            <li>Integrated Marketing Communications Leader of the Year</li>
                                            <li>Marketer of the Year</li>
                                            <li>Marketing Company of the Year</li>
                                            <li>Marketing Leader of the Year</li>
                                            <li>Marketing Strategist of the Year</li>
                                            <li>Media &amp; Advertising Leader of the Year</li>
                                            <li>Mentor of the Year</li>
                                            <li>Sales Director of the Year</li>
                                            <li>Social Media Innovator of the Year</li>
                                            <li>Start-up of the Year</li>
                                            <li>Sustainability Leader of the Year</li>
                                            <li>VP of the Year</li>
                                            <li>Woman Healthcare Leader of the Year</li>
                                            <li>Young Business Leader of the Year</li>
                                            <li>Young Entrepreneur of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panelb38'} onChange={handleChange('panelb38')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        Real Estate 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Business Leader of the Year</li>
                                            <li>Business Strategist of&nbsp;the&nbsp;Year</li>
                                            <li>Business Woman of the Year</li>
                                            <li>CEO of the Year</li>
                                            <li>CTO of the Year</li>
                                            <li>Entrepreneur of the Year</li>
                                            <li>Most Innovative Marketer of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panelb39'} onChange={handleChange('panelb39')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        SME's 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Business Competency Leader of the Year</li>
                                            <li>Business Leader of the Year</li>
                                            <li>Business Woman of the Year</li>
                                            <li>CEO of the Year</li>
                                            <li>CMO of the Year</li>
                                            <li>Consultancy of the Year (Outsourcing Services)</li>
                                            <li>Cybersecurity Evangelist of the Year</li>
                                            <li>Entrepreneur of the Year</li>
                                            <li>Healthcare Entrepreneur of the Year</li>
                                            <li>Innovator of the Year</li>
                                            <li>Lifetime Achievement Award in Operations</li>
                                            <li>Managing Director of the Year</li>
                                            <li>Mentor of the Year</li>
                                            <li>Pastry Chef of the Year</li>
                                            <li>Patisserie Company of the Year</li>
                                            <li>SME of the Year</li>
                                            <li>Start-up of the Year</li>
                                            <li>Women Entrepreneur of the Year</li>
                                            <li>Young Entrepreneur of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li>
                                    <Accordion expanded={expanded === 'panelb40'} onChange={handleChange('panelb40')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                        >
                                        <Typography>
                                        Technology 
                                        <div className="icon fa fa-angle-down"></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <ol>
                                            <li>Ad-Tech Leader of the Year</li>
                                            <li>AVP of the Year</li>
                                            <li>Blockchain Consultant of the Year</li>
                                            <li>Business Leader of the Year</li>
                                            <li>Business Transformation Leader of the Year</li>
                                            <li>Business Woman of the Year</li>
                                            <li>CEO of the Year</li>
                                            <li>CFO of the Year</li>
                                            <li>Chief Interoperability Officer of the Year</li>
                                            <li>Chief Revenue Officer of the Year</li>
                                            <li>CIO of the Year</li>
                                            <li>Cloud Computing Innovator of the Year</li>
                                            <li>CMO of the Year</li>
                                            <li>Creative Developer of the Year</li>
                                            <li>CTO of the Year</li>
                                            <li>Cybersecurity Business of the Year</li>
                                            <li>Cybersecurity Leader of the Year</li>
                                            <li>Data Center Management Leader of the Year</li>
                                            <li>Digital Disruptor of the Year</li>
                                            <li>Digital Technology Company of the Year</li>
                                            <li>Digital Transformation Leader of the Year</li>
                                            <li>Emerging Healthcare Leader of the Year</li>
                                            <li>Emerging Entrepreneur of the Year</li>
                                            <li>Emerging Technology Advocate of the Year</li>
                                            <li>Entrepreneur of the Year</li>
                                            <li>Founder of the Year</li>
                                            <li>Influencer of the Year</li>
                                            <li>Innovative Icon of the Year</li>
                                            <li>Innovative Leader of the Year</li>
                                            <li>Innovator of the Year</li>
                                            <li>IT Leader of the Year</li>
                                            <li>Lifetime Achievement Award</li>
                                            <li>Lifetime Achievement Award in Agriculture and Development</li>
                                            <li>Achievement Award in Engineering and Manufacturing</li>
                                            <li>Mental Health Tech Innovator Leader of the Year</li>
                                            <li>Mentor of the Year</li>
                                            <li>Most Innovative Company of the Year</li>
                                            <li>Most Innovative Technology Leader of the Year</li>
                                            <li>Power and Water Utilities Leader of the Year</li>
                                            <li>Sales and Marketing Leader of the&nbsp;Year</li>
                                            <li>Scientist of the Year</li>
                                            <li>Social Impact Leader of the Year</li>
                                            <li>Start-up of the Year</li>
                                            <li>Strategic Cloud Technology Leader of the Year</li>
                                            <li>Strategic Leader of the Year</li>
                                            <li>Technology Company of the Year</li>
                                            <li>Technology Leader of the Year</li>
                                            <li>Utility Industry Leader of the Year</li>
                                            <li>Visionary of the Year</li>
                                            <li>Website Design &amp; Development Company of the Year</li>
                                            <li>Young Business Leader of the Year</li>
                                            <li>Young Business Woman of the Year</li>
                                            <li>Young CEO of the Year</li>
                                            <li>Young Entrepreneur of the Year</li>
                                            <li>Young Technology Leader of the Year</li>
                                        </ol>
                                        </Typography>
                                    </AccordionDetails>
                                    </Accordion>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <Jury2 />
            <VideoArea />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPage;
