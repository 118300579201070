import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Health-Insurance-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards: Health Insurance Awards';
    }, []);
    return (
        <Fragment>
             <Helmet>
                <meta name="description" content="Witness the power of innovation transforming healthcare accessibility at the prestigious Forttuna Health Insurance Awards ceremony." />
                <meta property="og:title" content="Forttuna Global Excellence Awards: Health Insurance Awards" />
                <meta property="og:description" content="Witness the power of innovation transforming healthcare accessibility at the prestigious Forttuna Health Insurance Awards ceremony." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: Health Insurance Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="" />
                        </div>
                        <div className="upper-box d-flex justify-content-between flex-wrap">
                            <h2 style={{color: '#fff'}}>The Forttuna Global Excellence Awards: Health Insurance Awards</h2><br/>
                            {/* <div className="event-detail_author">
                                <div className="event-detail_author-image">
                                    <img src={eimg1} alt="" />
                                </div>
                                <div className="event-detail_author-name">Robert Gates, <span>Art Critic</span></div>
                                <div className="event-detail_author-location"><span>Location:</span> Hall 1, Building, <br /> Golden Street , Southafrica</div>
                                <div className="event-detail_author-timing">08.00 am  09.00 am</div>
                                <div className="event-detail_author-icon flaticon-voice"></div>
                            </div> */}
                        </div>
                        <div>
                            <p><strong>First-ever Jury Led Awards: Be Recognized on the World Stage</strong></p>
                            <p><span>The healthcare landscape thrives on constant progress, but for many, accessing these advancements hinges on a crucial element: health insurance.</span></p>
                            <p><span>The Forttuna Global Excellence Awards proudly presents the Health Insurance Awards**,** recognizing the pioneers who innovate and excel in this vital sector. We celebrate their dedication to progress, their commitment to affordability, and their unwavering focus on the well-being of their clients.</span></p>
                            <p><strong>What are the Forttuna Global Excellence Awards?</strong></p>
                            <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>A prestigious jury will evaluate your work and celebrate your exceptional achievements in health insurance.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Showcase your company's innovation and leadership, attracting new partnerships with increased industry visibility.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Connect with fellow healthcare insurance leaders, share best practices, and forge valuable connections.</span></li>
                            </ul>
                            <p><strong>Why Participate?</strong></p>
                            <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>A prestigious jury evaluates your work, placing your company's innovation and leadership in health insurance on the world stage.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Increased brand visibility fuels media exposure and new partnerships, propelling your achievements and talent forward.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Share your success story at our exclusive ceremony, motivating future leaders while networking with healthcare insurance giants.</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>A panel of distinguished international jury members assesses your entries, ensuring a fair and competitive selection process.</span></li>
                            </ul>
                            <p><strong>Health Insurance Awards Categories :</strong><span> (All Categories for this award will contain subcategories too- like CMO of the year, CTO of the year as mentioned on the website. As shown in the first two categories )</span></p>
                            <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Ayush, Spa, Wellness and Yoga Category</strong></li>
                            </ol>
                            <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Ayurveda Centre Of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Medical Spa/Medical Resort Of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Yoga Centre Of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Wellness Centre/ Retreat Of the Year</span></li>
                            </ol>
                            <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Company and Brands</strong></li>
                            </ol>
                            <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Health Insurance Company Of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Long-Tem Care Provider Of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Pharma Company Of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Pharmacy Retail Chain Of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Emerging Healthcare Company Of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Medical Equipment Company Of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Healthcare Start-up of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Public Healthcare Initiative of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Innovative Medical Product of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Marketing Company Of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Insurance and Finance Company Of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Technology Company Of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Education Company Of the Year</span></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><span>Veterinary Company Of the Year</span></li>
                            </ol>
                            <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> CXO&rsquo;s Health &amp; Wellness</strong></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Hospital &amp; Clinic Category</strong></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Individual Category</strong></li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong> Medical College/Institute Category</strong></li>
                            </ol>
                            <p><strong>Ready to shine a light? Participate yourself or someone you know for recognition.</strong></p>
                            <p><span>Witness the power of innovation transforming healthcare accessibility at the prestigious Forttuna Health Insurance Awards ceremony.</span></p>
                            <p><a href="https://calendly.com/d/ck9b-2wq-pjs/participation-interview-fga?month=2024-07"><span>Book An Appointment Now!</span></a></p>
                            <p><span><Link to={`/healthcare-advertising-awards/`}>Learn More</Link> About the Excellence Award.</span></p>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
