import timg1 from '../images/resource/jatin-kanojia.webp'
import timg2 from '../images/resource/harsh.webp'
import timg3 from '../images/resource/saurabh.webp'
import timg4 from '../images/resource/sanam.webp'
import timg5 from '../images/resource/gaurav-singh.webp'
import timg6 from '../images/resource/Amey-kadam.webp'
import timg7 from '../images/resource/kishor.webp'
import timg8 from '../images/resource/dhananajy-bokolia.webp'
import timg9 from '../images/resource/ayush-kumar.webp'
import timg10 from '../images/resource/speaker-1.jpg'
import timg11 from '../images/resource/speaker-1.jpg'
// import timg12 from '../images/resource/speaker-1.jpg'
import timg13 from '../images/resource/mandar.webp'

import tsimg1 from '../images/resource/jatin-kanojia.webp'
import tsimg2 from '../images/resource/harsh.webp'
import tsimg3 from '../images/resource/saurabh.webp'
import tsimg4 from '../images/resource/sanam.webp'
import tsimg5 from '../images/resource/gaurav-singh.webp'
import tsimg6 from '../images/resource/Amey-kadam.webp'
import tsimg7 from '../images/resource/kishor.webp'
import tsimg8 from '../images/resource/dhananajy-bokolia.webp'
import tsimg9 from '../images/resource/ayush-kumar.webp'
import tsimg10 from '../images/resource/speaker-1.jpg'
import tsimg11 from '../images/resource/speaker-1.jpg'
// import tsimg12 from '../images/resource/speaker-1.jpg'
import tsimg13 from '../images/resource/mandar.webp'
// import tsimg8 from '../images/resource/team-16.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Jatin Kanojia',
      slug: 'Jatin Kanojia',
      title: "AVP – Client Relations",
      link : 'https://www.linkedin.com/in/jatin-kanojia-096481117/',
   },
   {
      Id: '2',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Harsh Sharma',
      slug: 'Harsh Sharma',
      title: 'Manager - Client Relations',
      link : 'https://www.linkedin.com/in/harsh-sharma-7b87571b1/',
   },
   {
      Id: '3',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Saurabh Sharma',
      slug: 'Saurabh Sharma',
      title: 'Manager – Client Relations',
      link : 'https://www.linkedin.com/in/saurabh-sharma-b5b45751/',
   },
   {
      Id: '4',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Sanam Satija',
      slug: 'Sanam Satija',
      title: 'Manager - Client Relations',
      link : 'https://www.linkedin.com/in/sanam-satija-02a69b15a/',
   },
   {
      Id: '5',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Gaurav Singh',
      slug: 'Gaurav Singh',
      title: 'AVP - Operations, HR, and Chief Of Staff',
      link : 'https://www.linkedin.com/in/gsingh04/',
   },
   {
      Id: '6',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Amey Kadam',
      slug: 'Amey Kadam',
      title: 'Head - Operations',
      link : 'https://www.linkedin.com/in/amey-kadam-325a87202/',
   },
   {
      Id: '7',
      tImg: timg7,
      tsImg: tsimg7,
      name: 'Kishor Kumbhar',
      slug: 'Kishor Kumbhar',
      title: 'Manager - Delegate Relations',
      link : 'https://www.linkedin.com/in/kishor-kumbhar-154386245/',
   },
   {
      Id: '8',
      tImg: timg8,
      tsImg: tsimg8,
      name: 'Dhananjay Bokolia',
      slug: 'Dhananjay Bokolia',
      title: 'Social Media Manager',
      link : 'https://www.linkedin.com/in/dhananjaybokolia/',
   },
   {
      Id: '9',
      tImg: timg9,
      tsImg: tsimg9,
      name: 'Ayush Kumar',
      slug: 'Ayush Kumar',
      title: 'Full Stack Developer',
      link : 'https://www.linkedin.com/in/ayush-kumar-10131dev/',
   },
   {
      Id: '10',
      tImg: timg13,
      tsImg: tsimg13,
      name: 'Mandar Sarnobat',
      slug: 'Mandar Sarnobat',
      title: 'Social Media Manager',
      link : 'https://www.linkedin.com/in/mandar-sarnobat-114545201/',
   },
   // {
   //    Id: '11',
   //    tImg: timg10,
   //    tsImg: tsimg10,
   //    name: 'Meltron D’mello',
   //    slug: 'Meltron D’mello',
   //    title: 'Consultant - Growth',
   // },
   // {
   //    Id: '12',
   //    tImg: timg11,
   //    tsImg: tsimg11,
   //    name: 'Russel Dsilva',
   //    slug: 'Russel Dsilva',
   //    title: 'Consultant - Growth',
   // },
   
   // {
   //    Id: '8',
   //    tImg: timg8,
   //    tsImg: tsimg8,
   //    name: 'Harnold Min',
   //    slug: 'Harnold-Min',
   //    title: 'Starbucks CEO',
   // },

]

export default Teams;